import React from 'react';
import './AssetDownloadItem.sass';
import PropTypes from 'prop-types';
import { getBaseUrl } from 'utils/UrlUtil';

export const AssetDownloadItem = ({ asset, name }) => {
  return (
    <a className="asset-download-item col-xs-3" href={getBaseUrl() + asset} download>
      <img
        src={require('../../assets/pics/pdf-download.png')}
        className="asset-download-item__pdf-download-img"
      />
      <div className="asset-download-item__name">{name}</div>
    </a>
  );
};

AssetDownloadItem.displayName = 'AssetDownloadItem';

AssetDownloadItem.propTypes = {
  asset: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

AssetDownloadItem.defaultProps = {};
