import React, { Component } from 'react';
import './css/EmailIcon.sass';

class EmailIcon extends Component {
  render() {
    return (
      <div className="email-icon">
        <span className="lines"> </span>
        <span className="lines"> </span>
        <span className="lines"> </span>
        <span className="lines"> </span>
        <span className="lines"> </span>
        <span className="lines"> </span>
        <span className="lines"> </span>
        <span className="lines"> </span>
      </div>
    );
  }
}
export default EmailIcon;
