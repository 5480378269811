import React, { useCallback, useMemo } from 'react';
import './CustomCheckbox.sass';
import Proptypes from 'prop-types';
import CheckLightIcon from '../../assets/pics/check-light.png';
import CheckDarkIcon from '../../assets/pics/check-dark.png';

const CustomCheckbox = ({ disabled, isEdited, name, error, id, value, onChange }) => {
  const onChangeClick = useCallback(() => {
    if (disabled) return;

    const syntheticEvent = {
      target: {
        name,
        value: !value,
      },
    };
    onChange(syntheticEvent);
  }, [name, value, onChange]);

  const getClassnameForState = useMemo(() => {
    if (disabled) return 'disabled';
    if (isEdited) return 'edited';
    if (error) return 'error';
    return '';
  }, [disabled, isEdited, error]);

  const getClassnameForSelected = useMemo(() => {
    if (value === true) return 'selected';
    return '';
  }, [value]);

  return (
    <div className="custom-checkbox">
      <div
        className={`custom-checkbox__input ${getClassnameForState} ${getClassnameForSelected}`}
        id={id}
        onClick={onChangeClick}>
        <img
          src={isEdited && !disabled ? CheckDarkIcon : CheckLightIcon}
          alt="checkbox"
          className={`custom-checkbox__check-icon ${getClassnameForSelected}`}
        />
      </div>
    </div>
  );
};

CustomCheckbox.propTypes = {
  disabled: Proptypes.bool,
  isEdited: Proptypes.bool,
  error: Proptypes.bool,
  name: Proptypes.string.isRequired,
  id: Proptypes.string.isRequired,
  value: Proptypes.bool.isRequired,
  onChange: Proptypes.func.isRequired,
};

CustomCheckbox.defaultProps = {
  disabled: false,
  isEdited: false,
  error: false,
};

export { CustomCheckbox };
