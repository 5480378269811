import gql from 'graphql-tag';

export const REMOVE_NEW_TAG_FROM_ARTICLE_MUTATION = gql`
  mutation RemoveNewTag($articleId: Int!) {
    deleteArticleNotification(articleId: $articleId)
  }
`;
export const REMOVE_NEW_TAG_FROM_EVENT_MUTATION = gql`
  mutation RemoveNewTag($eventId: Int!) {
    deleteEventNotification(eventId: $eventId)
  }
`;

export const UPDATE_ARTICLE_LIKES = gql`
  mutation ArticleLikes($aemId: String!) {
    sendFeedback(aemId: $aemId) {
      likes
    }
  }
`;
export const DELETE_COMPANY = gql`
  mutation deleteCompanyMutation($deleteCompanyInput: DeleteCompanyInput!) {
    deleteCompany(deleteCompanyInput: $deleteCompanyInput) {
      status
    }
  }
`;

export const SEND_ARTICLE_FEEDBACK = gql`
  mutation ArticleFeedback($aemId: String!, $email: String!, $message: String!, $name: String!) {
    sendFeedback(aemId: $aemId, feedback: { email: $email, message: $message, name: $name }) {
      id
      likes
    }
  }
`;

export const SET_SUBSCRIPTION_STATUS_MUTATION = gql`
  mutation setSubscriptionStatus($status: Boolean!) {
    setSubscriptionStatus(status: $status) {
      email
      status
    }
  }
`;

export const UNREGISTER_FROM_EVENT_MUTATION = gql`
  mutation UnregisterFromEvent(
    $eventId: Int!
    $unregister: Boolean
    $participants: [EventParticipantInput!]!
  ) {
    setParticipants(eventId: $eventId, unregister: $unregister, participants: $participants) {
      companyName
      participants {
        firstName
        lastName
      }
    }
  }
`;

export const SET_PARTICIPANTS_MUTATION = gql`
  mutation SetParticipants(
    $eventId: Int!
    $participants: [EventParticipantInput!]!
    $companyName: String
  ) {
    setParticipants(eventId: $eventId, participants: $participants, companyName: $companyName) {
      companyName
      participants {
        id
        firstName
        lastName
      }
    }
  }
`;

export const SEND_DEB_FORM = gql`
  mutation SendDebForm($debInput: DEBInput!) {
    deb(debInput: $debInput) {
      status
    }
  }
`;

export const SET_INSTALLER_ID = gql`
  mutation SetInstallerId($id: Long!) {
    setInstallerId(id: $id) {
      id
    }
  }
`;

// PUBLIC mutations and queries which need no authentication config (see Apollo.jsx)

export const PUBLIC_SEND_NE_FORM = gql`
  mutation SendNeForm($neInput: NEInput!) {
    ne(neInput: $neInput) {
      status
    }
  }
`;
