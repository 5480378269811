import React, { useEffect } from 'react';
import { MapTo } from '@adobe/aem-react-editable-components';
import NetworkConnectionSubText from '../../components/NetworkConnectionSubText/NetworkConnectionSubText';
import NetworkConnectionSection from '../../components/NetworkConnectionSection/NetworkConnectionSection';
import './NetworkConnection.scss';

import { PageTitleSection } from '../../../PageTitleSection/PageTitleSection';

const CONTENT = {
  linkToNetworkContactPerson: {
    title: 'Ansprechpartner Netzanschlussfragen',
    description:
      'Bei Fragen zu Netzanschlüssen, Zählervorgängen und Aufträgen im mAP wenden Sie sich an das gemeinsame Postfach ',
    descriptionEmail: 'netzanschluss@avacon.de',
    descriptionAfterEmail: 'Bitte geben Sie die PLZ Ihres Auftragsortes im Betreff an.',
    cards: [
      {
        title: 'Burgwedel',
        data: [
          {
            name: 'Frank Fippl',
            phoneNumber: '+49513980231195',
            phoneNumberDisplay: '05139 802-31195',
          },
        ],
      },
      {
        title: 'Gardelegen',
        data: [
          {
            name: 'Torsten Lilienthal',
            phoneNumber: '+493907777930547',
            phoneNumberDisplay: '03907 7779-30547',
          },
        ],
      },
      {
        title: 'Gehrden, Sarstedt (Standard-Hausanschluss & Baustrom)',
        data: [
          {
            name: 'Sammelrufnummer',
            phoneNumber: '+4950668333333',
            phoneNumberDisplay: '05066 83-33333',
          },
        ],
      },
      {
        title: 'Gehrden, Sarstedt (Individualanschlüsse)',
        data: [
          {
            name: 'Sammelrufnummer',
            phoneNumber: '+4950668344444',
            phoneNumberDisplay: '05066 83-44444',
          },
        ],
      },
      {
        title: 'Genthin',
        data: [
          {
            name: 'Björn Nöske  (Standard-Hausanschluss & Baustrom)',
            phoneNumber: '+493933822136022',
            phoneNumberDisplay: '03933 8221-36022',
          },
        ],
      },
      {
        title: 'Genthin',
        data: [
          {
            name: 'Kerstin Abendroth (Standard-Hausanschluss & Baustrom)',
            phoneNumber: '+493933822132755',
            phoneNumberDisplay: '03933 8221-32755',
          },
        ],
      },
      {
        title: 'Genthin',
        data: [
          {
            name: 'Johannes Meineke (Individualanschlüsse)',
            phoneNumber: '+493933822130057',
            phoneNumberDisplay: '03933 8221-30057',
          },
        ],
      },
      {
        title: 'Lüneburg',
        data: [
          {
            name: 'Carsten Mohs',
            phoneNumber: '+49413170438860',
            phoneNumberDisplay: '04131 704-38860',
          },
        ],
      },
      {
        title: 'Nienburg',
        data: [
          {
            name: 'Melanie Hüser-Wurl',
            phoneNumber: '+49502198930024',
            phoneNumberDisplay: '05021 989-30024',
          },
        ],
      },
      {
        title: 'Oschersleben',
        data: [
          {
            name: 'Volker Schillack (Standard-Hausanschluss & Baustrom)',
            phoneNumber: '+49394993730177',
            phoneNumberDisplay: '03949 937-30177',
          },
        ],
      },
      {
        title: 'Oschersleben',
        data: [
          {
            name: 'Diana Müller (Individualanschlüsse)',
            phoneNumber: '+49394993732134',
            phoneNumberDisplay: '03949 937-32134',
          },
        ],
      },
      {
        title: 'Oschersleben',
        data: [
          {
            name: 'Greta-Louisa Könnecke (Standard-Hausanschluss & Baustrom)',
            phoneNumber: '+493942496132443',
            phoneNumberDisplay: '03942 496-132443',
          },
        ],
      },
      {
        title: 'Salzwedel',
        data: [
          {
            name: 'Thomas Klähn',
            phoneNumber: '+49390184136485',
            phoneNumberDisplay: '03901 841-36485',
          },
        ],
      },
      {
        title: 'Schöningen',
        data: [
          {
            name: 'Antonia Danzfuß (Standard-Hausanschluss & Baustrom)',
            phoneNumber: '+49535293930165',
            phoneNumberDisplay: '05352 939-30165',
          },
        ],
      },
      {
        title: 'Schöningen',
        data: [
          {
            name: 'Yvonne Wisotzky (Individualanschlüsse)',
            phoneNumber: '+49535293935258',
            phoneNumberDisplay: '05352 939-35258',
          },
        ],
      },
      {
        title: 'Syke',
        data: [
          {
            name: 'Karin Wigger',
            phoneNumber: '+49424269531189',
            phoneNumberDisplay: '04242 695-31189',
          },
        ],
      },
      {
        title: 'WEVG / Salzgitter',
        data: [
          {
            name: 'Mohammad Al-Mathyab',
            phoneNumber: '+49534122132136',
            phoneNumberDisplay: '05341 221-32136',
          },
        ],
      },
      {
        title: 'WEVG / Salzgitter',
        data: [
          {
            name: 'Vanessa Malek',
            phoneNumber: '+49534122136506',
            phoneNumberDisplay: '05341 221-36506',
          },
        ],
      },
    ],
  },
  eegQuestionContactPerson: {
    title: 'Ansprechpartner EEG-Fragen',
    description:
      'Bei Fragen zu Erzeugungsanlagen und dem EEG wenden Sie sich an das gemeinsame Postfach',
    descriptionEmail: 'einspeisung@avacon.de',
    descriptionAfterEmail: 'Bitte geben Sie die PLZ Ihres Auftragsortes im Betreff an.',
    cards: [
      {
        title: 'Burgwedel',
        data: [
          {
            name: 'Michael Matheus',
            phoneNumber: '+49513980236426',
            phoneNumberDisplay: '05139 802-36426',
          },
        ],
      },
      {
        title: 'Gardelegen',
        data: [
          {
            name: 'Robert Schernikau (PV > 30 kWp)',
            phoneNumber: '+493907777930548',
            phoneNumberDisplay: '03907 7779-30548',
          },
        ],
      },
      {
        title: 'Gardelegen, Lüneburg, Salzwedel',
        data: [
          {
            name: 'Thiemo Wölk (BHKW/Bio)',
            phoneNumber: '+49413170430115',
            phoneNumberDisplay: '04131 704-30115',
          },
        ],
      },
      {
        title: 'Gehrden, Sarstedt',
        data: [
          {
            name: 'Sammelrufnummer',
            phoneNumber: '+4950668330303',
            phoneNumberDisplay: '05066 83-30303',
          },
        ],
      },
      {
        title: 'Genthin',
        data: [
          {
            name: 'Oliver Winkel (PV>30 kWp)',
            phoneNumber: '+493933822130058',
            phoneNumberDisplay: '03933 8221-30058',
          },
        ],
      },
      {
        title: 'Genthin, Schöningen, Oschersleben',
        data: [
          {
            name: 'Beate Wölkerling (PV <= 30 kWp)',
            phoneNumber: '+49535293930679',
            phoneNumberDisplay: '05352 939-30679',
          },
        ],
      },
      {
        title: 'Genthin, Schöningen, Oschersleben',
        data: [
          {
            name: 'Simone Gassner (PV <= 30 kWp)',
            phoneNumber: '+49394993730591',
            phoneNumberDisplay: '03949 937-30591',
          },
        ],
      },
      {
        title: 'Lüneburg',
        data: [
          {
            name: 'Dominic Jahn (PV > 30 kWp)',
            phoneNumber: '+49413170430269',
            phoneNumberDisplay: '04131 704-30269',
          },
        ],
      },
      {
        title: 'Lüneburg',
        data: [
          {
            name: 'Empfang (Weiterleitung and die Fachabteilung)',
            phoneNumber: '+4941317040',
            phoneNumberDisplay: '04131 704-0',
          },
        ],
      },
      {
        title: 'Nienburg',
        data: [
          {
            name: 'Martin Nühring',
            phoneNumber: '+49502198931502',
            phoneNumberDisplay: '05021 989-31502',
          },
        ],
      },
      {
        title: 'Oschersleben',
        data: [
          {
            name: 'Chris Ohlendorf (PV>30 kWp)',
            phoneNumber: '+49394993732147',
            phoneNumberDisplay: '03949 937-32147',
          },
        ],
      },
      {
        title: 'Oschersleben',
        data: [
          {
            name: 'Henrik Schlange (PV > 30 kWp)',
            phoneNumber: '+49394993734853',
            phoneNumberDisplay: '03949 937-34853',
          },
        ],
      },
      {
        title: 'Oschersleben, Genthin',
        data: [
          {
            name: 'Carsten Fricke (BHKW/Bio)',
            phoneNumber: '+49394993736503',
            phoneNumberDisplay: '03949 937-36503',
          },
        ],
      },
      {
        title: 'Salzwedel',
        data: [
          {
            name: 'Thiemo Wölk (PV > 30 kWp)',
            phoneNumber: '+49413170430115',
            phoneNumberDisplay: '04131 704-301151',
          },
        ],
      },
      {
        title: 'Schöningen',
        data: [
          {
            name: 'Carsten Fricke (PV > 30 kWp & BHKW/Bio)',
            phoneNumber: '+49535293736503',
            phoneNumberDisplay: '05352 937-36503',
          },
        ],
      },
      {
        title: 'Schöningen',
        data: [
          {
            name: 'Silke Träger (PV > 30 kWp)',
            phoneNumber: '+49394993733302',
            phoneNumberDisplay: '03949 937-33302',
          },
        ],
      },
      {
        title: 'Syke',
        data: [
          {
            name: 'Frank Kehr',
            phoneNumber: '+49502198937358',
            phoneNumberDisplay: '05021 989-37358',
          },
        ],
      },
      {
        title: 'WEVG / Salzgitter',
        data: [
          {
            name: 'Rolf Frejek',
            phoneNumber: '+49534122135427',
            phoneNumberDisplay: '05341 221-35427',
          },
        ],
      },
    ],
  },
  firstSubText:
    'Sie sind sich nicht sicher, welchem unserer Betriebsstandorte Ihr Bauvorhaben zugeordnet ist?',
  secondSubText: 'Dann nutzen Sie gerne unsere ',
  secondSubTextActionText: 'Standortsuche',
  secondSubTextAfterAction: '.',
  secondSubTextAction:
    'https://avacon-standortsuche.regiocom.net/lokaextern/App/Suche/Standortsuche.aspx',
};

const NetworkConnectionComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    linkToNetworkContactPerson,
    eegQuestionContactPerson,
    firstSubText,
    secondSubText,
    secondSubTextAction,
    secondSubTextActionText,
    secondSubTextAfterAction,
  } = CONTENT;

  return (
    <div className="expert-page">
      <div className="expert-page-content-stage-text">
        <PageTitleSection title={linkToNetworkContactPerson.title} notFramed />
        <NetworkConnectionSection data={linkToNetworkContactPerson} />

        <PageTitleSection title={eegQuestionContactPerson.title} notFramed />
        <NetworkConnectionSection data={eegQuestionContactPerson} />

        <div className="bottom-text-wrapper">
          <div>{firstSubText}</div>
          <NetworkConnectionSubText
            text={secondSubText}
            textAction={secondSubTextActionText}
            textAfterEmail={secondSubTextAfterAction}
            textActionLink={secondSubTextAction}
          />
        </div>
      </div>
    </div>
  );
};

MapTo(['e-fix/components/content/avaconnetworkconnection'])(NetworkConnectionComponent);
