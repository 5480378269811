/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import './IncidentsContent.sass';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { SubTitle } from '../../components/SubTitle/SubTitle';

export const IncidentsContent = ({ content, reportSectionRef }) => {
  const renderContentItem = ({ title, tel, telFormatted }) => {
    return (
      <Fragment key={title}>
        <SubTitle text={title} notFramed />
        <div>
          <a href={'tel:' + tel}>{telFormatted}</a>
        </div>
      </Fragment>
    );
  };

  return (
    <>
      <div className="incidentsContent-wrapper">
        <iframe
          title="Störungsauskunft"
          className="incidentsContent-iframe"
          src={content.url}></iframe>
        {content.finePrintIFrame && <div className="incidents_text">{content.finePrintIFrame}</div>}
      </div>
      <div className={`incidentsContent-info-wrapper`} ref={reportSectionRef}>
        <PageTitle text={content.title} />
        <section className="incidentsContent-info">
          {content.items.map((item) => renderContentItem(item))}
        </section>
        {content.finePrint && (
          <div className="incidentsContent-info-fineprint">{content.finePrint}</div>
        )}
      </div>
    </>
  );
};
