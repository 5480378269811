/* eslint-disable react/prop-types */
import React from 'react';
import DataItem from '../../../../DataItem/DataItem';
import './RegionDetails.scss';
import { SubTitle } from '../../../../SubTitle/SubTitle';

const RegionDetails = ({ regionsDataOptions }) => {
  return (
    <div className="regionDetails">
      {(regionsDataOptions || []).map((item, index) => (
        <div key={index} className="regionDetailsItem">
          <SubTitle text={item?.option?.subTitle} id={item?.key} notFramed />
          <div>{item?.option?.subTitleDescription}</div>
          <div>{item?.option?.subTitleSecondDescription}</div>
          <div className="regionImageContainer">
            {item?.option?.mapImageName && (
              <img
                alt="Map"
                src={require(`../../../../../assets/pics/${item?.option?.mapImageName}`)}
              />
            )}
          </div>
          <div className="dataItem-container">
            {(item?.option?.contactDetails?.data || []).map((value, index) => (
              <DataItem key={index} data={value} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default RegionDetails;
