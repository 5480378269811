import React, { useMemo } from 'react';
import { BasicTable } from './BasicTable';
import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';

export function ContractsTable({ data, oldData }) {
  const rows = useMemo(() => {
    const rowsNew = data.map((row, index) => {
      if (row.contractStatus === 'Aktiv') {
        return (
          <TableRow
            key={row.id}
            className={
              oldData && oldData[index]
                ? _.isEqual(row, oldData[index])
                  ? ''
                  : 'table-row-edited'
                : ''
            }
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
            }}>
            <TableCell scope="row">{row.contractType.name}</TableCell>
            <TableCell align="right">{row.contractStatus}</TableCell>
            <TableCell align="right">{row.validFrom}</TableCell>
            {/* to be added in a future story */}
            {/* <TableCell align="right">
              <InputField
                name={`contracts[${index}].cancelledOn`}
                key={`contracts[${index}].cancelledOn`}
                id={`contracts[${index}].cancelledOn`}
                value={formik.values[`contracts[${index}].cancelledOn`]}
                onChange={formik.handleChange}
                oldValue={
                  oldData && oldData[index]
                    ? oldData[index].cancelledOn
                      ? oldData[index].cancelledOn
                      : ''
                    : ''
                }
                error={Boolean(formik.errors[`contracts[${index}].cancelledOn`])}
                helperText={formik.errors[`contracts[${index}].cancelledOn`]}></InputField>
            </TableCell> */}
          </TableRow>
        );
      }
    });
    return rowsNew;
  }, [data]);
  const headers = () => {
    return [
      <TableCell scope="row" key={'Vertrag'}>
        Vertrag
      </TableCell>,
      <TableCell align="right" key={'Status'}>
        Status
      </TableCell>,
      <TableCell align="right" key={'Gültig'}>
        Gültig ab
      </TableCell>,
      // <TableCell align="right" key={'Gekündigt'}>
      //   Gekündigt zum
      // </TableCell>,
    ];
  };

  return (
    <div>
      <BasicTable headers={headers()} rows={rows}></BasicTable>
    </div>
  );
}
ContractsTable.propTypes = {
  data: PropTypes.array,
  oldData: PropTypes.array,
  formik: PropTypes.object,
};
