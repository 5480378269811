import React from 'react';
import PropTypes from 'prop-types';
import { SubTitle } from 'components/SubTitle/SubTitle';

export function RenderAemComponent(props) {
  const { name, componentProps } = props;
  const fieldsToNotRender = ['cqPath', 'componentProperties', 'isInEditor'];
  const renderData = (data) => {
    if (Array.isArray(data)) {
      return (
        <ul>
          {data.map((item, index) => (
            <li key={index}>{renderData(item)}</li>
          ))}
        </ul>
      );
    } else if (typeof data === 'object' && data !== null) {
      return (
        <ul>
          {Object.entries(data).map(([key, value]) => {
            if (!fieldsToNotRender.includes(key)) {
              return (
                <li key={key}>
                  <strong>{key}:</strong> {renderData(value)}
                </li>
              );
            }
          })}
        </ul>
      );
    } else {
      return <span>{String(data)}</span>;
    }
  };

  return (
    <div className="input-field">
      <SubTitle text={name}></SubTitle>
      {renderData(componentProps)}
    </div>
  );
}

RenderAemComponent.propTypes = {
  name: PropTypes.string,
  componentProps: PropTypes.object,
  infoText: PropTypes.string,
  disabled: PropTypes.bool,
};
