/* eslint-disable react/prop-types */
import React from 'react';
import './NetworkConnectionSubText.scss';

const NetworkConnectionSubTextComponent = ({
  text,
  textEmail,
  textAfterEmail,
  textActionLink,
  textAction,
}) => {
  if (!text) {
    return null;
  }

  return (
    <div className="networkConnectionSubText">
      <span>{text}</span>
      {textEmail && (
        <span style={{ marginLeft: 3 }}>
          <a href={`mailto:${textEmail}`}>{textEmail}</a>
        </span>
      )}
      {textAction && (
        <span style={{ marginLeft: 3 }}>
          <a href={textActionLink} target="_blank" rel="noreferrer">
            {textAction}
          </a>
        </span>
      )}
      {textAfterEmail && <span>{textAfterEmail}</span>}
    </div>
  );
};

export default NetworkConnectionSubTextComponent;
