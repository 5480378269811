import React from 'react';
import PropTypes from 'prop-types';
import { STORAGE_KEY } from 'global/constants';

export const checkKeyDown = (e) => {
  // hack for multiline text fields to accept newlines
  if (
    e.target.name !== 'changes' &&
    e.target.name !== 'questionInput' &&
    (e.code === 'Enter' || e.key === 'Enter')
  )
    e.preventDefault();
};

export const FFormDebug = ({ values, errors, touched }) => {
  FFormDebug.propTypes = {
    values: PropTypes.object.isRequired,
    errors: PropTypes.object,
    touched: PropTypes.object,
  };

  return (
    (process.env.REACT_APP_STAGE === 'local' ||
      localStorage.getItem(STORAGE_KEY.FORM_DEBUG) === 'true') && (
      <>
        <div>Values:</div>
        <pre>{JSON.stringify(values, null, 2)}</pre>
        <div>Touched:</div>
        <pre>{JSON.stringify(touched, null, 2)}</pre>
        <div>Errors:</div>
        <pre>{JSON.stringify(errors, null, 2)}</pre>
      </>
    )
  );
};
