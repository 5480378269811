import React, { useEffect, useRef } from 'react';
import { MapTo, Utils } from '@adobe/aem-react-editable-components';
import './incidents.scss';
import { getTenantId } from 'utils/tenantUtil';
import { IncidentsContent } from './IncidentsContent';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { PageTitleSection } from 'components/PageTitleSection/PageTitleSection';
import { RenderAemComponent } from 'components/RenderAemComponent/RenderAemComponent';
import { TENANT_ID } from 'global/constants';

const CONTENT = {
  [TENANT_ID.SHNG]: {
    title: 'Sie möchten eine Störung melden?',
    subText: '',
    url: 'https://xn--strungsauskunft-9sb.de/stromausfall',
    finePrintIFrame:
      'Die Schleswig-Holstein Netz GmbH weist darauf hin, dass keine Gewähr für die Vollständigkeit, Aktualität und Richtigkeit der Darstellung übernommen wird und eine Anpassung jederzeit möglich ist. Eine Haftung der Schleswig-Holstein Netz GmbH ist insoweit ausgeschlossen.',
    items: [
      {
        title: 'Schleswig-Holstein Netz GmbH:',
        tel: '+4941066489090',
        telFormatted: 'T 04106 648-9090',
      },
      { title: 'HanseGas GmbH:', tel: '+4938558975075', telFormatted: 'T 0385 589-75075' },
      { title: 'ElbEnergie GmbH:', tel: '+4941051579900', telFormatted: 'T 04105 157-9900' },
    ],
  },
  [TENANT_ID.AVA]: {
    title: 'Sie möchten eine Störung melden?',
    subText: 'Aktuelle Stromausfälle finden Sie auf der nachfolgenden Störungskarte.',
    url: 'https://stoerungsauskunft.de',
    items: [
      { title: 'Für Erdgas:', tel: '+498004282266', telFormatted: 'T 0800 42 82266*' },
      {
        title: 'Für Strom, Wasser und Wärme:',
        tel: '+498000282266',
        telFormatted: 'T 0800 02 82266*',
      },
    ],
    finePrint: '*Kostenlos aus allen deutschen Fest- und Mobilfunknetzen',
  },
  [TENANT_ID.BAG]: {
    title: 'Störungsnummern',
    subText:
      'Aktuelle Stromausfälle finden Sie auf der nachfolgenden Störungskarte. Sie haben die Möglichkeit neue Störungen zu melden. Wir arbeiten mit der Plattform störungsauskunft.de zusammen.',
    url: 'https://stoerungsauskunft.de',
    items: [
      {
        title: 'Stromstörungsmeldungen bitte an:',
        tel: '+4994128003366',
        telFormatted: 'T 0941 - 28 00 33 66',
      },
      {
        title: 'Gasstörungsmeldungen bitte an:',
        tel: '+4994128003355',
        telFormatted: 'T 0941 - 28 00 33 55',
      },
    ],
    finePrint: '',
  },
};

const Incidents = () => {
  const { search, key } = useLocation();
  const reportFaultSection = useRef(null);

  useEffect(() => {
    const parsedQS = qs.parse(search);
    if (parsedQS.toReport) {
      if (reportFaultSection) {
        reportFaultSection.current.scrollIntoView();
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [key]);

  const tenantId = getTenantId();
  const currentContent = CONTENT[tenantId];
  const isEditor = Utils.isInEditor();

  if (isEditor) {
    return <RenderAemComponent componentProps={{}} name="Incidents" />;
  }
  return (
    <div className="incidents-page">
      <PageTitleSection title={currentContent.title} subText={currentContent.subText} notFramed />
      <div className="incidents-stage-content">
        <IncidentsContent content={currentContent} reportSectionRef={reportFaultSection} />
      </div>
    </div>
  );
};

MapTo(['e-fix/components/content/incidents'])(Incidents);
