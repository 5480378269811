import axios from 'axios';
import { getEIVApiBaseUrl } from './APIUtils';

export const getAxiosInstance = (ciamToken) => {
  const baseURL = getEIVApiBaseUrl();
  const username = process.env.REACT_APP_EIV_USERNAME;
  const password = process.env.REACT_APP_EIV_PASSWORD;

  const credentials = Buffer.from(`${username}:${password}`).toString('base64');

  return axios.create({
    baseURL: baseURL,
    headers: {
      Accept: 'application/json',
      Authorization: `Basic ${credentials}`,
      'ciam-token': ciamToken,
    },
  });
};
