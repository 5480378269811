/* eslint-disable react/prop-types */
import React from 'react';
import NetworkConnectionSubtitle from '../NetworkConnectionSubtitle/NetworkConnectionSubtitle';
import ContactPersonItem from '../ContactPersonItem/ContactPersonItem';
import { flatCards } from '../card-utils';
import './NetworkConnectionSection.scss';

const NetworkConnectionSectionComponent = ({ data }) => {
  const cards = flatCards(data?.cards || []);

  return (
    <div className="networkConnectionSection">
      <NetworkConnectionSubtitle
        description={data?.description}
        descriptionEmail={data?.descriptionEmail}
        descriptionAfterEmail={data?.descriptionAfterEmail}
      />

      <div className="networkConnectionSectionContactPersonContainer">
        {cards.map((details, index) => (
          <div
            key={index}
            className={`networkConnectionSectionContactPersonItem${
              !details?.title ? ' borderNone' : ''
            }`}>
            <ContactPersonItem
              title={details?.title}
              name={details.name}
              phoneNumber={details.phoneNumber}
              phoneNumberDisplay={details.phoneNumberDisplay}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default NetworkConnectionSectionComponent;
