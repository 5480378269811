/* eslint-disable react/prop-types */
import React, { createContext, useState } from 'react';

export const ErrorHandlingContext = createContext({});

const ErrorHandlingContextContextProvider = ({ children }) => {
  const [showError, setShowError] = useState(false);

  return (
    <ErrorHandlingContext.Provider value={{ showError, setShowError }}>
      {children}
    </ErrorHandlingContext.Provider>
  );
};

export default ErrorHandlingContextContextProvider;
