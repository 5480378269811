/* eslint-disable react/prop-types */
/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2018 Adobe Systems Incorporated
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
import React, { Component } from 'react';
import extractModelId from '../utils/extract-model-id';
import { MapTo } from '@adobe/aem-react-editable-components';

import '../components/css/Image.scss';
/**
 * Default Edit configuration for the Image component that interact with the Core Image component and sub-types
 *
 * @type EditConfig
 */
const ImageEditConfig = {
  emptyLabel: 'Image',

  isEmpty: function (props) {
    return !props || !props['src'];
  },
};

/**
 * Expected usage of the Image Component.
 *
 * <Image
 *      cqModel='the whole Object holding information for the image from the model.json response' />
 *
 * Please see the package.json for the proxy settings.
 */
class Image extends Component {
  get imageProps() {
    return {
      src: this.props.src,
      alt: this.props.alt,
      title: this.props.title,
      id: this.props.id || extractModelId(this.props.cqPath),
      hasLink: Boolean(this.props.link),
      link: this.props.link,
    };
  }

  get content() {
    return (
      <img
        className="efix-image"
        id={this.imageProps.id}
        src={this.imageProps.src}
        alt={this.imageProps.alt ? this.props.alt : ''}
        title={this.props.title ? this.props.title : ''}
      />
    );
  }

  get linkedContent() {
    return (
      <a href={this.imageProps.link} title={this.props.title || this.props.alt}>
        {this.content}
      </a>
    );
  }

  render() {
    let innerContent = this.imageProps.hasLink ? this.linkedContent : this.content;
    return <div className="cmp-image">{innerContent}</div>;
  }
}
export default MapTo('e-fix/components/content/image')(Image, ImageEditConfig);
