import React, { useEffect } from 'react';

import OuterBox from 'components/OuterBox/OuterBox';
import { SubTitle } from 'components/SubTitle/SubTitle';
import { Separator } from 'components/Separator/Separator';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';

//css
import './SubscribeBox.scss';
import 'components/MessageBox/MessageBox.scss';
//utils
import { getTenantId } from 'utils/tenantUtil';
import { getPrivacyPath } from 'utils/UrlUtil';
import { ThemedSwitch } from 'components/ThemedSwitch/ThemedSwitch';
import { SUBSCRIPTION_STATUS_QUERY } from 'Queries/Queries';
import { SET_SUBSCRIPTION_STATUS_MUTATION } from 'Queries/Mutations';
import { TENANT_ID } from 'global/constants';

const SubscribeBox = ({ isEIV }) => {
  const [fetchSubscriptionStatus, { data, loading, error }] =
    useLazyQuery(SUBSCRIPTION_STATUS_QUERY);
  const [setSubscriptionStatus] = useMutation(SET_SUBSCRIPTION_STATUS_MUTATION, {
    refetchQueries: () => [{ query: SUBSCRIPTION_STATUS_QUERY, partialRefetch: true }],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    if (!isEIV) {
      fetchSubscriptionStatus();
    }
  }, [isEIV]);

  return (
    <div className="message-box subscribe-box">
      <OuterBox className="message-box__outer-box">
        <SubTitle text={'Auf dem Laufenden bleiben'} notFramed />
        <Separator color={getTenantId() === TENANT_ID.AVA ? 'homepage' : 'secondary'} />
        <div>
          {'Möchten Sie per E-Mail über neue Nachrichten und Veranstaltungen informiert werden?'}
        </div>
        <br />
        {isEIV ? (
          <div>
            Passen Sie Ihre Einstellungen über die Seite <a href={getPrivacyPath()}>Datenschutz</a>{' '}
            an.
          </div>
        ) : (
          <div>
            <div>Dann passen Sie Ihre Einstellungen an:</div>
            {loading ? null : error ? (
              <div>Es ist ein Fehler aufgetreten</div>
            ) : (
              data != undefined && (
                <div className="subscribe-box__toggle-wrapper">
                  <div>Nein</div>
                  <div>
                    <ThemedSwitch
                      checked={data.userStatus.subscription === 'SUBSCRIBED'}
                      onChange={(e) => {
                        setSubscriptionStatus({ variables: { status: e.target.checked } });
                      }}
                    />
                  </div>
                  <div>Ja</div>
                </div>
              )
            )}
          </div>
        )}
      </OuterBox>
    </div>
  );
};

SubscribeBox.propTypes = {
  isEIV: PropTypes.bool,
};

SubscribeBox.defaultProps = {
  isEIV: false,
};

export { SubscribeBox };
