/* eslint-disable react/prop-types */
import { MapTo } from '@adobe/aem-react-editable-components';
import React from 'react';
import './css/BreadCrumbNavigation.sass';
import { Link } from 'react-router-dom';

const BreadCrumbNavigation = ({ items }) => {
  if (!items || !items.length) return null;

  return (
    <div className="layout-outer-container">
      <div className="bread-crumb-container">
        <ul className="bread-crumb-list">
          {items.map((crumb, index) => (
            <li key={`${crumb}-${index}`} className="bread-crumb-list-element">
              <Link to={{ pathname: crumb.url }}>
                {crumb.title}
                <span className="chevron right" />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
  // let path = window.location.pathname;
  // const breadcrumbs = path.split("/");
  // const breadCrumbsPath = mapURLtoLinkName(
  //   setBreadCrumbLinkNameArray(breadcrumbs)
  // );
};

MapTo('e-fix/components/structure/breadcrumb')(BreadCrumbNavigation);
