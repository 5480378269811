import { useState, useEffect } from 'react';
import { getCookie, setCookie } from 'utils/CookieUtils';

const useCookie = (key, initialValue, days = 7) => {
  const [value, setValue] = useState(() => {
    const cookieValue = getCookie(key);
    return cookieValue !== null ? cookieValue : initialValue;
  });

  useEffect(() => {
    setCookie(key, value, days);
  }, [key, value, days]);

  return [value, setValue];
};

export { useCookie };
