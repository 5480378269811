import React from 'react';
import { useQuery } from '@apollo/client';
import './ExpertSearchDropdownCity.sass';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { GET_LOCATIONS_QUERY } from 'Queries/Queries';
import PropTypes from 'prop-types';

const ExpertSearchDropdownCity = ({ zip, tile, backLink }) => {
  const { loading, error, data } = useQuery(GET_LOCATIONS_QUERY, {
    variables: {
      zip: zip,
      tile: tile,
    },
  });

  if (loading) return <LoadingIndicator />;
  if (error) return <div>{'(!) ERROR (!)'}</div>;

  function chooseCityAndDistrict(e, location) {
    const inputField = document.getElementById('city-search');
    inputField.value = `${location.city}/${location.district}`;
    backLink(location);
    hideSelectOptionContainer(e);
  }

  function toggleSelectOptionContainer(e) {
    const container = e.target.parentElement;
    const selectOptionBox = container.nextSibling;
    if (selectOptionBox.className.includes('hidden')) {
      selectOptionBox.classList.remove('hidden');
      container.parentElement.classList.remove('inactive');
    } else {
      selectOptionBox.classList.add('hidden');
      container.parentElement.classList.add('inactive');
    }
  }

  function hideSelectOptionContainer(e) {
    const selectOptionBox = e.target.parentElement;
    const container = selectOptionBox.parentElement;
    if (selectOptionBox.className.includes('hidden')) {
      selectOptionBox.classList.remove('hidden');
      container.classList.remove('inactive');
    } else {
      selectOptionBox.classList.add('hidden');
      container.classList.add('inactive');
    }
  }

  if (data.locations.length > 0) {
    return (
      <div className="select-container inactive">
        <div className="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
          <input
            readOnly={true}
            className="mdl-textfield__input"
            id="city-search"
            defaultValue="Bitte wählen"
            name="city"
            onClick={(e) => {
              toggleSelectOptionContainer(e);
            }}
          />
          <label
            className="mdl-textfield__label floated"
            id="city-search-label"
            htmlFor="city-search">
            ORT UND ORTSTEIL *
          </label>
        </div>
        <div className="inner-select-container hidden" id="select-option-container">
          {data.locations.map((location, index) => (
            <div
              className="select-option"
              key={index}
              onClick={(e) => {
                chooseCityAndDistrict(e, location);
              }}>
              {location.city}/{location.district}
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="expert-page-error-message">
        Zu dieser Postleitzahl konnte leider kein Eintrag gefunden werden. Eventuell befindet sich
        der gesuchte Ort außerhalb unseres Versorgungsgebietes.
      </div>
    );
  }
};

ExpertSearchDropdownCity.propTypes = {
  zip: PropTypes.string,
  tile: PropTypes.string,
  backLink: PropTypes.func,
};

export { ExpertSearchDropdownCity };
