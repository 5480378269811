/* eslint-disable react/prop-types */
import React from 'react';
import OuterBox from '../../OuterBox/OuterBox';
import EventMetaData from '../EventMetaData/EventMetaData';
import '../EventRegisterForm/EventRegisterForm.scss';
import ParticipantsTable from './ParticipantsTable';
import { SubTitle } from 'components/SubTitle/SubTitle';
import { Separator } from 'components/Separator/Separator';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { getTenantId } from 'utils/tenantUtil';
import { useAuth } from 'context/Auth';

const EventParticipantsList = ({ event }) => {
  const tenantId = getTenantId();
  const { token } = useAuth();

  const downloadName = event.title.replace(/ /g, '_');
  const downloadParticipantsList = async () => {
    const options = {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    };

    if (process.env.REACT_APP_GRAPHQL_ENDPOINT) {
      const baseURl = process.env.REACT_APP_GRAPHQL_ENDPOINT.replace('/graphql', '');
      const url = `${baseURl}/events/${event.id}/participants?tenant=${tenantId}`;

      const download = async (url, filename) => {
        await fetch(url, options).then(function (res) {
          return res.blob().then((b) => {
            var a = document.createElement('a');
            a.href = URL.createObjectURL(b);
            a.setAttribute('download', filename);
            a.click();
          });
        });
      };
      download(url, `${downloadName}.xlsx`);
    }
  };

  return (
    <>
      <OuterBox>
        <EventMetaData event={event} />
        <SubTitle text="Teilnehmer" notFramed />
        <Separator />
        <ParticipantsTable participants={event.allParticipants} />
        <ActionButton
          inverted
          className="event-participants-list-button"
          title="Teilnehmerlist exportieren"
          onClick={downloadParticipantsList}
          disabled={event.allParticipants.length === 0}
        />
      </OuterBox>
    </>
  );
};

export default EventParticipantsList;
