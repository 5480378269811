/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { ArticlesAndEventsContext } from '../../context/ArticlesAndEventsLimit';
import './Pagination.scss';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 124,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Pagination = ({
  selectVarsForPag,
  lastItemDate,
  firstItemDate,
  totalPages,
  currentPage,
  itemsNumber,
  text,
}) => {
  const { limit, setLimit } = useContext(ArticlesAndEventsContext);

  const classes = useStyles();

  const jumpToFirstPage = () => {
    window.scrollTo({
      top: window.innerHeight * 0.65,
      left: 0,
      behavior: 'smooth',
    });
    selectVarsForPag('FIRST', null, 1);
  };

  const jumpToPreviousPage = () => {
    window.scrollTo({
      top: window.innerHeight * 0.65,
      left: 0,
      behavior: 'smooth',
    });
    selectVarsForPag('BACK', firstItemDate, currentPage - 1);
  };

  const jumpToNextPage = () => {
    window.scrollTo({
      top: window.innerHeight * 0.65,
      left: 0,
      behavior: 'smooth',
    });
    selectVarsForPag('NEXT', lastItemDate, currentPage + 1);
  };

  const jumpToLastPage = () => {
    window.scrollTo({
      top: window.innerHeight * 0.65,
      left: 0,
      behavior: 'smooth',
    });
    selectVarsForPag('LAST', null, totalPages);
  };

  const disabledPrevious = currentPage === 1 ? true : false;
  const disabledNext = currentPage === totalPages ? true : false;

  const handleChange = (event) => {
    setLimit(event.target.value);
    jumpToFirstPage();
  };

  return (
    <div>
      {itemsNumber ? (
        <div className="pagination">
          <div className="pagination__icons">
            <IconButton
              className="pagination__icon"
              disabled={disabledPrevious}
              onClick={jumpToFirstPage}>
              <FirstPageIcon
                fontSize="large"
                style={{
                  fill: `${disabledPrevious ? 'currentColor' : '#145f8c'}`,
                }}
              />
            </IconButton>
            <IconButton
              className="pagination__icon"
              disabled={disabledPrevious}
              onClick={jumpToPreviousPage}>
              <NavigateBeforeIcon
                fontSize="large"
                style={{
                  fill: `${disabledPrevious ? 'currentColor' : '#145f8c'}`,
                }}
              />
            </IconButton>
            <p className="pagination__text">
              {currentPage}/{totalPages}
            </p>
            <IconButton
              className="pagination__icon"
              disabled={disabledNext}
              onClick={jumpToNextPage}>
              <NavigateNextIcon
                fontSize="large"
                style={{
                  fill: `${disabledNext ? 'currentColor' : '#145f8c'}`,
                }}
              />
            </IconButton>

            <IconButton
              className="pagination__icon__last"
              disabled={disabledNext}
              onClick={jumpToLastPage}>
              <LastPageIcon
                fontSize="large"
                style={{
                  fill: `${disabledNext ? 'currentColor' : '#145f8c'}`,
                }}
              />
            </IconButton>
          </div>

          <div className="pagination__form">
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-label">{text}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={limit}
                onChange={handleChange}>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Pagination;
