import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Typography, useMediaQuery, withStyles } from '@material-ui/core';
import { CustomTooltip } from 'components/CustomTooltip/CustomTooltip';
import { InfoIcon } from 'components/Icons/InfoIcon';
import { TooltipIconButton } from 'components/TooltipIconButton/TooltipIconButton';

const CustomLink = withStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(14),
  },
}))(Link);

const NavigationTooltip = (props) => {
  const { to, title, cta, className } = props;
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const isTabletOrPhone = useMediaQuery('(max-width:768px)');

  return (
    <CustomTooltip
      className={className}
      onClose={() => setTooltipOpen(false)}
      onOpen={() => {
        if (title) setTooltipOpen(true);
      }}
      open={isTooltipOpen}
      title={
        <div>
          {title}
          <Typography>
            <CustomLink href={to}>{cta}</CustomLink>
          </Typography>
        </div>
      }
      placement="top">
      <div>
        <TooltipIconButton
          onClick={() => {
            isTabletOrPhone && setTooltipOpen(!isTooltipOpen);
          }}>
          <InfoIcon />
        </TooltipIconButton>
      </div>
    </CustomTooltip>
  );
};

NavigationTooltip.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export { NavigationTooltip };
