/* eslint-disable react/prop-types */
import React from 'react';
import { ApolloClient, HttpLink, ApolloProvider } from '@apollo/client';
import { InMemoryCache } from '@apollo/client/cache';
import { useAuth } from 'context/Auth';
import { getTenantId } from 'utils/tenantUtil';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

const Apollo = ({ children }) => {
  const { token, isLoading } = useAuth();

  const tenantId = getTenantId();
  let uri;
  if (process.env.REACT_APP_GRAPHQL_ENDPOINT) {
    uri = `${process.env.REACT_APP_GRAPHQL_ENDPOINT}?tenant=${tenantId}`;
  }

  if (!uri) {
    // if this happens the env vars are not set correctly
    return <div>no uri</div>;
  }

  if (isLoading) {
    return <LoadingIndicator />;
  }

  const cache = new InMemoryCache();
  const httpLink = new HttpLink({
    uri: uri,
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : []),
    },
  });

  const client = new ApolloClient({
    cache,
    link: httpLink,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

const PUBLIC_NO_AUTH_CONFIG = {
  context: {
    headers: {
      Authorization: '',
    },
  },
};

export { Apollo, PUBLIC_NO_AUTH_CONFIG };
