import { VALIDATION_CONSTANTS } from 'global/constants';
import * as Yup from 'yup';

export const YContact = Yup.object().shape({
  text: Yup.string()
    .min(10, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(300, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  name: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(75, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  userTo: Yup.string()
    .email(VALIDATION_CONSTANTS.INVALID_EMAIL)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  phone: Yup.string()
    .min(5, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(20, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  sendMail: Yup.boolean(),
});
