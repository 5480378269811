import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './FormPageWrapper.sass';

const FormPageWrapper = ({ children, className }) => {
  const classes = classnames('form-page-wrapper', {
    [className]: className,
  });
  return <div className={classes}>{children}</div>;
};

FormPageWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export { FormPageWrapper };
