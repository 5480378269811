import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import './SubTitle.sass';
import ClassNames from 'classnames';
import { MapTo } from '@adobe/aem-react-editable-components';

export const SubTitle = forwardRef((props, ref) => {
  const { text, className, id, notFramed } = props;

  const classes = ClassNames('sub-title', {
    [className]: !!className,
    ['sub-title__framed']: !notFramed,
  });

  return (
    <div id={id} className={classes} ref={ref}>
      {text}
    </div>
  );
});

SubTitle.displayName = 'SubTitle';

SubTitle.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  notFramed: PropTypes.bool,
};

SubTitle.defaultProps = {
  notFramed: false,
};

MapTo(['e-fix/components/content/subtitle'])(SubTitle);
