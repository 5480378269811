import React from 'react';
import PropTypes from 'prop-types';
import { CustomModal } from 'components/CustomModal';
import { InputField } from '../../FormHelper/InputField';
import { ActionButton } from 'components/ActionButton/ActionButton';
import './Popup.sass';
import { branchToCapital } from 'utils/DataUtils';
export function ExpertPopupDelete({
  formik,
  selectedRow,
  popupOpen,
  setPopupOpen,
  deleteExpert,
  branch,
}) {
  const onCancel = () => {
    formik.setFieldValue(`experts[${selectedRow}].deletionReason${branchToCapital(branch)}`, '');
    formik.setFieldValue(`experts[${selectedRow}].deletionDate${branchToCapital(branch)}`, '');
    setPopupOpen(false);
  };
  const onSubmit = () => {
    if (!formik.errors.experts) {
      deleteExpert(formik.values.experts[`${selectedRow}`].id, branch);
      setPopupOpen(false);
    }
  };

  const modalContent = () => {
    if (formik.values.experts && formik.values.experts[`${selectedRow}`])
      return (
        <div className="popup-content">
          <div className="popup-content__popup-subtext">
            Möchten Sie die Fachkraft {formik.values.experts[`${selectedRow}`].firstName}{' '}
            {formik.values.experts[`${selectedRow}`].lastName} wirklich entfernen?
          </div>
          <div className="popup-content__popup-field">
            <InputField
              label={`Grund`}
              name={`experts[${selectedRow}].deletionReason${branchToCapital(branch)}`}
              key={`experts[${selectedRow}].deletionReason${branchToCapital(branch)}`}
              id={`experts[${selectedRow}].deletionReason${branchToCapital(branch)}`}
              value={
                formik.values.experts[`${selectedRow}`][`deletionReason${branchToCapital(branch)}`]
              }
              oldValue=""
              fullWidth
              onChange={formik.handleChange}
              error={Boolean(
                formik.errors.experts && formik.errors.experts[`${selectedRow}`]
                  ? formik.errors.experts[`${selectedRow}`][
                      `deletionReason${branchToCapital(branch)}`
                    ]
                  : ''
              )}
              helperText={
                formik.errors.experts && formik.errors.experts[`${selectedRow}`]
                  ? formik.errors.experts[`${selectedRow}`][
                      `deletionReason${branchToCapital(branch)}`
                    ]
                  : ''
              }></InputField>
          </div>
          <div className="popup-content__popup-field">
            <InputField
              label={`Austritt am`}
              name={`experts[${selectedRow}].deletionDate${branchToCapital(branch)}`}
              key={`experts[${selectedRow}].deletionDate${branchToCapital(branch)}`}
              id={`experts[${selectedRow}].deletionDate${branchToCapital(branch)}`}
              value={
                formik.values.experts[`${selectedRow}`][`deletionDate${branchToCapital(branch)}`]
              }
              oldValue=""
              fullWidth
              onChange={formik.handleChange}
              error={Boolean(
                formik.errors.experts && formik.errors.experts[`${selectedRow}`]
                  ? formik.errors.experts[`${selectedRow}`][
                      `deletionDate${branchToCapital(branch)}`
                    ]
                  : ''
              )}
              helperText={
                formik.errors.experts && formik.errors.experts[`${selectedRow}`]
                  ? formik.errors.experts[`${selectedRow}`][
                      `deletionDate${branchToCapital(branch)}`
                    ]
                  : ''
              }></InputField>
          </div>
        </div>
      );
    else {
      return <>Error</>;
    }
  };

  return (
    <CustomModal
      isOpen={popupOpen}
      title={'Fachkraft entfernen'}
      ctaButtons={
        <>
          <ActionButton onClick={onSubmit} title="Ok"></ActionButton>
          <ActionButton inverted onClick={onCancel} title="Abbrechen"></ActionButton>
        </>
      }
      content={modalContent()}></CustomModal>
  );
}
ExpertPopupDelete.propTypes = {
  formik: PropTypes.object,
  selectedRow: PropTypes.number,
  popupOpen: PropTypes.bool,
  branch: PropTypes.string,
  setPopupOpen: PropTypes.func,
  deleteExpert: PropTypes.func,
};
