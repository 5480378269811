import React from 'react';
import PropTypes from 'prop-types';
import { CustomModal } from 'components/CustomModal';
import { InputField } from '../../FormHelper/InputField';
import { ActionButton } from 'components/ActionButton/ActionButton';
import './Popup.sass';
export function SealsPopup({ oldData, formik, selectedRow, popupOpen, setPopupOpen }) {
  const modalContent = () => {
    if (formik.values.seals && formik.values.seals[`${selectedRow}`])
      return (
        <div className="popup-content">
          <div className="popup-content__popup-subtext">
            Möchten Sie Plombierausrüstung {formik.values.seals[`${selectedRow}`].sealNumber}{' '}
            ausgegeben am {formik.values.seals[`${selectedRow}`].issuedOn} als verloren melden?
          </div>
          <div className="popup-content__popup-field">
            <InputField
              label="Verloren am*"
              name={`seals[${selectedRow}].lostDate`}
              key={`seals[${selectedRow}].lostDate`}
              id={`seals[${selectedRow}].lostDate`}
              value={formik.values.seals[`${selectedRow}`].lostDate}
              onChange={formik.handleChange}
              oldValue={''}
              fullWidth
              error={Boolean(
                formik.errors.seals ? formik.errors.seals[`${selectedRow}`]?.lostDate : ''
              )}
              helperText={
                formik.errors.seals ? formik.errors.seals[`${selectedRow}`]?.lostDate : ''
              }></InputField>
          </div>
          <div className="popup-content__popup-field">
            <InputField
              label="Grund"
              name={`seals[${selectedRow}].lostReason`}
              key={`seals[${selectedRow}].lostReason`}
              id={`seals[${selectedRow}].lostReason`}
              value={formik.values.seals[`${selectedRow}`].lostReason}
              onChange={formik.handleChange}
              oldValue={''}
              fullWidth
              error={Boolean(
                formik.errors.seals ? formik.errors.seals[`${selectedRow}`]?.lostReason : ''
              )}
              helperText={
                formik.errors.seals ? formik.errors.seals[`${selectedRow}`]?.lostReason : ''
              }></InputField>
          </div>
        </div>
      );
    else {
      return <></>;
    }
  };

  return (
    <CustomModal
      isOpen={popupOpen}
      title={'Verlustmeldung Plombierausrüstung'}
      ctaButtons={
        <>
          <ActionButton
            onClick={() => {
              if (!formik.errors.seals) setPopupOpen(false);
            }}
            title="Ok"></ActionButton>
          <ActionButton
            inverted
            onClick={() => {
              formik.setFieldValue(`seals[${selectedRow}]`, oldData[selectedRow]);
              setPopupOpen(false);
            }}
            title="Abbrechen"></ActionButton>
        </>
      }
      content={modalContent()}></CustomModal>
  );
}
SealsPopup.propTypes = {
  data: PropTypes.array,
  oldData: PropTypes.array,
  formik: PropTypes.object,
  selectedRow: PropTypes.number,
  popupOpen: PropTypes.bool,
  setPopupOpen: PropTypes.func,
};
