import React, { useEffect } from 'react';
import { MapTo } from '@adobe/aem-react-editable-components';
import BusinessUnitSelection from '../ConnectionService/BusinessUnitSelection/BusinessUnitSelection';
import RegionDetails from '../ConnectionService/RegionDetails/RegionDetails';
import './ShngQasi.sass';

const DATA = {
  businessUnitSelection: {
    title: 'Anlagenprüfung',
    subTitle: 'Bitte wählen Sie das Gebiet aus, in dem Sie arbeiten möchten',
    secondSubTitle: 'Übersicht Prüfgebiete SHNG Strom und Gas',
    options: {
      1: {
        subTitle: 'Übersicht Prüfgebiete SHNG Strom',
        subTitleDescription:
          'Sie erreichen unsere Strom-Prüfmeister unter folgenden Telefonnummern:',
        imageName: 'connection_service_business_unit_1.png',
        mapImageName: 'qualitat_region_1.png',
        contactDetails: {
          data: [
            {
              type: 'value',
              data: {
                value: 'Region Nord: Friedrichstadt, Niebüll, Schuby, Süderbrarup',
              },
            },
            {
              type: 'tel',
              appendTelPrefix: true,
              data: {
                value: '+49 4621 9429565',
                name: 'Ralf Schill',
              },
            },
            {
              type: 'value',
              data: {
                value: 'Region Meldorf: Meldorf',
              },
            },
            {
              type: 'tel',
              appendTelPrefix: true,
              data: {
                value: '+49 4331 182849',
                name: 'Finn-Tomke Witt',
              },
            },
            {
              type: 'value',
              data: {
                value: 'Region Mitte: Dägeling, Fockbek, Kaltenkirchen, Malente, Uetersen',
              },
            },
            {
              type: 'tel',
              appendTelPrefix: true,
              data: {
                value: '+49 4331 182728',
                name: 'Jörg Muschiol',
              },
            },
            {
              type: 'value',
              data: {
                value: 'Region Süd: Ahrensburg, Bad Sebegerg, Pönitz, Schwarzenbek',
              },
            },
            {
              type: 'tel',
              appendTelPrefix: true,
              data: {
                value: '+49 4102 4942304',
                name: 'Björn-Oke Berwald',
              },
            },
          ],
        },
      },
      2: {
        subTitle: 'Übersicht Prüfgebiete SHNG Gas',
        subTitleDescription: 'Sie erreichen unsere Gas-Prüfmeister unter folgenden Telefonnummern:',
        imageName: '',
        mapImageName: 'qualitat_region_2.png',
        contactDetails: {
          data: [
            {
              type: 'value',
              data: {
                value: 'Region Nord: Friedrichstadt, Meldorf, Niebüll, Schuby, Süderbrarup',
              },
            },
            {
              type: 'tel',
              appendTelPrefix: true,
              data: {
                value: '+49 4331 18-2849',
                name: 'Finn-Tomke Witt',
              },
            },
            {
              type: 'tel',
              appendTelPrefix: true,
              data: {
                value: '+49 4661 9640-9563',
                name: 'Ralf Binge',
              },
            },
            {
              type: 'value',
              data: {
                value: 'Region Mitte: Dägeling, Fockbek, Kaltenkirchen, Uetersen',
              },
            },
            {
              type: 'tel',
              appendTelPrefix: true,
              data: {
                value: '+49 4321 60287-6363',
                name: 'Jörg Hagedorn',
              },
            },
            {
              type: 'value',
              data: {
                value: 'Region Süd: Ahrensburg, Bad Segeberg, Malente, Plön, Pönitz, Schwarzenbek',
              },
            },
            {
              type: 'tel',
              appendTelPrefix: true,
              data: {
                value: '+49 4551 522-539',
                name: 'Holger Poppitz',
              },
            },
          ],
        },
      },
      3: {
        subTitle: 'Übersicht Prüfgebiete ElbEnergie:',
        subTitleDescription: 'Sie erreichen unsere Prüfmeister unter folgenden Telefonnummern:',
        subTitleSecondDescription: 'Übersicht Prüfgebiete Gas ElbEnergie',
        imageName: 'connection_service_business_unit_2.png',
        mapImageName: 'connection_service_region_2.png',
        contactDetails: {
          data: [
            {
              type: 'value',
              data: {
                value: 'Region Hittfeld:',
              },
            },
            {
              type: 'tel',
              appendTelPrefix: true,
              data: {
                value: '+49 4551 522539',
                name: 'Holger Poppitz',
              },
            },
            {
              type: 'tel',
              appendTelPrefix: true,
              data: {
                value: '+49 40 2353 3949',
                name: 'Carsten Holsten',
              },
            },
          ],
        },
      },
      4: {
        subTitle: 'Übersicht Prüfgebiete HanseGas:',
        subTitleDescription: 'Sie erreichen unsere Prüfmeister unter folgenden Telefonnummern:',
        imageName: 'connection_service_business_unit_3.png',
        mapImageName: 'qualitat_region_3.png',
        contactDetails: {
          data: [
            {
              type: 'value',
              data: {
                value: 'Region Bützow: Bützow, Brüel, Gägelow, Warnitz',
              },
            },
            {
              type: 'tel',
              appendTelPrefix: true,
              data: {
                value: '+49 3846 1512-132',
                name: 'Raimond Teufel',
              },
            },
            {
              type: 'value',
              data: {
                value: 'Region GVP: Greifswald',
              },
            },
            {
              type: 'tel',
              appendTelPrefix: true,
              data: {
                value: '+49 151 17219219',
                name: 'Matthias Siedler',
              },
            },
            {
              type: 'value',
              data: {
                value: 'Region Roggentin: Löbnitz, Roggentin, Satow, Wustrow',
              },
            },
            {
              type: 'tel',
              appendTelPrefix: true,
              data: {
                value: '+49 38204 6874730',
                name: 'Jan Struck',
              },
            },
            {
              type: 'value',
              data: {
                value: 'Region Wittenburg: Lenzen, Spornitz, Wittenburg',
              },
            },
            {
              type: 'tel',
              appendTelPrefix: true,
              data: {
                value: '+49 3885 2660-4320',
                name: 'Rolf Pachurka',
              },
            },
          ],
        },
      },
      5: {
        subTitleDescription: '',
        imageName: 'connection_service_business_unit_4.png',
      },
    },
  },
};

const ShngQasi = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const businessSelectionData = DATA.businessUnitSelection;
  const options = businessSelectionData.options;

  const businessSelectionOptions =
    Object.keys(options || {}).map((key) => ({
      key: `businessSelectionOption_${key}`,
      alt: options[key]?.subTitle,
      imageName: options[key]?.imageName,
    })) || [];

  const regionsDataOptions = Object.keys(options || []).map((key) => ({
    key: `regionDataOption_${key}`,
    option: options[key],
  }));

  const scrollToRegion = (region) => {
    const element = document.getElementById(
      `regionDataOption_${region?.replace('businessSelectionOption_', '')}`
    );
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className=" expert-page">
      <div className="expert-page-content-stage-text">
        <BusinessUnitSelection
          options={businessSelectionOptions}
          onOptionSelected={(value) => scrollToRegion(value)}
        />
        {businessSelectionData.secondSubTitle && (
          <div className="shng-expert-sub-text">{businessSelectionData.secondSubTitle}</div>
        )}
        <div className="support-email-sub-text">
          Bei Fragen zu den technischen Anschlussbedingungen erreichen Sie uns unter{' '}
          <a className="support-email" href="mailto:TAB@sh-netz.com">
            TAB@sh-netz.com
          </a>
        </div>
        <div className="regionDetails">
          <RegionDetails regionsDataOptions={regionsDataOptions} />
        </div>
      </div>
    </div>
  );
};

MapTo(['e-fix/components/content/shngqasi'])(ShngQasi);
