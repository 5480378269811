/* eslint-disable react/prop-types */
import React, { createContext } from 'react';
import usePersistedState from '../hooks/PersistedState';

export const EventIdContext = createContext({});

const EventIdContextProvider = ({ children }) => {
  const [id, setId] = usePersistedState('id', null);

  return <EventIdContext.Provider value={{ id, setId }}>{children}</EventIdContext.Provider>;
};

export default EventIdContextProvider;
