import { getInstallerById } from 'api/installer/GetInstallerById';
import { DEB_CURRENT_TEST_SUITE, shouldFillTestData } from 'components/DEBForm/Utils/DebTestSetup';
import { ErrorHandlingContext } from 'context/ErrorHandling';
import { useInstallerInfo } from 'context/InstallerInfoContext';
import { useContext, useEffect, useState } from 'react';

export const useCurrentInstaller = () => {
  // const [installer, setInstaller] = useState(null);
  const [installer, setInstaller] = useState(
    shouldFillTestData() ? DEB_CURRENT_TEST_SUITE.installerData : {}
  );
  const { installerInfo, isEIV } = useInstallerInfo();
  const [isLoading, setIsLoading] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);
  const { setShowError } = useContext(ErrorHandlingContext);

  useEffect(() => {
    if (shouldFillTestData()) {
      return;
    }
    (async () => {
      if (!installer) {
        setIsLoading(true);
      }
      if (isEIV === false) {
        setIsLoading(false);
      } else if (isEIV === true) {
        if (installerInfo) {
          try {
            const responseInstaller = await getInstallerById(installerInfo.id, setShowError);
            setInstaller(responseInstaller);
            setIsLoading(false);
          } catch (e) {
            setIsLoading(false);
            console.log('useCurrentInstaller info error', e);
          }
        }
      }
    })();
  }, [installerInfo, isEIV]);

  return { installer, isLoading, installerInfo, isEIV };
};
