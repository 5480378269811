/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2018 Adobe Systems Incorporated
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
import {
  MapTo,
  Page,
  Utils,
  withComponentMappingContext,
} from '@adobe/aem-react-editable-components';
import _ from 'lodash';
import React from 'react';
import { withRoute } from '../../utils/RouteHelper';
import './BasicLayout.sass';

import ComponentContainer from '../_shared/ComponentContainer';

class BasicLayout extends Page {
  constructor(props) {
    super(props);

    this.regions = ['breadcrumb', 'content'];
    this.isEditor = Utils.isInEditor();
  }

  parseRegionsData(region) {
    const newProps = _.cloneDeep(this.props);
    for (const [key] of Object.entries(newProps.cqItems.root[':items'])) {
      if (key !== region) delete newProps.cqItems.root[':items'][key];
    }
    newProps.cqItems.root[':itemsOrder'] = [region];

    if (!newProps.cqItems.root[':items'][region]) return null;

    return newProps;
  }

  get regionsData() {
    const regionsData = {};
    this.regions.forEach((region) => {
      regionsData[region] = this.parseRegionsData(region);
    });

    return regionsData;
  }

  render() {
    const { breadcrumb, content } = this.regionsData;

    return (
      <div className="basic-layout">
        {breadcrumb && <ComponentContainer {...breadcrumb} />}
        {content && <ComponentContainer {...content} />}
      </div>
    );
  }
}

MapTo(['e-fix/components/page/basepage'])(withComponentMappingContext(withRoute(BasicLayout)));
