import React from 'react';

import './PublicHeader.sass';
import { getTenantInfo } from 'utils/tenantUtil';

const PublicHeader = () => {
  const tenantInfo = getTenantInfo();
  return (
    <div className="public-header">
      <div className="public-header__container">
        <div className="public-header__logo">
          <img src={tenantInfo.logo} alt="logo" />
        </div>
      </div>
      <div className="public-header__border" />
    </div>
  );
};

PublicHeader.displayName = 'PublicHeader';

PublicHeader.propTypes = {};

PublicHeader.defaultProps = {};

export { PublicHeader };
