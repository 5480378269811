import React, { useMemo } from 'react';
import './HintText.sass';
import PropTypes from 'prop-types';
import { HINTTEXT_TYPE } from './HintTextType';

const HintText = ({ children, type }) => {
  const getClassnameForType = useMemo(() => {
    switch (type) {
      case HINTTEXT_TYPE.INFO:
        return 'info';
      case HINTTEXT_TYPE.ERROR:
        return 'error';
      default:
        return '';
    }
  }, [type]);

  return (
    <div className={`hint-text`}>
      <div className={`${getClassnameForType}`}>{children}</div>
    </div>
  );
};

HintText.defaultProps = {
  type: HINTTEXT_TYPE.INFO,
};

HintText.propTypes = {
  children: PropTypes.oneOf(PropTypes.string, PropTypes.node).isRequired,
  type: PropTypes.oneOf(Object.values(HINTTEXT_TYPE)),
};

export { HintText };
