import { DOMAIN_CIAM_LOGIN_PREFIX, DOMAIN_PREFIX, STAGE } from 'global/constants';

// local, development, staging, production
export const getStage = () => {
  return process.env.REACT_APP_STAGE;
};

export const getDomainPrefixByStage = () => {
  let prefix = '';
  switch (getStage()) {
    case STAGE.LOCAL:
      prefix = DOMAIN_PREFIX.LOCAL;
      break;
    case STAGE.DEVELOPMENT:
      prefix = DOMAIN_PREFIX.DEVELOPMENT;
      break;
    case STAGE.STAGING:
      prefix = DOMAIN_PREFIX.STAGING;
      break;
    case STAGE.PRODUCTION:
      prefix = DOMAIN_PREFIX.PRODUCTION;
      break;
  }
  return prefix;
};

export const getDomainCIAMLoginPrefixByStage = () => {
  let prefix = '';
  switch (getStage()) {
    case STAGE.LOCAL:
      prefix = DOMAIN_CIAM_LOGIN_PREFIX.LOCAL;
      break;
    case STAGE.DEVELOPMENT:
      prefix = DOMAIN_CIAM_LOGIN_PREFIX.DEVELOPMENT;
      break;
    case STAGE.STAGING:
      prefix = DOMAIN_CIAM_LOGIN_PREFIX.STAGING;
      break;
    case STAGE.PRODUCTION:
      prefix = DOMAIN_CIAM_LOGIN_PREFIX.PRODUCTION;
      break;
  }
  return prefix;
};

export const isLocal = () => {
  return getStage() === 'local';
};

export const isDevelopment = () => {
  return getStage() === 'development';
};

export const isStaging = () => {
  return getStage() === 'staging';
};

export const isProduction = () => {
  return getStage() === 'production';
};
