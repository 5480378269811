import {
  BRANCH_TYPE,
  BRANCH_TYPE_TO_GERMAN_NAME,
  EIV_ENTRY_TYPE,
  EIV_STATUS,
  EIV_STATUS_ALLOW_RENDER,
  INSTALLER_ENTRIES,
  TENANT_ID,
} from 'global/constants';
import { getTenantId, isBUofCurrentTenant, isGridnetProviderOfCurrentTenant } from './tenantUtil';
import { add, isBefore, parse } from 'date-fns';
import { parseEIVDateStringToEfixDateString } from './DatesUtils';

export const getEntryType = (installerEntry) => {
  if (!installerEntry) {
    return 'none';
  }

  if (installerEntry === EIV_ENTRY_TYPE.MAIN) {
    return 'main';
  }

  if (installerEntry === EIV_ENTRY_TYPE.GUEST) {
    return 'guest';
  }
};

export const doesInstallerHaveBranch = (branch, installerStatusSummary) => {
  return (
    installerStatusSummary[branch].entryType !== 'none' &&
    [EIV_STATUS.ACTIVE, EIV_STATUS.INACTIVE, EIV_STATUS.IN_PROGRESS].includes(
      installerStatusSummary[branch].status
    )
  );
};
export const isBranchGuest = (installerStatusSummary, branch) => {
  return installerStatusSummary[branch].entryType === 'guest';
};

export const canBranchRenderByStatus = (installerStatusSummary, branch) => {
  return EIV_STATUS_ALLOW_RENDER.includes(installerStatusSummary[branch].status);
};

export const hasGuestBranchExtended = (experts, initialExperts, branch) => {
  let hasExtended;
  if (branch === BRANCH_TYPE.ENERGY) {
    hasExtended = experts.some((expert, index) => {
      if (
        initialExperts[index] &&
        expert.expertEnergyValidityDate !== initialExperts[index].expertEnergyValidityDate
      ) {
        return true;
      }
      return false;
    });
  }
  if (branch === BRANCH_TYPE.GAS) {
    hasExtended = experts.some((expert, index) => {
      if (
        initialExperts[index] &&
        expert.expertGasValidityDate !== initialExperts[index].expertGasValidityDate
      ) {
        return true;
      }
      return false;
    });
  }

  return hasExtended;
};

export const getBranchStatusAndExpireDate = (installer, branchType) => {
  const branchTypeVar = branchType === BRANCH_TYPE.ENERGY ? 'strom' : 'gas';

  return [installer[`status_${branchTypeVar}`], installer[`ausw_${branchTypeVar}_gueltig`]];
};

export const getInstallerStatusbarData = (installer) => {
  let energyEntry;
  let gasEntry;
  let limitationEntry;

  const installerStatusSummary = getInstallerStatusSummary(installer);

  const hasEnergyRow = doesInstallerHaveBranch(BRANCH_TYPE.ENERGY, installerStatusSummary);

  const hasGasRow = doesInstallerHaveBranch(BRANCH_TYPE.GAS, installerStatusSummary);

  if (hasEnergyRow) {
    energyEntry = {
      ...installerStatusSummary[BRANCH_TYPE.ENERGY],
      shouldExtend: isBefore(
        parse(installerStatusSummary[BRANCH_TYPE.ENERGY].expireDate, 'dd.MM.yyyy', new Date()),
        add(new Date(), { days: 30 })
      ),
      branch: BRANCH_TYPE_TO_GERMAN_NAME[BRANCH_TYPE.ENERGY],
    };
  }

  if (hasGasRow) {
    gasEntry = {
      ...installerStatusSummary[BRANCH_TYPE.GAS],
      shouldExtend: isBefore(
        parse(installerStatusSummary[BRANCH_TYPE.GAS].expireDate, 'dd.MM.yyyy', new Date()),
        add(new Date(), { days: 30 })
      ),
      branch: BRANCH_TYPE_TO_GERMAN_NAME[BRANCH_TYPE.GAS],
    };
  }

  let companyName = installer.firmenname2
    ? installer.firmenname1 + ' ' + installer.firmenname2
    : installer.firmenname1;
  if (getTenantId() === TENANT_ID.BAG) {
    limitationEntry = {
      energy: hasEnergyRow ? installer.eintragungsart_erg_strom : undefined,
      gas: hasGasRow ? installer.eintragungsart_erg_gas : undefined,
    };
    if (installer.firmenname3) {
      companyName += ' ' + installer.firmenname3;
    }
  }

  return {
    id: installer.id,
    name: companyName,
    energyEntry,
    gasEntry,
    limitationEntry,
  };
};

/**
 * return one of the following:
 * [mainEnergyOnly,
 * mainGasOnly,
 * guestOnly,
 * mainBoth,
 * mainEnergyGuestGas,
 * guestEnergyMainGas]
 * based on the values of enrgyEntry and gasEntry that can be 'main', 'guest' or 'none'
 *
 * @param {*} energyEntry
 * @param {*} gasEntry
 * @param {*} statusEnergy
 * @param {*} statusGas
 * @returns {*}
 */
const getInstallerEntry = (energyEntry, gasEntry, statusEnergy, statusGas) => {
  const allowedStatuses = [EIV_STATUS.ACTIVE, EIV_STATUS.IN_PROGRESS, EIV_STATUS.INACTIVE];
  let energyType = '';
  let gasType = '';
  if (allowedStatuses.includes(statusEnergy)) {
    energyType = energyEntry;
  }
  if (allowedStatuses.includes(statusGas)) {
    gasType = gasEntry;
  }

  if (energyType === 'main' && gasType === 'main') {
    return INSTALLER_ENTRIES.MAIN_BOTH;
  } else if (energyType === 'main' && gasType === 'guest') {
    return INSTALLER_ENTRIES.MAIN_ENERGY_GUEST_GAS;
  } else if (energyType === 'guest' && gasType === 'main') {
    return INSTALLER_ENTRIES.GUEST_ENERGY_MAIN_GAS;
  } else if (energyType === 'main' && gasType === '') {
    return INSTALLER_ENTRIES.MAIN_ENERGY_ONLY;
  } else if (energyType === '' && gasType === 'main') {
    return INSTALLER_ENTRIES.MAIN_GAS_ONLY;
  } else if (energyType === 'guest' || gasType === 'guest') {
    return INSTALLER_ENTRIES.GUEST_ONLY;
  }
  return null; // or a default value if none of the conditions are met
};

/**
 * Description placeholder
 *
 * @param {*} installer
 * @returns {{ [x: BRANCH_TYPE]: { status: any; entryType: string; companyType: any; expireDate: any; }; installerEntry: any; }}
 */
export const getInstallerStatusSummary = (installer) => {
  const [energyStatus, energyExpireDate] = getBranchStatusAndExpireDate(
    installer,
    BRANCH_TYPE.ENERGY
  );
  const [gasStatus, gasExpireDate] = getBranchStatusAndExpireDate(installer, BRANCH_TYPE.GAS);

  const entryTypeEnergy = getEntryType(installer.eintragungsart_strom);
  const entryTypeGas = getEntryType(installer.eintragungsart_gas);
  const installerEntry = getInstallerEntry(entryTypeEnergy, entryTypeGas, energyStatus, gasStatus);

  return {
    [BRANCH_TYPE.ENERGY]: {
      status: energyStatus,
      entryType: getEntryType(installer.eintragungsart_strom),
      companyType: installer.b_betriebsart_strom?.name,
      expireDate: parseEIVDateStringToEfixDateString(energyExpireDate),
    },
    [BRANCH_TYPE.GAS]: {
      status: gasStatus,
      entryType: getEntryType(installer.eintragungsart_gas),
      companyType: installer.b_betriebsart_gas?.name,
      expireDate: parseEIVDateStringToEfixDateString(gasExpireDate),
    },
    installerEntry,
  };
};

export const hasMainEntry = (installerData) => {
  const {
    pflegende_bu,
    b_netzbetreiber_strom,
    b_netzbetreiber_gas,
    eintragungsart_strom,
    eintragungsart_gas,
  } = installerData;

  if (!isBUofCurrentTenant(pflegende_bu)) {
    return false;
  }
  const energyEntryType = getEntryType(eintragungsart_strom);
  const gasEntryType = getEntryType(eintragungsart_gas);

  if (energyEntryType !== 'main' && gasEntryType !== 'main') {
    return false;
  }
  // SHNG and EDIS are skipped due to changing list of grid providers
  if ([TENANT_ID.SHNG, TENANT_ID.EDI].includes(getTenantId())) {
    return true;
  } else {
    if (energyEntryType === 'main' && isGridnetProviderOfCurrentTenant(b_netzbetreiber_strom.id)) {
      return true;
    }
    if (gasEntryType === 'main' && isGridnetProviderOfCurrentTenant(b_netzbetreiber_gas.id)) {
      return true;
    }
  }

  return false;
};

export const hasDownloadEntry = (installerData) => {
  const { pflegende_bu, eintragungsart_strom, eintragungsart_gas } = installerData;

  if (!isBUofCurrentTenant(pflegende_bu)) {
    return false;
  }

  const energyEntryType = getEntryType(eintragungsart_strom);
  const gasEntryType = getEntryType(eintragungsart_gas);

  const [energyStatus] = getBranchStatusAndExpireDate(installerData, BRANCH_TYPE.ENERGY);
  const [gasStatus] = getBranchStatusAndExpireDate(installerData, BRANCH_TYPE.GAS);

  // checking if both of them are guest to block the download tile showing as SHNG only want main entries downloads for now
  if (getTenantId() === TENANT_ID.SHNG) {
    if (
      (energyEntryType === 'guest' && gasEntryType === 'guest') ||
      (energyEntryType === 'guest' && gasEntryType === 'none') ||
      (energyEntryType === 'none' && gasEntryType === 'guest')
    ) {
      return false;
    } else {
      if (
        energyEntryType === 'main' &&
        gasEntryType !== 'main' &&
        energyStatus !== EIV_STATUS.ACTIVE
      ) {
        return false;
      } else if (
        gasEntryType === 'main' &&
        energyEntryType !== 'main' &&
        gasStatus !== EIV_STATUS.ACTIVE
      ) {
        return false;
      }
    }
  }

  return true;
};

export const hasGuestEntry = (installerData) => {
  const { pflegende_bu, eintragungsart_strom, eintragungsart_gas } = installerData;
  if (!isBUofCurrentTenant(pflegende_bu)) {
    return false;
  }

  const energyEntryType = getEntryType(eintragungsart_strom);
  const gasEntryType = getEntryType(eintragungsart_gas);

  if (energyEntryType !== 'guest' && gasEntryType !== 'guest') {
    return false;
  }

  return true;
};
