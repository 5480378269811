import { getResealAllowedIds } from 'api/APIUtils';
import { hasDownloadEntry, hasGuestEntry, hasMainEntry } from './InstallerUtils';

/**
 * Capitalizes the first letter of a word.
 *
 * @param {string} word - The word to capitalize.
 * @returns {string} - The capitalized word.
 */
export const branchToCapital = (word) => {
  if (word) {
    word = word.toLowerCase();
    return word.charAt(0).toUpperCase() + word.slice(1);
  } else {
    return word;
  }
};

/**
 * Determines if a data card should be shown based on the id and installer data.
 *
 * @param {string} id - The identifier for the data card.
 * @param {Object} installerData - The installer data to check against.
 * @returns {boolean} - True if the data card should be shown, otherwise false.
 */
export const shouldShowMyDataCard = (id, installerData) => {
  switch (id) {
    case 'guest-extension':
      return hasGuestEntry(installerData);
    case 'main-extension':
      return hasMainEntry(installerData);
    case 'document-download':
      return hasDownloadEntry(installerData);
    default:
      return true;
  }
};

/**
 * Converts a boolean value to its German equivalent.
 *
 * @param {boolean} boolValue - The boolean value to convert.
 * @returns {string} - 'Ja' for true, 'Nein' for false.
 */
export function booleanToGerman(boolValue) {
  return boolValue ? 'Ja' : 'Nein';
}

/**
 * Converts a boolean value to 'Ja' or '-' for email representation.
 *
 * @param {boolean} boolValue - The boolean value to convert.
 * @returns {string} - 'Ja' for true, '-' for false.
 */
export function booleanToYesOrHyphen(boolValue) {
  return boolValue ? 'Ja' : '-';
}

/**
 * Prepares a value for email, converting booleans to 'Ja'/'Nein' and empty values to '-'.
 *
 * @param {*} value - The value to prepare.
 * @returns {*} - The prepared value.
 */
export const prepValueForEmail = (value) => {
  if (typeof value == 'boolean') {
    return booleanToGerman(value);
  }
  return value || '-';
};
/**
 * Prepares a value for any form initial data, converting missing values to default values: [], ""
 *
 * @param {*} value - The value to prepare.
 * @returns {*} - The prepared value.
 */
export const prepValueForInitialData = (value) => {
  if (Array.isArray(value)) {
    return value.length ? value : [];
  }
  if (typeof value === 'number') {
    return value;
  }
  return value || '';
};

/**
 * Parses meter installation codes to their German equivalent.
 *
 * @param {string} meterInstallation - The meter installation code.
 * @returns {string} - 'Ja' for '01' or '02', 'Nein' otherwise.
 */
export const parseMeterInstallation = (meterInstallation) => {
  return meterInstallation === '01' || meterInstallation === '02' ? 'Ja' : 'Nein';
};

/**
 * Determines if resealing is allowed based on contract data.
 *
 * @param {Array} contracts - The list of contracts to check.
 * @returns {string} - 'Ja' if resealing is allowed, 'Nein' otherwise.
 */
export const canReseal = (contracts) => {
  let active = 'Nein';
  contracts.forEach((contract) => {
    if (
      getResealAllowedIds().includes(contract.vertragsart.id) &&
      contract.vertragsstatus === 'Aktiv'
    ) {
      active = 'Ja';
      return;
    }
  });
  return active;
};
