import * as Yup from 'yup';
import { YExpert } from './YExpert';
// import { YContract } from './YContract';
import { YDate, YZip } from 'utils/YupUtils';
import { YSeals } from './YSeals';
import { EIV_CHAR_LENGTH, VALIDATION_CONSTANTS } from 'global/constants';

export const YDebFormSchema = Yup.object().shape({
  companyName1: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.FIRMENNAME1, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  companyName2: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.FIRMENNAME2, VALIDATION_CONSTANTS.TOO_LONG),
  companyName3: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.FIRMENNAME3, VALIDATION_CONSTANTS.TOO_LONG),
  companyOwner: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.FIRMENNAME3, VALIDATION_CONSTANTS.TOO_LONG),
  companyStreet: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.BUERO_STRASSE, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  companyHouseNumber: Yup.string()
    .min(1, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.BUERO_HAUSNUMMER, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  companyPostal: Yup.string()
    .min(1, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.BUERO_PLZ, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  companyCity: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.BUERO_ORT, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  companyRegion: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.BUERO_ORTSTEIL, VALIDATION_CONSTANTS.TOO_LONG),
  phone: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.TELEFON_GESCHAEFT, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  mobile: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.TELEFON_MOBIL, VALIDATION_CONSTANTS.TOO_LONG),
  gridProviderEnergy: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.B_NETZBETREIBER_NAME, VALIDATION_CONSTANTS.TOO_LONG),
  foreignCompanyIdEnergy: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.AUSWEISNR_FREMD, VALIDATION_CONSTANTS.TOO_LONG),
  gridProviderGas: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.B_NETZBETREIBER_NAME, VALIDATION_CONSTANTS.TOO_LONG),
  foreignCompanyIdGas: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.AUSWEISNR_FREMD, VALIDATION_CONSTANTS.TOO_LONG),
  //contracts: Yup.array().of(YContract), NOTE: See YContract.js
  experts: Yup.array().of(YExpert),
  businessRegistrationDate: YDate,
  craftsChamberDate: YDate,
  commercialRegisterDate: YDate,
  seals: Yup.array().of(YSeals),
  doesEnergyBranchExist: Yup.bool().required(),
  doesGasBranchExist: Yup.bool().required(),
  gasAgreement: Yup.bool().when('doesGasBranchExist', {
    is: (doesGasBranchExist) => {
      return doesGasBranchExist === true;
    },
    then: Yup.bool().oneOf([true], VALIDATION_CONSTANTS.MANDATORY),
  }),
  energyAgreement: Yup.bool().when('doesEnergyBranchExist', {
    is: (doesEnergyBranchExist) => {
      return doesEnergyBranchExist === true;
    },
    then: Yup.bool().oneOf([true], VALIDATION_CONSTANTS.MANDATORY),
  }),
  doesNeedAvaGuestPostal: Yup.bool().required(),
  avaGuestPostal: Yup.number().when('doesNeedAvaGuestPostal', {
    is: (doesNeedAvaGuestPostal) => {
      return doesNeedAvaGuestPostal === true;
    },
    then: YZip.required(VALIDATION_CONSTANTS.MANDATORY),
  }),
});
