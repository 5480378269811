import { Constants, ModelManager } from '@adobe/aem-spa-page-model-manager';
import { createBrowserHistory } from 'history';
import React from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
import App from './App';
import Auth from './context/Auth';
import './global/style/normalize.sass';
import './global/style/fonts.scss';
import './global/style/index.scss';
import './global/style/main.sass';
import './global/style/material.min.css';
import './global/style/mediaquery.sass';
import './global/style/App.scss';
import './global/style/Main.scss';
import './ImportComponents';
import * as serviceWorker from './serviceWorker';
import { getTenantInfo } from './utils/tenantUtil';
import { AEM_PAGE_TEMPLATE } from 'global/constants';

document.addEventListener('DOMContentLoaded', () => {
  const appRenderer = (model) => {
    const history = createBrowserHistory();
    // eslint-disable-next-line
    const urlRegex = /code\=(.*?)\&/;
    let searchUrl = history.location.search;

    let isCIAM = true;
    if (searchUrl.search('auth=EIAM') !== -1) {
      isCIAM = false;
    }
    let authCode = null;
    if (searchUrl.search('code') !== -1) {
      authCode = history.location.search.match(urlRegex)[1];
    }

    let currentPage;
    const children = Object.values(model[Constants.CHILDREN_PROP]);
    if (children.length > 0) {
      currentPage = children[children.length - 1];
    }
    const isPublicPage = currentPage && currentPage.templateName === AEM_PAGE_TEMPLATE.PUBLIC_PAGE;

    render(
      <>
        <Auth code={authCode} isCIAM={isCIAM} isPublic={isPublicPage}>
          <Router history={history}>
            <App
              history={history}
              cqChildren={model[Constants.CHILDREN_PROP]}
              cqItems={model[Constants.ITEMS_PROP]}
              cqItemsOrder={model[Constants.ITEMS_ORDER_PROP]}
              cqPath={model[Constants.PATH_PROP]}
              locationPathname={window.location.pathname}
              isPublic={isPublicPage}
            />
          </Router>
        </Auth>
      </>,
      document.getElementById('root')
    );
  };

  const tenantInfo = getTenantInfo();

  // window.location.pathname would be /content/revu-global/e-fix/${tenant}/de.html
  // this would result in path /content/revu-global/e-fix/${tenant}/de.model.json
  // Note: "de" before .model.json
  // for some reason we need /${currentTenant}.model.json instead
  let path = `/content/revu-global/e-fix/${tenantInfo.aemPath}.model.json`;

  fetch(window.location.pathname.split('.html')[0] + '.model.json')
    .then((response) => {
      if (!response.ok) {
        if (response.status === 404) {
          throw new Error('404 Not Found');
        } else {
          throw new Error('Network response was not ok');
        }
      }
      return;
    })
    .then(() => {
      ModelManager.initialize({ path }).then((pageModel) => {
        appRenderer(pageModel);
      });
    })
    .catch((error) => {
      if (error.message === '404 Not Found') {
        // Redirect to another page in case of 404 error
        window.location.href = `/content/revu-global/e-fix/${tenantInfo.aemPath}/de.html`;
      } else {
        console.error('Error initializing the model:', error);
      }
    });
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
