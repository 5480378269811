/* eslint-disable react/prop-types */
import React, { createContext } from 'react';
import usePersistedState from '../hooks/PersistedState';

export const ArticlesAndEventsContext = createContext({});

const ArticlesAndEventsContextProvider = ({ children }) => {
  const [limit, setLimit] = usePersistedState('limit', 10);

  return (
    <ArticlesAndEventsContext.Provider value={{ limit, setLimit }}>
      {children}
    </ArticlesAndEventsContext.Provider>
  );
};

export default ArticlesAndEventsContextProvider;
