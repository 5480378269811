/* eslint-disable react/prop-types */
import React from 'react';
import './TwoColumnLayout.scss';

const TwoColumnsLayout = ({ leftColumn = null, rightColumn = null, outerTopContent = null }) => {
  return (
    <>
      {outerTopContent}
      <div className="layout-outer-container">
        <div className="layout-two-column-inner-container">
          <div className="layout-two-column-main-content">{leftColumn}</div>
          <div className="layout-two-column-sidebar-content">{rightColumn}</div>
        </div>
      </div>
    </>
  );
};

export default TwoColumnsLayout;
