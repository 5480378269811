/* eslint-disable react/prop-types */
import { useMutation } from '@apollo/client';
import classnames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { GET_ARTICLES_OVERVIEW_DATA_QUERY } from '../../../Queries/Queries';
import { REMOVE_NEW_TAG_FROM_ARTICLE_MUTATION } from '../../../Queries/Mutations';
import { formatDate } from 'utils/DatesUtils';
import isNewEntry from '../../OverviewLists/utils/isNewEntry';
import Tags from '../../Tags/Tags';
import LikeCard from '../LikeCard/LikeCard';
import { isDefined } from '../../../utils/objectUtils';

const ArticleCard = ({ article }) => {
  const {
    likes,
    id: articleId,
    creationDate,
    description,
    shortDescription,
    title,
    tags,
    path,
    enableFeedback,
  } = article;

  const checkPath = (path) => {
    if (path.startsWith('/')) {
      return path;
    }
    return `/${path}`;
  };

  const [removeNewTag] = useMutation(REMOVE_NEW_TAG_FROM_ARTICLE_MUTATION, {
    refetchQueries: () => [{ query: GET_ARTICLES_OVERVIEW_DATA_QUERY }],
    awaitRefetchQueries: true,
    variables: {
      articleId,
    },
  });

  const cardRef = useRef(null);
  const isNew = isNewEntry(tags);

  useEffect(() => {
    if (!cardRef.current || !isNew) return;
    // eslint-disable-next-line
  }, []);

  const cardClasses = classnames('article-meta-data', {
    'article-meta-data--new': isNew,
  });

  return (
    <div ref={cardRef} className={cardClasses} onClick={() => removeNewTag()}>
      <div className="article-meta-data__header">
        <div className="article-meta-data__data">
          <div className="article-meta-data__header-date">
            {`Veröffentlicht am ${formatDate(creationDate)}`}
          </div>
          <Tags tags={tags} />
        </div>
        <div className="article-meta-data__info">
          <div className="article-meta-data__info-item">
            <div className="article-meta-data__info-item-section">
              <Link to={checkPath(path)}>
                <div className="article-meta-data__info-text-container">
                  <div className="article-meta-data__info-item-title">{title}</div>
                  <div className="article-meta-data__info-item-text">
                    {isDefined(shortDescription) ? shortDescription : description}
                  </div>
                </div>
                <LikeCard isOverview={true} likesFromBE={likes} showLikes={enableFeedback} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
