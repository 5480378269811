/* eslint-disable react/prop-types */
import React from 'react';
import NetworkConnectionSubText from '../NetworkConnectionSubText/NetworkConnectionSubText';
import './NetworkConnectionSubtitle.scss';

const NetworkConnectionSubtitleComponent = ({
  description,
  descriptionEmail,
  descriptionAfterEmail = '',
}) => {
  return (
    <div className="networkConnectionSubtitle">
      <NetworkConnectionSubText
        text={description}
        textEmail={descriptionEmail}
        textAfterEmail="."
      />

      {descriptionAfterEmail && <NetworkConnectionSubText text={descriptionAfterEmail} />}
    </div>
  );
};

export default NetworkConnectionSubtitleComponent;
