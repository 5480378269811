import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CustomModal } from 'components/CustomModal';
import { getTenantId } from 'utils/tenantUtil';

import { LabelCheckbox } from 'components/LabelCheckbox/LabelCheckbox';
import './Popup.sass';
import energyIcon from '../../../assets/pics/energy_icon.svg';
import gasIcon from '../../../assets/pics/gas_icon.svg';

import { useFormik } from 'formik';
import { InputField } from 'components/FormHelper/InputField';
import { LabelRadiobutton } from 'components/LabelRadiobutton/LabelRadiobutton';
import { YNEExpertValidation } from 'components/NEValidation/NEExpertValidation';
import { uniqueId } from 'lodash';
import { FormActionButtons } from 'components/FormHelper/FormActionButtons';
import { isInstallerRegisteringOnlyForBranch } from 'components/NEForm/Utils/NEDataUtils';
import { BRANCH_TYPE, EIV_ENTRY_TYPE, TENANT_ID } from 'global/constants';

const NENewExpertPopup = ({ branchSelectionValues, popupOpen, setPopupOpen, mainFormik }) => {
  const formik = useFormik({
    validationSchema: YNEExpertValidation(branchSelectionValues),
    validateOnChange: true,
    initialValues: {
      lastName: '',
      firstName: '',
      isExpertEnergyActive: isInstallerRegisteringOnlyForBranch(
        BRANCH_TYPE.ENERGY,
        branchSelectionValues
      ),
      isExpertGasActive: isInstallerRegisteringOnlyForBranch(
        BRANCH_TYPE.GAS,
        branchSelectionValues
      ),
      birthdate: '',
      energyValidityDate: '',
      gasValidityDate: '',
      isExpertOwner: undefined,
    },
  });

  useEffect(() => {
    if (popupOpen) {
      formik.validateForm();
    }
  }, [popupOpen]);

  const onCancel = () => {
    formik.resetForm();
    setPopupOpen(false);
  };

  const onSubmit = () => {
    if (formik.isValid) {
      mainFormik.setFieldValue('experts', [
        ...mainFormik.values.experts,
        { ...formik.values, id: uniqueId() },
      ]);
      formik.resetForm();
      setPopupOpen(false);
    }
  };

  const modalContent = () => {
    return (
      <div className="ne-popup popup-content">
        <div className="ne-popup-field">
          <div className="flex-row">
            <div>Für die Sparte:</div>
            {branchSelectionValues.isEnergyActive ? (
              <LabelCheckbox
                label="Strom"
                id="isExpertEnergyActive"
                name="isExpertEnergyActive"
                error={Boolean(formik.errors?.noBranchSelected)}
                icon={energyIcon}
                value={formik.values.isExpertEnergyActive}
                onChange={formik.handleChange}></LabelCheckbox>
            ) : null}
            {branchSelectionValues.isGasActive ? (
              <LabelCheckbox
                label="Gas"
                id="isExpertGasActive"
                name="isExpertGasActive"
                error={Boolean(formik.errors?.noBranchSelected)}
                icon={gasIcon}
                value={formik.values.isExpertGasActive}
                onChange={formik.handleChange}></LabelCheckbox>
            ) : null}
          </div>
        </div>
        {!formik.errors.noBranchSelected && (
          <>
            <div className="ne-popup-field">
              <InputField
                label={`Nachname*`}
                name={`lastName`}
                key={`lastName`}
                id={`lastName`}
                fullWidth
                value={formik.values.lastName}
                onChange={formik.handleChange}
                oldValue={''}
                error={Boolean(formik.errors?.lastName)}
                helperText={formik.errors.lastName ? formik.errors?.lastName : ''}></InputField>
            </div>
            <div className="ne-popup-field">
              <InputField
                label={`Vorname*`}
                name={`firstName`}
                key={`firstName`}
                id={`firstName`}
                fullWidth
                value={formik.values.firstName}
                onChange={formik.handleChange}
                oldValue={''}
                error={Boolean(formik.errors?.firstName)}
                helperText={formik.errors.firstName ? formik.errors?.firstName : ''}></InputField>
            </div>
            {getTenantId() === TENANT_ID.BAG ? (
              <div className="ne-popup-field">
                <InputField
                  label="Geburtsdatum*"
                  name={`birthdate`}
                  key={`birthdate`}
                  id={`birthdate`}
                  value={formik.values.birthdate}
                  fullWidth
                  oldValue={''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors?.birthdate)}
                  helperText={formik.errors.birthdate ? formik.errors?.birthdate : ''}></InputField>
              </div>
            ) : null}
            {formik.values.isExpertEnergyActive &&
            branchSelectionValues.branchTypeEnergy === EIV_ENTRY_TYPE.GUEST ? (
              <div className="ne-popup-field">
                <InputField
                  label="Strom-Ausweis gültig bis*"
                  name={`energyValidityDate`}
                  key={`energyValidityDate`}
                  id={`energyValidityDate`}
                  value={formik.values.energyValidityDate}
                  fullWidth
                  oldValue={''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors?.energyValidityDate)}
                  helperText={
                    formik.errors.energyValidityDate ? formik.errors?.energyValidityDate : ''
                  }></InputField>
              </div>
            ) : null}
            {formik.values.isExpertGasActive &&
            branchSelectionValues.branchTypeGas === EIV_ENTRY_TYPE.GUEST ? (
              <div className="ne-popup-field">
                <InputField
                  label="Gas-Ausweis gültig bis*"
                  name={`gasValidityDate`}
                  key={`gasValidityDate`}
                  id={`gasValidityDate`}
                  value={formik.values.gasValidityDate}
                  fullWidth
                  oldValue={''}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors?.gasValidityDate)}
                  helperText={
                    formik.errors?.gasValidityDate ? formik.errors?.gasValidityDate : ''
                  }></InputField>
              </div>
            ) : null}
            {(formik.values.isExpertEnergyActive &&
              branchSelectionValues.branchTypeEnergy === EIV_ENTRY_TYPE.MAIN) ||
            (formik.values.isExpertGasActive &&
              branchSelectionValues.branchTypeGas === EIV_ENTRY_TYPE.MAIN) ? (
              <div className="ne-popup-field">
                <div className="flex-row">
                  <div>Die verantwortliche Fachkraft ist Inhaber/Geschäftsführer*?</div>
                  <LabelRadiobutton
                    label="Nein"
                    name={'isExpertOwner'}
                    id={`isExpertOwner`}
                    error={Boolean(formik.errors?.isExpertOwner)}
                    value={false}
                    checked={formik.values.isExpertOwner === false}
                    onChange={formik.handleChange}></LabelRadiobutton>
                  <LabelRadiobutton
                    label="Ja"
                    name="isExpertOwner"
                    id="isExpertOwner"
                    error={Boolean(formik.errors?.isExpertOwner)}
                    value={true}
                    checked={formik.values.isExpertOwner === true}
                    onChange={formik.handleChange}></LabelRadiobutton>
                </div>
              </div>
            ) : null}
          </>
        )}
        {/* <FFormDebug values={formik.values} errors={formik.errors}></FFormDebug> */}
      </div>
    );
  };

  return (
    <CustomModal
      isOpen={popupOpen}
      title={'Fachkraft hinzufügen'}
      ctaButtons={
        <FormActionButtons
          hasErrors={Object.keys(formik.errors).length > 0}
          titleLeft="Abbrechen"
          titleRight="Hinzufügen"
          onClickLeft={onCancel}
          onClickRight={onSubmit}
          errorMessage={
            formik.errors.noBranchSelected
              ? formik.errors.noBranchSelected
              : !formik.isValid
              ? 'Füllen Sie das Formular vollständig aus'
              : ''
          }
        />
      }
      content={modalContent()}></CustomModal>
  );
};

NENewExpertPopup.propTypes = {
  branchSelectionValues: PropTypes.object,
  popupOpen: PropTypes.bool,
  setPopupOpen: PropTypes.func,
  mainFormik: PropTypes.object,
};

export { NENewExpertPopup };
