import PropTypes from 'prop-types';
import React from 'react';
import './TenantSwitchButton.sass';
import { ActionButton } from 'components/ActionButton/ActionButton';
import classNames from 'classnames';

const TenantSwitchButton = ({ onClick, className }) => {
  const classes = classNames('tenant-switch-button', {
    [className]: !!className,
  });

  return <ActionButton title="Portalwechsel" onClick={onClick} className={classes} inverted />;
};

TenantSwitchButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export { TenantSwitchButton };
