/* eslint-disable default-case */

import React from 'react';

import { ReactComponent as SVGStorungen } from '../../assets/pics/stoerung-white.svg';
import { ReactComponent as SVGExpert } from '../../assets/pics/expertentelefon-white.svg';
import { ReactComponent as SVGExpertAva } from '../../assets/pics/expertentelefon_avacon.svg';
import { ReactComponent as SVGNaturalGasAva } from '../../assets/pics/naturalGas_avacon.svg';
import { ReactComponent as SVGLigthningAva } from '../../assets/pics/lightning_avacon.svg';
import { ReactComponent as SVGLexikon } from '../../assets/pics/Lexikon.svg';
import { ReactComponent as SVGLexikonAva } from '../../assets/pics/lexikon_avacon.svg';
import { ReactComponent as SVGNews } from '../../assets/pics/i-inka-icon_2-1.svg';
import { ReactComponent as SVGNewsAva } from '../../assets/pics/informationen_avacon.svg';
import { FORM_CONTANTS, TENANT_ID } from 'global/constants';
import {
  getDisorderPath,
  getEventsAndArticlesOverviewPath,
  getExpertTelephonePath,
  getLexikonHomePath,
  getMyDataPath,
} from 'utils/UrlUtil';

export const getSitesCards = (tenantId) => {
  switch (tenantId) {
    case TENANT_ID.EDI: {
      return {
        sites: [
          {
            id: 1,
            title: 'Expertentelefon',
            mainNaviTitle: 'Expertentelefon',
            icon: (
              <SVGExpert
                fill="white"
                width={FORM_CONTANTS.IMAGE_SIZE}
                height={FORM_CONTANTS.IMAGE_SIZE}
              />
            ),
            teaser: {
              elementsList: [
                {
                  name: 'Finden Sie hier den richtigen Experten!',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Expertenkontakt jetzt finden',
              linkedPage: getExpertTelephonePath(),
            },
          },
          {
            id: 2,
            title: 'Störungen',
            mainNaviTitle: 'Aktuelle Störungen',
            icon: (
              <SVGStorungen
                fill="white"
                width={FORM_CONTANTS.IMAGE_SIZE}
                height={FORM_CONTANTS.IMAGE_SIZE}
              />
            ),
            teaser: {
              elementsList: [
                {
                  name: 'Aktuelle Stromstörungen in unserem Netzgebiet',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Aktuelle Störungen anzeigen',
              linkedPage: getDisorderPath(),
            },
          },
          {
            id: 3,
            title: 'News & Veranstaltungen',
            mainNaviTitle: 'News & Veranstaltungen',
            icon: <SVGNews width={FORM_CONTANTS.IMAGE_SIZE} height={FORM_CONTANTS.IMAGE_SIZE} />,
            teaser: {
              elementsList: [
                {
                  name: 'Fachbeiträge',
                },
                {
                  name: 'Veranstaltungen',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Fachbeiträge & Veranstaltungen',
              linkedPage: getEventsAndArticlesOverviewPath(),
            },
          },
          {
            id: 4,
            title: 'Lexikon',
            mainNaviTitle: 'Lexikon',
            icon: (
              <SVGLexikon
                fill="white"
                width={FORM_CONTANTS.IMAGE_SIZE}
                height={FORM_CONTANTS.IMAGE_SIZE}
              />
            ),
            teaser: {
              elementsList: [
                {
                  name: 'Fachwissen und Informationen rund um den Hausanschluss aktuell und übersichtlich!',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Nachschlagen',
              linkedPage: getLexikonHomePath(),
            },
          },
          {
            id: 5,
            title: 'Meine Daten',
            mainNaviTitle: 'Meine Daten',
            icon: (
              <SVGLexikon
                fill="white"
                width={FORM_CONTANTS.IMAGE_SIZE}
                height={FORM_CONTANTS.IMAGE_SIZE}
              />
            ),
            teaser: {
              elementsList: [
                {
                  name: 'Ausweise herunterladen',
                },
                {
                  name: 'Ausweisverlängerung beantragen',
                },
                {
                  name: 'Datenschutz anpassen',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Nachschlagen',
              linkedPage: getMyDataPath(),
            },
          },
        ],
      };
    }
    case TENANT_ID.SHNG: {
      return {
        sites: [
          {
            id: 1,
            title: 'Expertentelefon',
            mainNaviTitle: 'Expertentelefon',
            icon: (
              <SVGExpert
                fill="white"
                width={FORM_CONTANTS.IMAGE_SIZE}
                height={FORM_CONTANTS.IMAGE_SIZE}
              />
            ),
            teaser: {
              elementsList: [
                {
                  name: 'Finden Sie hier den richtigen Experten!',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Expertenkontakt jetzt finden',
              linkedPage: getExpertTelephonePath(),
            },
          },
          {
            id: 2,
            title: 'Störungen',
            mainNaviTitle: 'Aktuelle Störungen',
            icon: (
              <SVGStorungen
                fill="white"
                width={FORM_CONTANTS.IMAGE_SIZE}
                height={FORM_CONTANTS.IMAGE_SIZE}
              />
            ),
            teaser: {
              elementsList: [
                {
                  name: 'Aktuelle Stromstörungen in unserem Netzgebiet',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Aktuelle Störungen anzeigen',
              linkedPage: getDisorderPath(),
            },
          },
          {
            id: 3,
            title: 'News & Veranstaltungen',
            mainNaviTitle: 'News & Veranstaltungen',
            icon: <SVGNews width={FORM_CONTANTS.IMAGE_SIZE} height={FORM_CONTANTS.IMAGE_SIZE} />,
            teaser: {
              elementsList: [
                {
                  name: 'Fachbeiträge',
                },
                {
                  name: 'Veranstaltungen',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Fachbeiträge & Veranstaltungen',
              linkedPage: getEventsAndArticlesOverviewPath(),
            },
          },
          {
            id: 4,
            title: 'Lexikon',
            mainNaviTitle: 'Lexikon',
            icon: (
              <SVGLexikon
                fill="white"
                width={FORM_CONTANTS.IMAGE_SIZE}
                height={FORM_CONTANTS.IMAGE_SIZE}
              />
            ),
            teaser: {
              elementsList: [
                {
                  name: 'Fachwissen und Informationen rund um den Hausanschluss aktuell und übersichtlich!',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Nachschlagen',
              linkedPage: getLexikonHomePath(),
            },
          },
          {
            id: 5,
            title: 'Meine Daten',
            mainNaviTitle: 'Meine Daten',
            icon: (
              <SVGLexikon
                fill="white"
                width={FORM_CONTANTS.IMAGE_SIZE}
                height={FORM_CONTANTS.IMAGE_SIZE}
              />
            ),
            teaser: {
              elementsList: [
                {
                  name: 'Ausweise herunterladen',
                },
                {
                  name: 'Ausweisverlängerung beantragen',
                },
                {
                  name: 'Datenschutz anpassen',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Nachschlagen',
              linkedPage: getMyDataPath(),
            },
          },
        ],
      };
    }
    case TENANT_ID.AVA: {
      return {
        sites: [
          {
            id: 1,
            title: 'Ansprechpartner',
            mainNaviTitle: 'Ansprechpartner',
            icon: (
              <SVGExpertAva
                fill="#005564"
                width={FORM_CONTANTS.IMAGE_SIZE}
                height={FORM_CONTANTS.IMAGE_SIZE}
              />
            ),
            teaser: {
              elementsList: [
                {
                  name: 'Hier finden Sie Ihre Ansprechpartner!',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Ansprechpartner suche',
              linkedPage: getExpertTelephonePath(),
            },
          },
          {
            id: 2,
            title: 'Störungen',
            mainNaviTitle: 'Aktuelle Störungen',
            icon: (
              <SVGNaturalGasAva
                fill="white"
                width={FORM_CONTANTS.IMAGE_SIZE}
                height={FORM_CONTANTS.IMAGE_SIZE}
              />
            ),
            secondIcon: (
              <SVGLigthningAva
                fill="white"
                width={FORM_CONTANTS.IMAGE_SIZE}
                height={FORM_CONTANTS.IMAGE_SIZE}
              />
            ),
            teaser: {
              elementsList: [
                {
                  name: 'Aktuelle Störungen und Meldestellen unseres Netzgebietes',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Aktuelle Störungen anzeigen',
              linkedPage: getDisorderPath(),
            },
          },
          {
            id: 3,
            title: 'News & Veranstaltungen',
            mainNaviTitle: 'News & Veranstaltungen',
            icon: <SVGNewsAva width={FORM_CONTANTS.IMAGE_SIZE} height={FORM_CONTANTS.IMAGE_SIZE} />,
            imageMoved: true,
            teaser: {
              elementsList: [
                {
                  name: 'Nachrichten',
                },
                {
                  name: 'Veranstaltungen',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Nachrichten & Veranstaltungen',
              linkedPage: getEventsAndArticlesOverviewPath(),
            },
          },
          {
            id: 4,
            title: 'Lexikon',
            mainNaviTitle: 'Lexikon',
            icon: (
              <SVGLexikonAva
                fill="#005564"
                width={FORM_CONTANTS.IMAGE_SIZE}
                height={FORM_CONTANTS.IMAGE_SIZE}
              />
            ),
            teaser: {
              elementsList: [
                {
                  name: 'Fachwissen und Informationen zu Netzanschluss und Installation',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Nachschlagen',
              linkedPage: getLexikonHomePath(),
            },
          },

          {
            id: 5,
            title: 'Meine Daten',
            mainNaviTitle: 'Meine Daten',
            icon: (
              <SVGLexikonAva
                fill="#005564"
                width={FORM_CONTANTS.IMAGE_SIZE}
                height={FORM_CONTANTS.IMAGE_SIZE}
              />
            ),
            teaser: {
              elementsList: [
                {
                  name: 'Ausweise herunterladen',
                },
                {
                  name: 'Ausweisverlängerung beantragen',
                },
                {
                  name: 'Datenschutz anpassen',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Nachschlagen',
              linkedPage: getMyDataPath(),
            },
          },
        ],
      };
    }
    case TENANT_ID.BAG: {
      return {
        sites: [
          {
            id: 1,
            title: 'Ansprechpartner',
            mainNaviTitle: 'Ansprechpartner',
            icon: (
              <SVGExpert
                fill="white"
                width={FORM_CONTANTS.IMAGE_SIZE}
                height={FORM_CONTANTS.IMAGE_SIZE}
              />
            ),
            teaser: {
              elementsList: [
                {
                  name: 'Hier finden Sie Ihre Ansprechpartner!',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Ansprechpartner suche',
              linkedPage: getExpertTelephonePath(),
            },
          },
          {
            id: 2,
            title: 'Störungen',
            mainNaviTitle: 'Aktuelle Störungen',
            icon: (
              <SVGStorungen
                fill="white"
                width={FORM_CONTANTS.IMAGE_SIZE}
                height={FORM_CONTANTS.IMAGE_SIZE}
              />
            ),
            teaser: {
              elementsList: [
                {
                  name: 'Aktuelle Störungen und Meldestellen unseres Netzgebietes',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Aktuelle Störungen anzeigen',
              linkedPage: getDisorderPath(),
            },
          },
          {
            id: 3,
            title: 'News & Veranstaltungen',
            mainNaviTitle: 'News & Veranstaltungen',
            icon: <SVGNews width={FORM_CONTANTS.IMAGE_SIZE} height={FORM_CONTANTS.IMAGE_SIZE} />,
            imageMoved: true,
            teaser: {
              elementsList: [
                {
                  name: 'Nachrichten',
                },
                {
                  name: 'Veranstaltungen',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Nachrichten & Veranstaltungen',
              linkedPage: getEventsAndArticlesOverviewPath(),
            },
          },
          {
            id: 4,
            title: 'Lexikon',
            mainNaviTitle: 'Lexikon',
            icon: (
              <SVGLexikon
                fill="white"
                width={FORM_CONTANTS.IMAGE_SIZE}
                height={FORM_CONTANTS.IMAGE_SIZE}
              />
            ),
            teaser: {
              elementsList: [
                {
                  name: 'Fachwissen und Informationen zu Netzanschluss und Installation',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Nachschlagen',
              linkedPage: getLexikonHomePath(),
            },
          },
          {
            id: 5,
            title: 'Meine Daten',
            mainNaviTitle: 'Meine Daten',
            icon: (
              <SVGLexikon
                fill="white"
                width={FORM_CONTANTS.IMAGE_SIZE}
                height={FORM_CONTANTS.IMAGE_SIZE}
              />
            ),
            teaser: {
              elementsList: [
                {
                  name: 'Ausweise herunterladen',
                },
                {
                  name: 'Ausweisverlängerung beantragen',
                },
                {
                  name: 'Datenschutz anpassen',
                },
              ],
            },
            actionLink: {
              actionLinkTitle: 'Nachschlagen',
              linkedPage: getMyDataPath(),
            },
          },
        ],
      };
    }
  }
};
