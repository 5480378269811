import { SHARED_DOCUMENTS } from 'global/SharedDocumentFields';

const DUMMY_FILE_INPUT_DATA = {
  name: 'char_lengths.PNG',
  contentType: 'image/png',
  base64: 'data:image/png;base64,content',
  size: 45091,
};

const fillFormUploadIV = (initialValues) => {
  initialValues.documentsTrigger.forEach((documentId) => {
    const ivDocumentKey = SHARED_DOCUMENTS[documentId].name;
    initialValues[ivDocumentKey] = [DUMMY_FILE_INPUT_DATA];
  });

  Object.keys(initialValues.expertTrigger).forEach((ivExpertKey) => {
    const expertTriggers = initialValues[ivExpertKey];
    Object.keys(expertTriggers).forEach((expertTriggerDocumentId) => {
      initialValues[ivExpertKey][expertTriggerDocumentId] = [DUMMY_FILE_INPUT_DATA];
    });
  });

  return initialValues;
};

const NE_FU = {
  fillFormUploadIV,
};

export { NE_FU };
