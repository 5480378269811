import { parse } from 'date-fns';

const getRelevantEventDay = (eventDays) => {
  return eventDays.find(
    (eventDay) =>
      parse(eventDay.date + eventDay.endTime, 'yyyy-MM-ddHH:mm', new Date()) > new Date()
  );
};

export { getRelevantEventDay };
