/* eslint-disable react/prop-types */
import React from 'react';
import './css/HamburgerMenuComponent.sass';
import HomePageHeaderSubLink from './HomePageHeaderSubLink';

const HamburgerMenuComponent = ({ navRoot, navLinks, installer }) => {
  const closeMenu = () => {
    let menu = document.getElementById('hamburg');
    if (menu.checked === true) {
      menu.checked = false;
    }
  };

  return (
    <div className="hamburger-menu-container">
      <div id="hamburger-menu" className="row">
        <input type="checkbox" id="hamburg" />
        <label htmlFor="hamburg" className="hamburg">
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </label>
        <div className="hamburger-menu-label">Menü</div>

        <div className="hamburger-menu-list">
          <HomePageHeaderSubLink
            name={navRoot.title}
            link={navRoot.url}
            key={navRoot.title}
            closeMenu={closeMenu}
            installer={installer}
          />
          {navLinks.map((link) => (
            <HomePageHeaderSubLink
              name={link.title}
              link={link.url}
              key={link.title}
              closeMenu={closeMenu}
              installer={installer}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HamburgerMenuComponent;
