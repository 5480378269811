import React, { useEffect } from 'react';
import { MapTo } from '@adobe/aem-react-editable-components';
import GenericInfoPage from '../../components/GenericInfoPage';
import { impressum1, impressum2, impressum3 } from '../../global/data/footerPagesContent';
import './ImpressumPage.scss';

const ImpressumPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="layout-outer-container imprint-page">
      <div className="para1">
        {impressum1.map((paragraph, index) => (
          <GenericInfoPage key={index} data={paragraph} />
        ))}
      </div>

      <div className="para2">
        {impressum2.map((paragraph, index) => (
          <GenericInfoPage key={index} data={paragraph} />
        ))}
      </div>

      <div className="para3">
        {impressum3.map((paragraph, index) => (
          <GenericInfoPage key={index} data={paragraph} />
        ))}
      </div>
    </div>
  );
};

MapTo('e-fix/components/content/impressum')(ImpressumPage);
