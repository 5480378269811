import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { CustomModal } from 'components/CustomModal';
import { InputField } from '../../FormHelper/InputField';
import { ActionButton } from 'components/ActionButton/ActionButton';
import './Popup.sass';
import { getTenantId } from 'utils/tenantUtil';
import { EIV_STATUS, EXPERT_EDITING_STATUS, TENANT_ID } from 'global/constants';
export function ExpertPopupEnergy({
  formik,
  selectedRow,
  popupOpen,
  setPopupOpen,
  edit = false,
  isGuest,
  oldData,
}) {
  useEffect(() => {
    if (selectedRow !== null && formik.values.experts[`${selectedRow}`]) {
      let status = '';
      if (popupOpen) {
        status = edit === true ? EXPERT_EDITING_STATUS.EDIT : EXPERT_EDITING_STATUS.NEW;
        if (edit) {
          formik.setFieldValue(`experts.${selectedRow}.expertEnergyValidityDate`, '');
        }
      }
      if (formik.values.experts[selectedRow]) {
        formik.setFieldValue(`experts.${selectedRow}.editingStatusEnergy`, status);
      }
    }
  }, [selectedRow, edit, popupOpen]);

  const onCancel = () => {
    if (formik.values.experts[`${selectedRow}`].statusEnergy === EIV_STATUS.NEW && !edit) {
      formik.setFieldValue(`experts`, formik.values.experts.slice(0, -1));
    } else if (edit) {
      if (oldData[`${selectedRow}`]) {
        formik.setFieldValue(`experts[${selectedRow}]`, {
          ...formik.values.experts[`${selectedRow}`],
          lastName: oldData[`${selectedRow}`].lastName,
          firstName: oldData[`${selectedRow}`].firstName,
          birthdate: oldData[`${selectedRow}`].birthdate,
          expertEnergyValidityDate: oldData[`${selectedRow}`].expertEnergyValidityDate,
        });
      }
    }
    setPopupOpen(false);
  };
  const onSubmit = () => {
    if (!formik.errors.experts) {
      if (
        formik.values.experts[`${selectedRow}`].lastName &&
        formik.values.experts[`${selectedRow}`].firstName
      ) {
        formik.values.experts.forEach((expert) => {
          if (
            formik.values.experts[`${selectedRow}`].lastName === expert?.lastName &&
            formik.values.experts[`${selectedRow}`].firstName === expert?.firstName &&
            expert.id != formik.values.experts[`${selectedRow}`].id
          ) {
            const copyExpert = { ...expert };
            const copyThis = { ...formik.values.experts[`${selectedRow}`] };
            const newObject = {
              ...copyExpert,
              ...copyThis,
              statusGas: copyExpert.statusGas,
              statusEnergy: copyThis.statusEnergy,
              expertGasValidityDate: copyExpert.expertGasValidityDate,
              expertEnergyValidityDate: copyThis.expertEnergyValidityDate,
              isMainGas: copyExpert.isMainGas,

              id: copyExpert.id,
            };
            let copyArray = [...formik.values.experts].filter((expert) => {
              return expert.id != copyThis.id && expert.id != copyExpert.id;
            });
            formik.setFieldValue(`experts`, copyArray.concat(newObject));
          }
        });
        setPopupOpen(false);
      }
    }
  };
  const modalContent = () => {
    if (formik.values.experts && formik.values.experts[`${selectedRow}`])
      return (
        <div className="popup-content">
          <div className="popup-content__popup-subtext">Fachkraft Sparte Strom</div>
          {!edit && (
            <>
              <div className="popup-content__popup-field">
                <InputField
                  label={`Name*`}
                  name={`experts[${selectedRow}].lastName`}
                  key={`experts[${selectedRow}].lastName`}
                  id={`experts[${selectedRow}].lastName`}
                  value={formik.values.experts[`${selectedRow}`].lastName}
                  onChange={formik.handleChange}
                  fullWidth
                  disabled={edit}
                  oldValue={oldData[`${selectedRow}`] ? oldData[`${selectedRow}`].lastName : ''}
                  error={Boolean(
                    formik.errors.experts ? formik.errors.experts[`${selectedRow}`]?.lastName : ''
                  )}
                  helperText={
                    formik.errors.experts ? formik.errors.experts[`${selectedRow}`]?.lastName : ''
                  }></InputField>
              </div>
              <div className="popup-content__popup-field">
                <InputField
                  label={`Vorname*`}
                  name={`experts[${selectedRow}].firstName`}
                  key={`experts[${selectedRow}].firstName`}
                  id={`experts[${selectedRow}].firstName`}
                  value={formik.values.experts[`${selectedRow}`].firstName}
                  onChange={formik.handleChange}
                  fullWidth
                  oldValue={oldData[`${selectedRow}`] ? oldData[`${selectedRow}`].firstName : ''}
                  disabled={edit}
                  error={Boolean(
                    formik.errors.experts ? formik.errors.experts[`${selectedRow}`]?.firstName : ''
                  )}
                  helperText={
                    formik.errors.experts ? formik.errors.experts[`${selectedRow}`]?.firstName : ''
                  }></InputField>
              </div>
            </>
          )}
          {getTenantId() === TENANT_ID.BAG && !edit ? (
            <div className="popup-content__popup-field">
              <InputField
                label="Geburtsdatum*"
                name={`experts[${selectedRow}].birthdate`}
                key={`experts[${selectedRow}].birthdate`}
                id={`experts[${selectedRow}].birthdate`}
                value={formik.values.experts[`${selectedRow}`].birthdate}
                fullWidth
                oldValue={
                  oldData[`${selectedRow}`] ? oldData[`${selectedRow}`].birthdate ?? '' : ''
                }
                onChange={formik.handleChange}
                error={Boolean(
                  formik.errors.experts ? formik.errors.experts[`${selectedRow}`]?.birthdate : ''
                )}
                helperText={
                  formik.errors.experts ? formik.errors.experts[`${selectedRow}`]?.birthdate : ''
                }></InputField>
            </div>
          ) : null}
          {isGuest && (
            <div className="popup-content__popup-field">
              <InputField
                label={`Strom-Ausweis gültig bis*`}
                name={`experts[${selectedRow}].expertEnergyValidityDate`}
                key={`experts[${selectedRow}].expertEnergyValidityDate`}
                id={`experts[${selectedRow}].expertEnergyValidityDate`}
                value={formik.values.experts[`${selectedRow}`].expertEnergyValidityDate}
                fullWidth
                onChange={formik.handleChange}
                oldValue={
                  oldData[`${selectedRow}`]
                    ? oldData[`${selectedRow}`].expertEnergyValidityDate ?? ''
                    : ''
                }
                error={Boolean(
                  formik.errors.experts
                    ? formik.errors.experts[`${selectedRow}`]?.expertEnergyValidityDate
                    : ''
                )}
                helperText={
                  formik.errors.experts
                    ? formik.errors.experts[`${selectedRow}`]?.expertEnergyValidityDate
                    : ''
                }></InputField>
            </div>
          )}
        </div>
      );
    else {
      return <>Error</>;
    }
  };

  return (
    <CustomModal
      isOpen={popupOpen}
      title={edit ? 'Verlängerung' : 'Fachkraft hinzufügen'}
      ctaButtons={
        <>
          <ActionButton onClick={onSubmit} title="Ok"></ActionButton>
          <ActionButton inverted onClick={onCancel} title="Abbrechen"></ActionButton>
        </>
      }
      content={modalContent()}></CustomModal>
  );
}
ExpertPopupEnergy.propTypes = {
  data: PropTypes.array,
  oldData: PropTypes.array,
  formik: PropTypes.object,
  selectedRow: PropTypes.number,
  popupOpen: PropTypes.bool,
  setPopupOpen: PropTypes.func,
  edit: PropTypes.bool,
  isGuest: PropTypes.bool,
};
