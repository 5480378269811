import * as Yup from 'yup';

import { getTenantId } from 'utils/tenantUtil';
import { YFutureDate, YPastDate } from 'utils/YupUtils';

import { EIV_CHAR_LENGTH, VALIDATION_CONSTANTS, EIV_ENTRY_TYPE, TENANT_ID } from 'global/constants';

export const YNEExpertValidation = (branchSelectionValues) =>
  Yup.object()
    .shape({
      lastName: Yup.string()
        .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
        .max(EIV_CHAR_LENGTH.EXPERT_NAME, VALIDATION_CONSTANTS.TOO_LONG)
        .required(VALIDATION_CONSTANTS.MANDATORY),
      birthdate: Yup.string().when([], {
        is: () => {
          return getTenantId() === TENANT_ID.BAG;
        },
        then: YPastDate.required(VALIDATION_CONSTANTS.MANDATORY),
      }),
      firstName: Yup.string()
        .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
        .max(EIV_CHAR_LENGTH.EXPERT_NAME, VALIDATION_CONSTANTS.TOO_LONG)
        .required(VALIDATION_CONSTANTS.MANDATORY),
      isExpertEnergyActive: Yup.boolean(),
      isExpertGasActive: Yup.boolean(),
      energyValidityDate: Yup.string().when(['isExpertEnergyActive'], {
        is: (isExpertEnergyActive) => {
          return (
            branchSelectionValues.branchTypeEnergy === EIV_ENTRY_TYPE.GUEST && isExpertEnergyActive
          );
        },
        then: YFutureDate.required(VALIDATION_CONSTANTS.MANDATORY),
      }),
      gasValidityDate: Yup.string().when(['isExpertGasActive'], {
        is: (isExpertGasActive) => {
          return branchSelectionValues.branchTypeGas === EIV_ENTRY_TYPE.GUEST && isExpertGasActive;
        },
        then: YFutureDate.required(VALIDATION_CONSTANTS.MANDATORY),
      }),
      isExpertOwner: Yup.boolean().when(['isExpertEnergyActive', 'isExpertGasActive'], {
        is: (isExpertEnergyActive, isExpertGasActive) => {
          return (
            (isExpertEnergyActive === true &&
              branchSelectionValues.branchTypeEnergy === EIV_ENTRY_TYPE.MAIN) ||
            (isExpertGasActive === true &&
              branchSelectionValues.branchTypeGas === EIV_ENTRY_TYPE.MAIN)
          );
        },
        then: Yup.boolean().required(VALIDATION_CONSTANTS.MANDATORY),
      }),
    })
    .test('checkAtLeastOneBranchSelected', null, (obj) => {
      if (obj.isExpertEnergyActive || obj.isExpertGasActive) {
        return true; // everything is fine
      }
      return new Yup.ValidationError(
        'Wählen Sie mindestens eine Sparte aus.',
        null,
        'noBranchSelected'
      );
    });
