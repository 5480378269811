import React from 'react';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';
import PropTypes from 'prop-types';
import './InputField.sass';
export function InputField(props) {
  const { oldValue, infoText, ...filteredProps } = props;
  return (
    <div className="input-field">
      <ThemedTextField
        className={
          props.error
            ? 'input-field__error'
            : oldValue && oldValue != props.value
            ? 'input-field__edited'
            : 'input-field__normal'
        }
        {...filteredProps}></ThemedTextField>
      {infoText && (
        <div
          className={`input-field__info-text ${
            props.disabled ? 'input-field__info-text__disabled' : ''
          }`}>
          {infoText}
        </div>
      )}
    </div>
  );
}

InputField.propTypes = {
  oldValue: PropTypes.string,
  value: PropTypes.string,
  infoText: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
};
