import { isDevelopment, isLocal } from 'utils/StageUtils';
import { EIV_ENDPOINT } from '../APIUtils';
import { getAxiosInstance } from '../InitAxios';

// const EDIS_INSTALLER =
//   'eyJraWQiOiIyNDQiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdF9oYXNoIjoiODNtVFlnU0QtNmJYenZuQXFzTWN2USIsInN1YiI6Imh0dHBzOi8vdGVzdC5zYWxlc2ZvcmNlLmNvbS9pZC8wMEQyNjAwMDAwMERaOTBFQUcvMDA1MjYwMDAwMDgxcVphQUFJIiwiYXVkIjoiM01WRzlvZDZ2Tm9sLmVCaEVwQ1lER2RudTNKbjBnbGl3LnBLM2pkZXRWT3p1aVIyb0NnSnppelY4cnFlTk1ObjRHcjEyb1pQejllWEROTmxMenliNiIsImlzcyI6Imh0dHBzOi8vbG9naW4tcWEuZS1maXguaW5mbyIsImV4cCI6MTY4NDg1MTE5OCwiaWF0IjoxNjg0ODQ3NTk4LCJjdXN0b21fYXR0cmlidXRlcyI6eyJFR0NJRCI6IlNJVFgwMDAwMDc2MjQ1IiwiTGFzdE5hbWUiOiJUZXN0IEFjY291bnQiLCJFbWFpbCI6ImVmaXguZW9uQGdtYWlsLmNvbSIsIkZpcnN0TmFtZSI6ImUtZml4In19.moVHCM-CZ4QnADj5iUkMbb-7xUwxFxQAdGK0hL4ZFpOQ0e74czkPm-l0mOtd5Op5-rft53-JgCBDtYAjs8WJgGQZSJP4Y1espEVaGHfzxCXc9c4aeLvM-A-tRMckn6oFGyrS8X4Slt-Y_NI03ls5yhczpBrB-_4tHtpzDREsGDtPafkkmapnEsgwZdo7U8qNB52-VI67eB47ZszsSr2a8MIGcratCqBP-LGkorJiTUuf_RqtIfeCquAS6GfZLLySVVewsU4ZCCpOolteYu_RXxRnlsWYzth7SiUlDBnwLLlaog6VwYLAH_fZrUPG87xRrEG9K26FTsflj7IF3g6NAz29ldyFucVix_RmXSB9AXGU4sYfeZrDTDZ0ZJKMWFXIJ5fTrQ1u0LbBW2RXlclsiHvDcFhxgdyhc97-k-UixUzsmaBNipI_Tbic0gznlFVBDPpP5cnP5VEsxHA9cFzO8R-Dob_ezDNc-o0wNaGdVI2skcg8zH1Yv9TzKd96vgwMjRY4xSES-pf-nkjw2s1-LFzuQV5ncH7NsLEq-0RpbI5Rz_qObaUZdRnIN407KkJsf-qO2UpDvb9weVjJVOvYQClHpnYdW75uzlEP2SVBdUnhLANA4gPc_dqNFlNfCUl62TzwTP5FjYuexmSu_WDK_AGOZ0eYSTj_k7861pV9Ow8';
// const AVA_INSTALLER =
//   'eyJraWQiOiIyNDQiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdF9oYXNoIjoiZk1faXhmVVRELWdoekFwUXllQmZfUSIsInN1YiI6Imh0dHBzOi8vdGVzdC5zYWxlc2ZvcmNlLmNvbS9pZC8wMEQyNjAwMDAwMERaOTBFQUcvMDA1MjYwMDAwMDgxcWFFQUFRIiwiYXVkIjoiM01WRzlvZDZ2Tm9sLmVCaEVwQ1lER2RudTNKbjBnbGl3LnBLM2pkZXRWT3p1aVIyb0NnSnppelY4cnFlTk1ObjRHcjEyb1pQejllWEROTmxMenliNiIsImlzcyI6Imh0dHBzOi8vbG9naW4tcWEuZS1maXguaW5mbyIsImV4cCI6MTY4ODY0NjkyOSwiaWF0IjoxNjg4NjQzMzI5LCJjdXN0b21fYXR0cmlidXRlcyI6eyJFR0NJRCI6IlNJVFgwMDAwMDc2Mjg1IiwiTGFzdE5hbWUiOiJUZXN0aW5zdGFsbGF0ZXVyIiwiRW1haWwiOiJpbnN0YWxsYXRldXJAYWJ3ZXNlbmQuZGUiLCJGaXJzdE5hbWUiOiJUaGVvZG9yIn19.fQQ_cgr6MHPhVyd1XhuXuaf8nA-QWpST3eL17npvKrFlxFLnZOL9jW6So3njy_F1dZWBh38yuxPjxF6ZbHivPY8_Kx5WrXtYLvdb2J0oRhe5tnd54uYBnVkzweccFDMzFbXD810UGeOarnAHtxC0pOUm1op6ejISXBPIPA2FgG0BIxtVIMo0vTPmwCbWX2dRghwqsYB-1XJo7hKkMdCmn7KGNi1tABMAIvUQ6qbsvKFoey-qoJ4HPqjQlvEqwiBAmU32dybDEEdPBnIqXFwYg32PhKMnpXfOgZF2sHkxo5Z9GX5eB1_wZPICByEi17JTcDGn5HFOQ0BKDIo5MvKMu9cIDqEDtl32rADN9BibN2hPXxG244gW_wGw5LrmDDnaQ-MBq6GZcNRxmx6sm7C5Q3erN2Bu_vptFwy2cAo7szatko_oXuNAcpR9XNR62a0XEK284PFBzGpRwwNQ87sO7qRVRfieTCrb7MvbucAx6HtUh9DBBNQuZNH5jJIvFdCYaU9gkIK_NGbXMY_4Tw12syoE52oE3Lq-Pp_ZE3okydfhsmZZ_oGVg2Jl3oVB98HKaUeEPjUQt_8tEBslyzyl4PW7rI5eKkIKw1HPMxdLiOB-UXGiPAdwbmhrwRgGODz_SlERGffFbdEm2MpqFwG-fExSUqStMXAZwczlKGM3yck';
// const SHNG_INSTALLER =
//   'eyJraWQiOiIyNDQiLCJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdF9oYXNoIjoib2kyUmxQY215VF9WTE5Hd18tRklUZyIsInN1YiI6Imh0dHBzOi8vdGVzdC5zYWxlc2ZvcmNlLmNvbS9pZC8wMEQwRTAwMDAwMEhXRVZVQTQvMDA1MEUwMDAwMEVaUkpNUUE1IiwiYXVkIjoiM01WRzlzU05fUE1uOHRqU29keUQyOGpTSnB5WEFQS2R0X1lkZ0JnVTFBN1BqS1cySUxiUV93cURyak5fcVh0M2wzOWNNV3NEeDQzM1l2ZE5ENzFXeSIsImlzcyI6Imh0dHBzOi8vbG9naW4tZGV2LmUtZml4LmluZm8iLCJleHAiOjE2ODk3NTY2MjQsImlhdCI6MTY4OTc1MzAyNCwiY3VzdG9tX2F0dHJpYnV0ZXMiOnsiRUdDSUQiOiJTSVRDMDAyMTI5Mjg5MiIsIkxhc3ROYW1lIjoiU3Ryb20tYWt0aXYiLCJFbWFpbCI6ImVmaXhzaG5nQGdtYWlsLmNvbSIsIkZpcnN0TmFtZSI6Ikdhcy1ha3RpdiJ9fQ.HE0x_DoD-G2ml8EvpZlF-gUNoSoJXILmWPzo4D22oK_bekT50cQMrYkoFd3BIvHGAU9GTzyBFQKFYDgZu_YJZQ7nDj6-TwjR4QUI07O3NEGwInmytUd_725H25SkWZgu1gKvnVEDZpWvM99iRJSaLdWzxbiPqyayDFszlCEmA8X05ujIQvRHLgN0Xbig9Y0OodfiQHL7_UHyoZxOFdeOmuuNjC1Qpta4yreA44uro3hGbYnPxyLUmntxQelWNuXC3RHhQtIQCsFFrQHzEYMxUOdJ40lmy4Qv4e8BAiqN0pigGWeZG7CR3txVM5sbBUW6068x3S_zzboljzEklnmvLiUUo-FGvXFtLU_rnG9B3PGpu3iU285rVMfxLsBcZOqktZYklfTVGYDa-toYZqwCuct6vOs1QzN6o9kvwD5CuyDdqpUBbWv03B6eqTSKAVRSbcf-RC8kGIX1gf9dM2PcsDkDxJzm_TNBpphDWItajiihsAD1-lzABKflpXoK14p_xwdL1G0zCQL5A1ZSitwMiLKShHfKtzRpmySSKyQTc-v_6zaGLK2nlYLRKbsn4VArqvoLqbuxESJNgqY3WGhnSK4XUvgkR4MrChMNJZsUhNvMmaO9RaqZ-qZX_fW5zqMOurvCfWO_5RypCFp-Nj2XiZNpfAGz-BxYdxb57d2YZVs';

export const getInstallerInfo = async (ciamToken, setShowError) => {
  let usedCiamToken = ciamToken;
  if (isLocal() || isDevelopment()) {
    // usedCiamToken = SHNG_INSTALLER;
  }

  const axiosInstance = getAxiosInstance(usedCiamToken);

  return axiosInstance
    .get(EIV_ENDPOINT.INSTALLER_INFO())
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      setShowError(true);
    });
};
