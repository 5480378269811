import React from 'react';

export const sortMetaNav = (aemConfig) => {
  const result = aemConfig.map((staticItem) => {
    const { url: aemUrl, linkTitle: aemTitle } = staticItem;
    staticItem.title = aemTitle;
    staticItem.linkedPage = aemUrl;

    if (staticItem?.linkDescription) {
      staticItem.teaser = {
        elementsList: [
          {
            name: staticItem?.linkDescription,
          },
        ],
      };
    }

    if (staticItem?.linkIcon) {
      staticItem.icon = staticItem?.linkIcon?.endsWith('.svg') ? (
        <div>
          <img
            alt="Nav Icon"
            style={{ width: 80, height: 80 }}
            className="custom-svg-logo"
            src={staticItem?.linkIcon}
          />
        </div>
      ) : (
        <div>
          <img alt="Nav Icon" style={{ width: 80, height: 80 }} src={staticItem?.linkIcon} />
        </div>
      );
    }

    return staticItem;
  });

  return result;
};

export const parseCardsMyData = (aemConfig) => {
  const result = aemConfig
    .filter((card) => card.enableCard === true)
    .map((currentCard) => {
      const cardData = {
        id: currentCard.id,
        linkedPage: currentCard.url,
        title: currentCard.linkTitle,
        icon: currentCard?.linkIcon?.endsWith('.svg') ? (
          <div>
            <img
              alt="Nav Icon"
              style={{ width: 80, height: 80 }}
              className="custom-svg-logo"
              src={currentCard?.linkIcon}
            />
          </div>
        ) : (
          <div>
            <img alt="Nav Icon" style={{ width: 80, height: 80 }} src={currentCard?.linkIcon} />
          </div>
        ),
        teaser: {
          elementsList: [
            {
              name: currentCard?.linkDescription,
            },
          ],
        },
      };

      return cardData;
    });

  return result;
};
