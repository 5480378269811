import React from 'react';
import './EventRegisterFormFieldWrapper.sass';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const EventRegisterFormFieldWrapper = (props) => {
  const classes = classnames('event-register-form-field-wrapper', {
    [`${props.className}`]: !!props.className,
  });
  return <div className={classes}>{props.children}</div>;
};

EventRegisterFormFieldWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export { EventRegisterFormFieldWrapper };
