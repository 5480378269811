import React, { useEffect } from 'react';
import { MapTo } from '@adobe/aem-react-editable-components';
import BusinessUnitSelection from '../ConnectionService/BusinessUnitSelection/BusinessUnitSelection';
import RegionDetails from '../ConnectionService/RegionDetails/RegionDetails';
import './ShngConnectionService.sass';

const DATA = {
  businessUnitSelection: {
    title: 'Anschluss-Service',
    subTitle: 'Bitte wählen Sie das Gebiet aus, in dem Sie arbeiten möchten:',
    options: {
      1: {
        subTitle: 'Schleswig-Holstein Netz',
        subTitleDescription:
          'Sie erreichen uns in den Regionen zu allen Fragen rund um den Status des Antrags und der Angebote sowie der Zählerbestellung unter folgenden E-Mail-Adressen bzw. Telefonnummern:',
        imageName: 'connection_service_business_unit_1.png',
        mapImageName: 'connection_service_region_1.png',
        contactDetails: {
          data: [
            {
              type: 'value',
              data: {
                value: 'Hotline',
              },
            },
            {
              type: 'tel',
              data: {
                value: '+49 4106 648-9090',
              },
            },
            {
              type: 'email',
              data: {
                value: 'meinanschluss@sh-netz.com',
              },
            },
            {
              type: 'value-thin',
              data: {
                value: `Unsere Experten der Kundenbetreuung sind persönlich für Sie da:`,
              },
            },
            {
              type: 'value-no-margin',
              data: {
                value: `  Montag und Dienstag 13:00 bis 15:00 Uhr`,
              },
            },

            {
              type: 'value',
              data: {
                value: `Mittwoch bis Freitag 07:30 bis 10:00 Uhr`,
              },
            },
            {
              type: 'value',
              data: {
                value: ' ',
              },
            },
            {
              type: 'value',
              data: {
                value:
                  'Region Nord-West (NW): für die Netzcenter Dägeling, Friedrichstadt, Meldorf, Niebüll',
              },
            },
            {
              type: 'tel-email',
              data: {
                firstValue: '+49 4106 648-9015',
                secondValue: 'netzkunden-west@sh-netz.com',
              },
            },
            {
              type: 'value',
              data: {
                value:
                  'Region Süd-West (SW): für die Netzcenter Bad Segeberg, Kaltenkirchen, Uetersen',
              },
            },
            {
              type: 'tel-email',
              data: {
                firstValue: '+49 4106 648-9017',
                secondValue: 'netzkunden-west@sh-netz.com',
              },
            },
            {
              type: 'value',
              data: {
                value: 'Region Nord-Ost (NO): für die Netzcenter Fockbek, Schuby, Süderbrarup',
              },
            },
            {
              type: 'tel-email',
              data: {
                firstValue: '+49 4106 648-9016',
                secondValue: 'netzkunden-ost@sh-netz.com',
              },
            },
            {
              type: 'value',
              data: {
                value:
                  'Region Süd-Ost (SO): für die Netzcenter Ahrensburg, Plön, Pönitz, Schwarzenbek',
              },
            },
            {
              type: 'tel-email',
              data: {
                firstValue: '+49 4106 648-9018',
                secondValue: 'netzkunden-ost@sh-netz.com',
              },
            },
            {
              type: 'value',
              data: {
                value: ' Stadtwerke Neumünster',
              },
            },
            {
              type: 'tel-email',
              data: {
                firstValue: '+49 4321 202-2569',
                secondValue: 'hausanschluss@swn.net',
              },
            },
          ],
        },
      },
      2: {
        subTitle: 'ElbEnergie',
        subTitleDescription:
          'Sie erreichen uns in der Regionen zu allen Fragen rund um den Status des Antrags und der Angebote sowie der Zählerbestellung unter folgender E-Mail-Adresse bzw. Telefonnummer:',
        imageName: 'connection_service_business_unit_2.png',
        mapImageName: 'connection_service_region_2.png',
        contactDetails: {
          data: [
            {
              type: 'value',
              data: {
                value: 'ElbEnergie: Hittfeld-Seevetal',
              },
            },
            {
              type: 'tel-email',
              data: {
                firstValue: '+49 4105 157-9900',
                secondValue: 'meinanschluss@elbenergie.com',
              },
            },
          ],
        },
      },
      3: {
        subTitle: 'HanseGas',
        subTitleDescription:
          'Sie erreichen uns in den Regionen zu allen Fragen rund um den Status des Antrags und der Angebote sowie der Zählerbestellung unter folgender E-Mail-Adresse bzw. Telefonnummern:',
        imageName: 'connection_service_business_unit_3.png',
        mapImageName: 'connection_service_region_3.png',
        contactDetails: {
          data: [
            {
              type: 'value',
              data: {
                value: 'HanseGas',
              },
            },
            {
              type: 'email',
              data: {
                value: 'Netzanschluss@hansegas.com',
              },
            },
            {
              type: 'value',
              data: {
                value: 'Netzgebiet Löbnitz/ teilweise Wustrow',
              },
            },
            {
              type: 'tel',
              data: {
                value: '+49 38324 643 4750',
                name: 'Daniel Funke',
              },
            },
            {
              type: 'value',
              data: {
                value: 'Netzgebiet Roggentin/ teilweise Wustrow',
              },
            },
            {
              type: 'tel',
              data: {
                value: '+49 38204 687 4712',
                name: 'Anke Lewing',
              },
            },
            {
              type: 'value',
              data: {
                value: 'Netzgebiet Gägelow und Sternberg',
              },
            },
            {
              type: 'tel',
              data: {
                value: '+49 3841 626 14424',
                name: 'Ulf Knolle',
              },
            },
            {
              type: 'value',
              data: {
                value: 'Netzgebiet Bützow',
              },
            },
            {
              type: 'tel',
              data: {
                value: '+49 38461 512 123',
                name: 'Michael Jannott',
              },
            },
            {
              type: 'value',
              data: {
                value: 'Netzgebiet Spornitz/ Lenzen/ teilweise Wittenburg',
              },
            },
            {
              type: 'tel',
              data: {
                value: '+49 3872 6839 4822',
                name: 'Rüdiger Wilke',
              },
            },
            {
              type: 'value',
              data: {
                value: 'Netzgebiet Schwerin Warnitz/ teilweise Wittenburg',
              },
            },
            {
              type: 'tel',
              data: {
                value: '+49 385 5750 4298',
                name: 'Thomas Kühn',
              },
            },
            {
              type: 'value',
              data: {
                value: 'GVPN, Gasversorgung Vorpommern Netz',
              },
            },
            {
              type: 'tel',
              data: {
                value: '+49 38371 23613',
                name: 'Matthias Siedler',
              },
            },
            {
              type: 'value',
              data: {
                value: 'GWL, Gasversorgung Wismar Land',
              },
            },
            {
              type: 'tel',
              data: {
                value: '+49 3841 626 14420',
                name: 'Jens Heidtmann',
              },
            },
          ],
        },
      },
      4: {
        subTitle: 'NordNetz',
        subTitleDescription:
          'Sie erreichen uns in der Region zu allen Fragen rund um den Status des Antrags und der Angebote sowie der Zählerbestellung unter der folgenden E-Mail-Adresse bzw. Telefonnummer:',
        imageName: 'connection_service_business_unit_4.png',
        mapImageName: '',
        contactDetails: {
          data: [
            {
              type: 'value',
              data: {
                value: 'NordNetz: Ammersbek, Großhansdorf, Heiligenhafen, Meldorf, Rellingen',
              },
            },
            {
              type: 'tel',
              data: {
                value: '+49 4106 999 8033',
              },
            },
            {
              type: 'email',
              data: {
                value: 'meinanschluss@nordnetz.com',
              },
            },
            {
              type: 'email',
              data: {
                value: 'einspeisen@nordnetz.com',
              },
            },
          ],
        },
      },
    },
  },
};

const ShngConnectionService = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const businessSelectionData = DATA.businessUnitSelection;
  const options = businessSelectionData.options;

  const businessSelectionOptions =
    Object.keys(options || {}).map((key) => ({
      key: `businessSelectionOption_${key}`,
      alt: options[key]?.subTitle,
      imageName: options[key]?.imageName,
    })) || [];

  const regionsDataOptions = Object.keys(options || []).map((key) => ({
    key: `regionDataOption_${key}`,
    option: options[key],
  }));

  const scrollToRegion = (region) => {
    const element = document.getElementById(
      `regionDataOption_${region?.replace('businessSelectionOption_', '')}`
    );
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className=" expert-page">
      <div className="expert-page-content-stage-text">
        <BusinessUnitSelection
          options={businessSelectionOptions}
          onOptionSelected={(value) => scrollToRegion(value)}
        />
        {businessSelectionData.secondSubTitle && (
          <div className="shng-expert-sub-text">{businessSelectionData.secondSubTitle}</div>
        )}

        <div className="regionDetails">
          <RegionDetails regionsDataOptions={regionsDataOptions} />
        </div>
      </div>
    </div>
  );
};

MapTo(['e-fix/components/content/shngconnectionservice'])(ShngConnectionService);
