/* eslint-disable react/prop-types */
import React from 'react';
import './css/Subnav.sass';
import SubnavButton from './SubnavButton';

const Subnav = ({ navItems }) => {
  const showUserCentrics = () => window.UC_UI?.showSecondLayer();

  return (
    <div className="subnav">
      <div className="footer-content">
        {navItems.map((navItem) => (
          <SubnavButton key={navItem.url} name={navItem.linkTitle} link={navItem.url} />
        ))}
        <SubnavButton
          name={'Cookie Disclaimer'}
          isCookieDisclaimerBtn={true}
          clickCookieDisclaimer={showUserCentrics}
        />
      </div>
    </div>
  );
};
export default Subnav;
