import React from 'react';
import PropTypes from 'prop-types';
import { CustomModal } from 'components/CustomModal';
import { ActionButton } from 'components/ActionButton/ActionButton';
import './SubmitPopup.sass';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
export function SubmitPopup({
  response,
  popupOpen,
  setPopupOpen,
  redirectLinkOnSuccessOrCancel,
  redirectFullOnSuccessOrCancel,
  onErrorRetry,
  loading,
  type,
  redirectCancelButtonText,
}) {
  const errorContent = (
    <div className="error-popup">
      <div>
        Ihr Antrag konnte nicht gesendet werden. Bitte versuchen Sie es erneut. Falls der Fehler
        weiterhin auftritt, versuchen Sie es bitte später erneut.
      </div>
    </div>
  );
  const successContent = () => {
    let content = '';
    if (type === 'DELETE_COMPANY') {
      content = (
        <div className="success-popup">
          <div>Sie haben die Löschung Ihrer Eintragung beantragt.</div>
          <div>Die Löschung wird zeitnah im Installateurverzeichnis durchgeführt.</div>
        </div>
      );
    } else if (type === 'EDIT_COMPANY') {
      content = (
        <div className="success-popup">
          <div>Wir werden Ihren Antrag prüfen und Ihre Änderungen vornehmen.</div>
          <div>
            Falls wir Rückfragen haben, werden wir Sie in der Regel per E-Mail kontaktieren und bei
            Bedarf Dokumente nachfordern.
          </div>
          <div>Die Bearbeitung wird einige Zeit in Anspruch nehmen.</div>
        </div>
      );
    } else if (type === 'NEW_ENTRY') {
      content = (
        <div className="success-popup">
          <div>Wir werden Ihren Antrag prüfen.</div>
          <div>
            Falls wir Rückfragen haben, werden wir Sie in der Regel per E-Mail kontaktieren und bei
            Bedarf Dokumente nachfordern.
          </div>
          <div>Die Bearbeitung wird einige Zeit in Anspruch nehmen.</div>
        </div>
      );
    }

    return content;
  };
  if (response) {
    return (
      <CustomModal
        isOpen={popupOpen}
        title={
          loading
            ? ''
            : response === 'SUCCESS'
            ? 'Antrag erfolgreich gesendet!'
            : 'Antrag senden fehlgeschlagen!'
        }
        ctaButtons={
          <>
            {response === 'SUCCESS' ? (
              <>
                <ActionButton
                  link={redirectLinkOnSuccessOrCancel}
                  onClick={() => {
                    if (redirectFullOnSuccessOrCancel) {
                      window.location.href = redirectFullOnSuccessOrCancel;
                    }
                    setPopupOpen(false);
                  }}
                  title="Ok"></ActionButton>
              </>
            ) : (
              <>
                <ActionButton
                  link={redirectLinkOnSuccessOrCancel}
                  onClick={() => {
                    if (redirectFullOnSuccessOrCancel) {
                      window.location.href = redirectFullOnSuccessOrCancel;
                    }
                    setPopupOpen(false);
                  }}
                  inverted
                  title={redirectCancelButtonText}></ActionButton>
                <ActionButton
                  onClick={() => {
                    onErrorRetry();
                  }}
                  title="Erneut senden"></ActionButton>
              </>
            )}
          </>
        }
        content={
          loading ? (
            <LoadingIndicator></LoadingIndicator>
          ) : response === 'SUCCESS' ? (
            successContent()
          ) : (
            errorContent
          )
        }></CustomModal>
    );
  } else {
    return <></>;
  }
}
SubmitPopup.propTypes = {
  response: PropTypes.string,
  popupOpen: PropTypes.bool,
  setPopupOpen: PropTypes.func,
  onErrorRetry: PropTypes.func,
  redirectLinkOnSuccessOrCancel: PropTypes.string,
  redirectFullOnSuccessOrCancel: PropTypes.string,
  redirectCancelButtonText: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.string,
};

SubmitPopup.defaultProps = {
  type: 'EDIT_COMPANY',
  redirectCancelButtonText: 'Abbrechen',
  loading: false,
};
