import React from 'react';
import './ThemedTextField.sass';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TextField } from '@material-ui/core';

const ThemedTextField = (props) => {
  const { shrink, className: propsClassName, ...rest } = props;
  const classes = classnames('themed-text-field', {
    [`${propsClassName}`]: !!propsClassName,
  });
  return <TextField InputLabelProps={{ shrink: shrink }} className={classes} {...rest} />;
};

ThemedTextField.propTypes = {
  className: PropTypes.string,
  shrink: PropTypes.bool,
};

export { ThemedTextField };
