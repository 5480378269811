const GasGuest = {
  isEnergyActive: false,
  isGasActive: true,
  companyTypeEnergy: '',
  companyTypeGas: 'Hauptbetrieb',
  branchTypeEnergy: '',
  branchTypeGas: 2,
};

const EnergyGuest = {
  isEnergyActive: true,
  isGasActive: false,
  companyTypeEnergy: 'Hauptbetrieb',
  companyTypeGas: '',
  branchTypeEnergy: 2,
  branchTypeGas: '',
};

const EnergyMainGasGuest = {
  isEnergyActive: true,
  isGasActive: true,
  companyTypeEnergy: 'Hauptbetrieb',
  companyTypeGas: 'Hauptbetrieb',
  branchTypeEnergy: 1,
  branchTypeGas: 2,
};
const EnergyGuestGasGuest = {
  isEnergyActive: true,
  isGasActive: true,
  companyTypeEnergy: 'Hauptbetrieb',
  companyTypeGas: 'Hauptbetrieb',
  branchTypeEnergy: 2,
  branchTypeGas: 2,
};
const EnergyGuestGasMain = {
  isEnergyActive: true,
  isGasActive: true,
  companyTypeEnergy: 'Hauptbetrieb',
  companyTypeGas: 'Hauptbetrieb',
  branchTypeEnergy: 2,
  branchTypeGas: 1,
};

const EnergyMain = {
  isEnergyActive: true,
  isGasActive: false,
  companyTypeEnergy: 'Hauptbetrieb',
  companyTypeGas: '',
  branchTypeEnergy: 1,
  branchTypeGas: '',
};

const EnergyMainHilfsbetrieb = {
  isEnergyActive: true,
  isGasActive: false,
  companyTypeEnergy: 'Hilfsbetrieb',
  companyTypeGas: '',
  branchTypeEnergy: 1,
  branchTypeGas: '',
};

const GasMain = {
  isEnergyActive: false,
  isGasActive: true,
  companyTypeEnergy: '',
  companyTypeGas: 'Hauptbetrieb',
  branchTypeEnergy: '',
  branchTypeGas: 1,
};
const GasMainHilfsbetrieb = {
  isEnergyActive: false,
  isGasActive: true,
  companyTypeEnergy: '',
  companyTypeGas: 'Hilfsbetrieb',
  branchTypeEnergy: '',
  branchTypeGas: 1,
};

const EnergyMainHilfsbetriebGasGuestHauptbetrieb = {
  isEnergyActive: true,
  isGasActive: true,
  companyTypeEnergy: 'Hilfsbetrieb',
  companyTypeGas: 'Hauptbetrieb',
  branchTypeEnergy: 1,
  branchTypeGas: 2,
};

const EnergyMainHauptbetriebGasGuestHilfsbetrieb = {
  isEnergyActive: true,
  isGasActive: true,
  companyTypeEnergy: 'Hauptbetrieb',
  companyTypeGas: 'Hilfsbetrieb',
  branchTypeEnergy: 1,
  branchTypeGas: 2,
};

const NE_BS = {
  GasGuest,
  EnergyGuest,
  EnergyMainGasGuest,
  EnergyMain,
  EnergyMainHilfsbetrieb,
  GasMain,
  EnergyGuestGasGuest,
  GasMainHilfsbetrieb,
  EnergyGuestGasMain,
  EnergyMainHilfsbetriebGasGuestHauptbetrieb,
  EnergyMainHauptbetriebGasGuestHilfsbetrieb,
};

export { NE_BS };
