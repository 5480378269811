import React, { useEffect } from 'react';
import { MapTo } from '@adobe/aem-react-editable-components';
import './ItHilfeComponent.scss';
import { SubTitle } from '../../../SubTitle/SubTitle';

const DATA = {
  SHNG: {
    title: 'Schleswig-Holstein Netz, ElbEnergie und NordNetz',
    number: '+4941067069922',
    numberFormatted: 'Tel. +49 4106 706 99 22',
  },
  HG: {
    title: 'HanseGas',
    number: '+4938534343434',
    numberFormatted: 'Tel. +49 385 34 34 34 34',
  },
};

const ItHilfeComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className=" expert-page it-help">
      <div className="expert-page-content-stage-text">
        <div className="it-hilfe-number-container">
          <SubTitle text={DATA.SHNG.title} notFramed />
          <div>
            <a href={'tel:' + DATA.SHNG.number}>{DATA.SHNG.numberFormatted}</a>
          </div>
        </div>
        <div className="it-hilfe-number-container">
          <SubTitle text={DATA.HG.title} notFramed />
          <div>
            <a href={'tel:' + DATA.HG.number}>{DATA.HG.numberFormatted}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

MapTo('e-fix/components/content/shngithilfe')(ItHilfeComponent);
