import * as React from 'react';
export const DownloadIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={21} height={21} fill="none" {...props}>
    <path
      fill="rgba(0,0,0,0)"
      className="download-icon"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M18.375 13.125v3.5a1.75 1.75 0 0 1-1.75 1.75H4.375a1.75 1.75 0 0 1-1.75-1.75v-3.5M6.125 8.75l4.375 4.375 4.375-4.375M10.5 13.125v-10.5"
    />
  </svg>
);
