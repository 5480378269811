import PropTypes from 'prop-types';
import React from 'react';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { CustomModal } from 'components/CustomModal';
import './TenantSwitchModal.sass';
import DividingLine from 'components/DividingLine/DividingLine';
import { TenantSwitchSelectionButton } from './TenantSwitchSelectionButton';
import { getTenantId } from 'utils/tenantUtil';
import { getStage } from 'utils/StageUtils';

import { getHomepageUrlByTenantId } from 'utils/UrlUtil';
import { STORAGE_KEY, TENANT_ID, STAGE } from 'global/constants';

const TenantSwitchModal = ({ isOpen, onClose, links }) => {
  const currentTenantId = getTenantId();
  const stage = getStage();

  const prepareLink = (link) => {
    if (link.startsWith('https://') || link.startsWith('http://')) {
      return link;
    } else {
      return 'https://' + link;
    }
  };
  const onTenantSwitchSimple = async (tenantId) => {
    if (tenantId === currentTenantId) {
      return;
    } else {
      const targetUrl = getHomepageUrlByTenantId(tenantId);

      if (stage === STAGE.LOCAL) {
        localStorage.setItem(STORAGE_KEY.TENANT_ID, tenantId);
      }

      window.location.replace(targetUrl);
    }
  };

  const switchContent = () => {
    return (
      <div className="tenant-switch-modal__container">
        <div className="tenant-switch-modal__sub-text">
          Klicken Sie auf das Logo für den Wechsel zum e-Fix des Netzbetreibers:
        </div>
        <div className="tenant-switch-modal__tenant-button-container">
          <TenantSwitchSelectionButton
            tenant={TENANT_ID.EDI}
            onClick={() => onTenantSwitchSimple(TENANT_ID.EDI)}
            active={currentTenantId === TENANT_ID.EDI}
          />
          <TenantSwitchSelectionButton
            tenant={TENANT_ID.SHNG}
            onClick={() => onTenantSwitchSimple(TENANT_ID.SHNG)}
            active={currentTenantId === TENANT_ID.SHNG}
          />
          <TenantSwitchSelectionButton
            tenant={TENANT_ID.AVA}
            onClick={() => onTenantSwitchSimple(TENANT_ID.AVA)}
            active={currentTenantId === TENANT_ID.AVA}
          />
          <TenantSwitchSelectionButton
            tenant={TENANT_ID.BAG}
            onClick={() => onTenantSwitchSimple(TENANT_ID.BAG)}
            active={currentTenantId === TENANT_ID.BAG}
          />
        </div>

        {links?.length ? (
          <>
            <DividingLine />
            <div className="tenant-switch-modal__sub-text">
              Weitere Links zu Portalen von uns und unseren Schwesterunternehmen:
            </div>
            <div className="tenant-switch-modal__link-list">
              {links.map((link, index) => {
                return (
                  <div key={index} className="tenant-switch-modal__link-item-container">
                    <img
                      className="tenant-switch-modal__link-item-icon"
                      src={require('../../../assets/pics/newTab.png')}
                    />
                    <a href={prepareLink(link.urlTenantSwitch)} target="_blank" rel="noreferrer">
                      <div className="tenant-switch-modal__link-item">{link.linkTitle}</div>
                    </a>
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
      </div>
    );
  };

  return (
    <CustomModal
      isOpen={isOpen}
      title="Portalwechsel"
      content={switchContent()}
      ctaButtons={<ActionButton title="Schließen" onClick={onClose} inverted />}
    />
  );
};

TenantSwitchModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      linkTitle: PropTypes.string.isRequired,
      urlTenantSwitch: PropTypes.string.isRequired,
    })
  ),
};

export { TenantSwitchModal };
