import React, { useMemo, useState } from 'react';
import { BasicTable } from './BasicTable';
import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { SealsPopup } from '../Popup/SealsPopup';

export function SealsTable({ data, oldData, formik }) {
  const [selectedRow, setSelectedRow] = useState(0);
  const [popupOpen, setPopupOpen] = useState(false);

  const resetSeal = (id) => {
    const newSeals = data.map((seal) => {
      if (id === seal.id) {
        const oldSeal = oldData.find((seal) => seal.id === id);
        return { ...oldSeal };
      } else {
        return { ...seal };
      }
    });

    formik.setFieldValue('seals', newSeals);
  };

  const rows = useMemo(() => {
    const rowsNew = data.map((row, index) => {
      const setAsLost = row.lostDate ? true : false;
      return (
        <TableRow
          key={row.sealNumber}
          className={
            oldData && oldData[index]
              ? _.isEqual(row, oldData[index])
                ? ''
                : 'table-row-edited'
              : ''
          }
          sx={{
            '&:last-child td, &:last-child th': { border: 0 },
          }}>
          <TableCell scope="row">{row.sealNumber}</TableCell>
          <TableCell align="right" scope="row" className="capital">
            {row.status.name}
          </TableCell>
          <TableCell align="right">{row.issuedOn}</TableCell>
          <TableCell align="right">{row.returnedOn}</TableCell>
          <TableCell align="right">{row.lostOn}</TableCell>
          <TableCell align="right" className="action-buttons">
            <ActionButton
              inverted
              disabled={row.status.name !== 'ausgegeben'}
              className={`action-button-fixed-width ${
                row.status.name !== 'ausgegeben' ? 'action-button-hidden' : ''
              }`}
              title={setAsLost ? 'Zurücksetzen' : 'Verlust melden'}
              onClick={() => {
                if (setAsLost) {
                  resetSeal(row.id);
                } else {
                  formik.setFieldValue(`seals.${index}.isEditingLostData`, true);
                  setPopupOpen(true);
                  setSelectedRow(index);
                }
              }}></ActionButton>
          </TableCell>
        </TableRow>
      );
    });
    return rowsNew;
  }, [data]);

  const headers = () => {
    return [
      <TableCell scope="row" key={'Nummer'}>
        Nummer
      </TableCell>,
      <TableCell align="right" key={'Status'}>
        Status
      </TableCell>,
      <TableCell align="right" key={'Ausgegeben am'}>
        Ausgegeben am
      </TableCell>,
      <TableCell align="right" key={'Zurückgegeben am'}>
        Zurückgegeben am
      </TableCell>,
      <TableCell align="right" key={'Verlustmeldung am'}>
        Verlustmeldung am
      </TableCell>,
      <TableCell align="right" key={'Button'}></TableCell>,
    ];
  };

  return (
    <div>
      <BasicTable headers={headers()} rows={rows}></BasicTable>
      <SealsPopup
        oldData={oldData}
        formik={formik}
        selectedRow={selectedRow}
        popupOpen={popupOpen}
        setPopupOpen={setPopupOpen}></SealsPopup>
    </div>
  );
}
SealsTable.propTypes = {
  data: PropTypes.array,
  oldData: PropTypes.array,
  formik: PropTypes.object,
};
