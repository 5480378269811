import { VALIDATION_CONSTANTS } from 'global/constants';
import * as Yup from 'yup';

export const YParticipant = Yup.object().shape({
  firstName: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(25, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  lastName: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(75, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  eMail: Yup.string().email(VALIDATION_CONSTANTS.INVALID_EMAIL),
});
