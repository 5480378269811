import React from 'react';
import PropTypes from 'prop-types';
import './NEAgreementSection.sass';
import { CheckboxRow } from 'components/CheckboxRow/CheckboxRow';
import { isInstallerRegisteringForBranch } from './Utils/NEDataUtils';
import { BRANCH_TYPE } from 'global/constants';

const NEAgreementSection = ({
  energyAgreementLink,
  gasAgreementLink,
  branchSelectionValues,
  formik,
}) => {
  return (
    <div className="ne-agreement-section__wrapper">
      {isInstallerRegisteringForBranch(BRANCH_TYPE.ENERGY, branchSelectionValues) && (
        <CheckboxRow
          name="energyAgreement"
          title={'Erklärungen zur Eintragung ins Elektro-I-Verzeichnis'}
          label={'Ich verpflichte mich folgendes verbindlich anzuerkennen*:'}
          documentLink={energyAgreementLink}
          value={formik.values.energyAgreement}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.energyAgreement)}></CheckboxRow>
      )}
      {isInstallerRegisteringForBranch(BRANCH_TYPE.GAS, branchSelectionValues) && (
        <CheckboxRow
          name="gasAgreement"
          title={'Erklärungen zur Eintragung ins Gas-I-Verzeichnis'}
          label={'Ich verpflichte mich folgendes verbindlich anzuerkennen*:'}
          documentLink={gasAgreementLink}
          value={formik.values.gasAgreement}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.gasAgreement)}></CheckboxRow>
      )}
    </div>
  );
};

NEAgreementSection.propTypes = {
  energyAgreementLink: PropTypes.string,
  gasAgreementLink: PropTypes.string,
  branchSelectionValues: PropTypes.object,
  formik: PropTypes.object,
};

export { NEAgreementSection };
