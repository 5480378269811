/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import gql from 'graphql-tag';
import './LikeCard.scss';
import LikeIcon from '../../../assets/pics/like.png';
import Headline from '../../Headline/Headline';
import { CustomModal } from '../../CustomModal';
import { ActionButton } from '../../ActionButton/ActionButton';
import FeedbackForm from '../../FeedbackForm/FeedbackForm';
import { UPDATE_ARTICLE_LIKES, SEND_ARTICLE_FEEDBACK } from '../../../Queries/Mutations';
import { Utils } from '@adobe/aem-react-editable-components';

const getArticlesQuery = gql`
  query {
    articles {
      likes
      voted
      aemId
    }
  }
`;

let initialLikes = 0;
const isEditor = Utils.isInEditor();

const LikeCard = ({ aemId = '0', title, isOverview, likesFromBE, showLikes = false }) => {
  const {
    data,
    loading,
    error: errorOnGet,
  } = useQuery(getArticlesQuery, {
    fetchPolicy: 'network-only',
  });

  const getInputValues = (value) => setInputs(value);
  const [isValid, setIsValid] = useState(false);
  const [likes, setLikes] = useState(0);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: 'Ihr Feedback',
    content: <FeedbackForm handleSubmit={getInputValues} getIsValid={setIsValid} />,
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [revert, setRevert] = useState(false);
  const [inputs, setInputs] = useState({});
  const { name, message, email } = inputs;

  const [updateArticleLikes] = useMutation(UPDATE_ARTICLE_LIKES, {
    variables: {
      aemId,
      likes,
    },
  });

  const increment = async () => {
    if (!liked) {
      await updateArticleLikes();
      setLiked(true);
      setDisliked(false);
      setLikes(likes + 1);
    }
  };

  const [sendArticleFeedback] = useMutation(SEND_ARTICLE_FEEDBACK, {
    variables: {
      aemId,
      email,
      message,
      name,
    },
  });

  const sendFeedback = async () => {
    const response = await sendArticleFeedback();
    response.data.sendFeedback.id ? setSuccess(true) : setError(true);
    setRevert(false);
    setLiked(true);
    setDisliked(false);
  };

  const giveFeedback = () => setDisliked(true);

  const revertButtons = () => {
    setRevert(true);
    setError(false);
    setSuccess(false);
  };

  useEffect(() => {
    let newContent = {};
    if (!loading && aemId !== '0' && !isEditor) {
      initialLikes = data.articles.filter((article) => article.aemId === aemId);
      initialLikes[0] ? setLikes(initialLikes[0].likes) : setLikes(0);
      const disabledLikeButton = data.articles.filter((article) => article.aemId === aemId);
      disabledLikeButton[0] ? setLiked(disabledLikeButton[0].voted) : setLiked(false);
    } else if (errorOnGet) {
      setLikes(0);
      setLiked(false);
    }
    if (!success) {
      newContent = Object.assign({}, modalContent, {
        title: 'Ihr Feedback',
        content: (
          <FeedbackForm handleSubmit={getInputValues} article={title} getIsValid={setIsValid} />
        ),
      });
    }
    if (success) {
      newContent = Object.assign({}, modalContent, {
        title: 'Ihr Feedback',
        content:
          'Vielen Dank! Wir nehmen Ihren Hinweis gerne auf und werden diesen berücksichtigen.',
      });
      setError(false);
      setRevert(false);
    } else if (revert) {
      newContent = Object.assign({}, modalContent, {
        title: 'Ihr Feedback',
        content: (
          <FeedbackForm
            handleSubmit={getInputValues}
            initValues={inputs}
            article={title}
            getIsValid={setIsValid}
          />
        ),
      });
      setError(false);
      setSuccess(false);
    } else if (error) {
      newContent = Object.assign({}, modalContent, {
        title: 'Sorry! Hier ist ein Fehler aufgetreten.',
        content: 'Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut',
      });
      setRevert(false);
      setSuccess(false);
    }
    setModalContent(newContent);
    // eslint-disable-next-line
  }, [success, error, revert, loading, inputs, title]);

  const getModalCtaButtons = () => {
    if (success) {
      return <ActionButton onClick={() => setIsOpen(false)} title="Ok, verstanden" inverted />;
    }

    if (error) {
      return <ActionButton onClick={revertButtons} title="Ok, verstanden" inverted />;
    }

    if (revert) {
      return (
        <>
          <ActionButton onClick={sendFeedback} disabled={!isValid} title="Absenden" inverted />
          <ActionButton onClick={() => setIsOpen(false)} title="Abbrechen" inverted />
        </>
      );
    }

    return (
      <>
        <ActionButton onClick={sendFeedback} disabled={!isValid} title="Absenden" inverted />
        <ActionButton onClick={() => setIsOpen(false)} title="Abbrechen" inverted />
      </>
    );
  };

  const textToDisplay = () => {
    const likesToShow = likesFromBE || likes;
    if (likesToShow) {
      if (likesToShow === 1) {
        return <span>1 Person hat dieser Artikel geholfen</span>;
      } else return <span>{`${likesToShow} Personen hat dieser Artikel geholfen`}</span>;
    } else return <span>Seien Sie der Erste, dem der Artikel gefällt</span>;
  };

  return (
    !loading &&
    !isEditor &&
    showLikes && (
      <div className="like-card">
        <div className="like-card__like-container">
          <div className={`${liked ? 'like-card__disabled' : 'like-card__icon'}`}>
            <img
              src={LikeIcon}
              alt="like icon"
              onClick={increment}
              disabled={liked || isOverview}
            />
          </div>
          <div className="like-card__text">{textToDisplay()}</div>
        </div>
        {!isOverview && (
          <div>
            {!liked && (
              <>
                <div className="like-card__question">Hat Ihnen der Artikel gefallen?</div>
                <div className="like-card__button-wrapper">
                  <ActionButton onClick={increment} title="Ja" inverted />
                  <div className="like-card__button-spacer" />
                  <ActionButton onClick={giveFeedback} title="Nein" inverted />
                </div>
              </>
            )}
            {liked && (
              <div className="like-card__thank-you-text">Vielen Dank für Ihr Feedback!</div>
            )}
            {disliked && (
              <div>
                <Headline
                  className="like-card__headline"
                  text={'Wir sind sehr interessiert an Ihrem Feedback'}
                  tagName={'h6'}
                />
                <div className="like-card__paragraph">
                  Fragen, Kritik oder Anregungen? Wir würden uns über konstruktives Feedback zum
                  Beitrag sehr freuen. Dafür steht Ihnen unser Kontaktformular zur Verfügung. Vielen
                  Dank!
                </div>
                <ActionButton
                  onClick={() => setIsOpen(true)}
                  title="Zum Kontaktformular"
                  inverted
                />
              </div>
            )}
          </div>
        )}
        <CustomModal
          className="like-card__modal"
          title={modalContent.title}
          content={<div className="like-card__modal-content-wrapper">{modalContent.content}</div>}
          ctaButtons={getModalCtaButtons()}
          isOpen={isOpen}
        />
      </div>
    )
  );
};

export default LikeCard;
