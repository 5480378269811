import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Separator.sass';

const Separator = ({ color = 'primary', className }) => {
  const classes = classNames('separator', {
    [`${color}-color`]: true,
    [className]: !!className,
  });

  return <div className={classes} />;
};

Separator.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'white', 'homepage']),
  className: PropTypes.string,
};

export { Separator };
