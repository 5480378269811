import React from 'react';
import 'date-fns';
import Tags from '../components/Tags/Tags';

import './css/SearchBox.sass';
import OuterBox from './OuterBox/OuterBox';
import { SubTitle } from './SubTitle/SubTitle';
import PropTypes from 'prop-types';

const SearchBox = (props) => {
  const { title, tags, searchTagsLabel, formSlot, selectedTags, handleTagSelection } = props;
  return (
    <div className="search-box-container">
      <OuterBox>
        <SubTitle text="Suche" notFramed />
        <div className="search-title">{title}</div>
        <Tags
          className="search-tags"
          handleTagSelection={handleTagSelection}
          selectedTags={selectedTags}
          tags={tags}
        />
        <div className="search-box">
          <div className="search-tags-new">{searchTagsLabel}</div>
          <div className="search-box__form">{formSlot}</div>
        </div>
      </OuterBox>
    </div>
  );
};

SearchBox.propTypes = {
  title: PropTypes.string.isRequired,
  searchTagsLabel: PropTypes.string.isRequired,
  formSlot: PropTypes.element.isRequired,
  selectedTags: PropTypes.arrayOf(PropTypes.string),
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleTagSelection: PropTypes.func.isRequired,
};

export { SearchBox };
