import {
  isGuestOnly,
  isInstallerRegisteringForBranch,
  isRegisteringBranchGuest,
} from 'components/NEForm/Utils/NEDataUtils';
import { NE_ERROR } from 'components/NEForm/Utils/NEError';
import { BRANCH_TYPE, TENANT_ID } from 'global/constants';
import React from 'react';
import { getTenantId } from 'utils/tenantUtil';

export const NE_FIRST_SECTION_INPUTS = [
  {
    id: 'companyNames',
    title: 'Firmendaten',
    infoText:
      'Hier können Sie Ihre Eintragung ins Installateurverzeichnis beantragen.\n\nBeim Verlassen der Seite werden Ihre Daten nicht gespeichert. Halten Sie bitte alle notwendigen Dokumente für Ihre Eintragung bereit (z.B. Handwerkskarte, Gewerbeanmeldung, Qualifikationen für Fachkraft).',
    fields: [
      { name: 'companyName1', fieldName: 'Firmenname', mandatory: true },
      { name: 'companyName2', fieldName: 'Firmenname (weitere Angaben)' },
      { name: 'companyName3', fieldName: 'Firmenname (weitere Angaben)' },
      {
        name: 'companyOwner',
        fieldName: 'Firmeninhaber',
        mandatory: true,
        infoText: 'Bitte in diesem Feld alle Inhaber/Geschäftsführer/Teilhaber eintragen',
      },
    ],
  },
  {
    id: 'companyStreetData',
    fields: [
      { name: 'companyStreet', fieldName: 'Straße', mandatory: true },
      { name: 'companyHouseNumber', fieldName: 'Hausnummer', mandatory: true },
    ],
  },
  {
    id: 'companyCity',
    fields: [
      { name: 'companyPostal', fieldName: 'PLZ', mandatory: true },
      { name: 'companyCity', fieldName: 'Ort', mandatory: true },
      { name: 'companyRegion', fieldName: 'Ortsteil' },
    ],
  },
  {
    id: 'avaExtraInfo',
    subtitle: 'Zuweisung Auftragsort',
    tooltip: (
      <div className="deb-form__ava-tooltip-table">
        <div>
          Diese Angabe benötigen wir für unsere interne Zuordnung. Sofern Sie die Anmeldung von
          mehreren Installationsanlagen in unserem Gebiet planen, geben Sie die PLZ eines beliebigen
          Auftragsortes an. Ihre Gasteintragung gilt für das gesamte Gebiet der Avacon Netz GmbH. In
          der Tabelle einige Beispiele
        </div>
        <table cellPadding="0" cellSpacing="0" border="0">
          <thead>
            <tr>
              <th>Standort</th>
              <th>PLZ</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Burgwedel</td>
              <td>30938</td>
            </tr>
            <tr>
              <td>Gardelegen</td>
              <td>39638</td>
            </tr>
            <tr>
              <td>Gehrden</td>
              <td>30989</td>
            </tr>
            <tr>
              <td>Lüneburg</td>
              <td>21337</td>
            </tr>
            <tr>
              <td>Salzwedel</td>
              <td>29410</td>
            </tr>
            <tr>
              <td>Sarstedt</td>
              <td>31157</td>
            </tr>
            <tr>
              <td>Schöningen</td>
              <td>38364</td>
            </tr>
            <tr>
              <td>Syke</td>
              <td>28857</td>
            </tr>
            <tr>
              <td>Oschersleben</td>
              <td>39387</td>
            </tr>
            <tr>
              <td>WEVG / Salzgitter</td>
              <td>38226</td>
            </tr>
          </tbody>
        </table>
      </div>
    ),
    fields: [{ name: 'avaGuestPostal', fieldName: 'PLZ Auftragsort', mandatory: true }],
  },
  {
    id: 'companyContacts',
    subtitle: 'Kontaktdaten',
    fields: [
      {
        name: 'mobile',
        fieldName: 'Mobil',
        // infoText: 'Entweder Mobil und/oder Telefonnummer angeben',
      },
    ],
  },
  {
    id: 'companyContacts2',
    fields: [
      { name: 'phone', fieldName: 'Telefon', mandatory: true },
      {
        name: 'email',
        fieldName: 'E-Mail-Adresse',
        readOnly: true,
        mandatory: true,
        infoText: '(Entspricht der im ersten Schritt von Ihnen eingetragenen Adresse)',
      },
    ],
  },
  {
    id: 'craftChamberData',
    subtitle: 'Eintragung bei der Handwerkskammer',
    tooltip: 'Handwerkskarte',
    fields: [
      { name: 'craftsChamberArea', fieldName: 'Ausgestellt von', mandatory: true },
      { name: 'craftsChamberNumber', fieldName: 'Betriebsnummer', mandatory: true },
      { name: 'craftsChamberDate', fieldName: 'Ausstellungsdatum', mandatory: true },
    ],
  },
  {
    id: 'businessRegistrationData',
    subtitle: 'Gewerbean-/ummeldung',
    tooltip: 'von der zuständigen Gemeinde/Stadt',
    fields: [
      {
        name: 'businessRegistrationLocation',
        fieldName: 'Ort',
        mandatory: true,
        optionalForHilfsbetrieb: true,
      },
      {
        name: 'businessRegistrationDate',
        fieldName: 'Ausstellungsdatum',
        mandatory: true,
        optionalForHilfsbetrieb: true,
      },
    ],
  },
  {
    id: 'commercialRegistrationData',
    subtitle: 'Handelsregisterauszug',
    tooltip:
      'mit Nennung der aktuellen Inhaber/Geschäftsführer (bei Firmierung als „e. K.“, „GmbH“, „GmbH & Co. KG“; „AG“, etc.)',

    fields: [
      {
        name: 'commercialRegisterNumber',
        fieldName: 'HRA/-B-Nummer und Amtsgericht',
        mandatory: false,
      },
      { name: 'commercialRegisterDate', fieldName: 'Auszug vom', mandatory: false },
    ],
  },
  {
    id: 'gridProviderTitle',
    title: 'Gasteintragung',
    infoText:
      'Die Eintragungsdaten zu Firma und Fachkraft müssen den Daten auf Ihrem Installateurausweis entsprechen. Bitte aktualisieren Sie den Ausweis vor der Eintragung, falls erforderlich. Abweichende Daten können nicht eingetragen werden.',
    fields: [],
  },
  {
    id: 'gridEnergyProvider',
    subtitle: 'Eintragung bei ortsansässigem Netzbetreiber Strom',
    fields: [
      { name: 'gridProviderEnergy', fieldName: 'Netzbetreiber', mandatory: true },
      { name: 'foreignCompanyIdEnergy', fieldName: 'Ausweis-Nummer', mandatory: true },
    ],
  },
  {
    id: 'gridGasProvider',
    subtitle: 'Eintragung bei ortsansässigem Netzbetreiber Gas',
    fields: [
      { name: 'gridProviderGas', fieldName: 'Netzbetreiber', mandatory: true },
      { name: 'foreignCompanyIdGas', fieldName: 'Ausweis-Nummer', mandatory: true },
    ],
  },
];
// {
//   "email": "stefan@test.io",
//   "isEnergyActive": true,
//   "isGasActive": true,
//   "companyTypeEnergy": "Hauptbetrieb",
//   "companyTypeGas": "Nebenbetrieb",
//   "branchTypeEnergy": 1,
//   "branchTypeGas": 2
// }

export const isInstallerRegisteringOnlyForBranch = (branch, branchSelectionValues) => {
  const otherBranch = branch === BRANCH_TYPE.ENERGY ? BRANCH_TYPE.GAS : BRANCH_TYPE.ENERGY;
  return (
    isInstallerRegisteringForBranch(branch, branchSelectionValues) &&
    !isInstallerRegisteringForBranch(otherBranch, branchSelectionValues)
  );
};

export const isInstallerOnlyHilfsbetrieb = (installer) => {
  const isEnergyHilfsbetrieb = installer.companyTypeEnergy === 'Hilfsbetrieb';
  const isGasHilfsbetrieb = installer.companyTypeGas === 'Hilfsbetrieb';
  const hasEnergyBranch = isInstallerRegisteringForBranch(BRANCH_TYPE.ENERGY, installer);
  const hasGasBranch = isInstallerRegisteringForBranch(BRANCH_TYPE.GAS, installer);

  const isEnergyMain = !isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, installer);
  const isGasMain = !isRegisteringBranchGuest(BRANCH_TYPE.GAS, installer);
  if (hasEnergyBranch && hasGasBranch) {
    if (isEnergyMain && isGasMain) {
      // Both must be 'Hilfsbetrieb' to return true
      return isEnergyHilfsbetrieb && isGasHilfsbetrieb;
    }
    // At least one main branch must be 'Hilfsbetrieb' to return true
    return (isEnergyMain && isEnergyHilfsbetrieb) || (isGasMain && isGasHilfsbetrieb);
  }
  // Case: Only one branch exists
  // The existing branch must be 'Hilfsbetrieb' to return true
  return (hasEnergyBranch && isEnergyHilfsbetrieb) || (hasGasBranch && isGasHilfsbetrieb);
};

export const shouldNeFirstPageSectionRender = (sectionId, installer, tenantId) => {
  const avaGuestSection = ['avaExtraInfo'];
  const bagSectionsMain = [
    'craftChamberData',
    'businessRegistrationData',
    'commercialRegistrationData',
  ];
  const guestSections = ['gridProviderTitle', 'gridGasProvider', 'gridEnergyProvider'];
  const guestSectionsGas = 'gridGasProvider';
  const guestSectionsEnergy = 'gridEnergyProvider';
  const notRenderonHilfsbetrieb = ['craftChamberData'];

  switch (tenantId) {
    case TENANT_ID.BAG:
      if (notRenderonHilfsbetrieb == sectionId && isInstallerOnlyHilfsbetrieb(installer)) {
        return false;
      }
      if (avaGuestSection.includes(sectionId)) return false;
      if (guestSections.includes(sectionId)) {
        if (
          guestSectionsGas === sectionId &&
          isInstallerRegisteringForBranch(BRANCH_TYPE.GAS, installer) &&
          isRegisteringBranchGuest(BRANCH_TYPE.GAS, installer)
        ) {
          return true;
        } else {
          if (
            guestSectionsEnergy === sectionId &&
            isInstallerRegisteringForBranch(BRANCH_TYPE.ENERGY, installer) &&
            isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, installer)
          ) {
            return true;
          } else if (
            guestSectionsEnergy !== sectionId &&
            guestSectionsGas !== sectionId &&
            ((isInstallerRegisteringForBranch(BRANCH_TYPE.ENERGY, installer) &&
              isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, installer)) ||
              (isInstallerRegisteringForBranch(BRANCH_TYPE.GAS, installer) &&
                isRegisteringBranchGuest(BRANCH_TYPE.GAS, installer)))
          ) {
            return true;
          } else {
            return false;
          }
        }
      }
      if (bagSectionsMain.includes(sectionId) && isGuestOnly(installer)) {
        return false;
      }

      return true;
    default:
      if (bagSectionsMain.includes(sectionId)) {
        return false;
      } else if (avaGuestSection.includes(sectionId)) {
        if (tenantId === TENANT_ID.AVA && isGuestOnly(installer)) {
          return true;
        } else {
          return false;
        }
      } else {
        if (guestSections.includes(sectionId)) {
          if (
            guestSectionsGas === sectionId &&
            isInstallerRegisteringForBranch(BRANCH_TYPE.GAS, installer) &&
            isRegisteringBranchGuest(BRANCH_TYPE.GAS, installer)
          ) {
            return true;
          } else {
            if (
              guestSectionsEnergy === sectionId &&
              isInstallerRegisteringForBranch(BRANCH_TYPE.ENERGY, installer) &&
              isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, installer)
            ) {
              return true;
            } else {
              if (
                guestSectionsEnergy !== sectionId &&
                guestSectionsGas !== sectionId &&
                ((isInstallerRegisteringForBranch(BRANCH_TYPE.ENERGY, installer) &&
                  isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, installer)) ||
                  (isInstallerRegisteringForBranch(BRANCH_TYPE.GAS, installer) &&
                    isRegisteringBranchGuest(BRANCH_TYPE.GAS, installer)))
              ) {
                return true;
              } else {
                return false;
              }
            }
          }
        }
      }
  }
  return true;
};

export const shouldNeFirstPageSectionFieldRender = (fieldName, branchSelectionValues) => {
  const bagFields = [
    'companyOwner',
    'craftsChamberArea',
    'craftsChamberNumber',
    'craftsChamberDate',
    'businessRegistrationLocation',
    'businessRegistrationDate',
    'commercialRegisterNumber',
    'commercialRegisterDate',
  ];
  const energyGuestFields = ['gridProviderEnergy', 'foreignCompanyIdEnergy'];
  const gasGuestFields = ['gridProviderGas', 'foreignCompanyIdGas'];

  switch (getTenantId()) {
    case TENANT_ID.BAG:
      if (energyGuestFields.includes(fieldName)) {
        return (
          isInstallerRegisteringForBranch(BRANCH_TYPE.ENERGY, branchSelectionValues) &&
          isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, branchSelectionValues)
        );
      } else if (gasGuestFields.includes(fieldName)) {
        return (
          isInstallerRegisteringForBranch(BRANCH_TYPE.GAS, branchSelectionValues) &&
          isRegisteringBranchGuest(BRANCH_TYPE.GAS, branchSelectionValues)
        );
      } else {
        return true;
      }
    default:
      if (bagFields.includes(fieldName)) {
        return false;
      } else {
        if (energyGuestFields.includes(fieldName)) {
          return (
            isInstallerRegisteringForBranch(BRANCH_TYPE.ENERGY, branchSelectionValues) &&
            isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, branchSelectionValues)
          );
        } else if (gasGuestFields.includes(fieldName)) {
          return (
            isInstallerRegisteringForBranch(BRANCH_TYPE.GAS, branchSelectionValues) &&
            isRegisteringBranchGuest(BRANCH_TYPE.GAS, branchSelectionValues)
          );
        } else {
          return true;
        }
      }
  }
};

export const checkErrors = (values, branchSelectionValues, formErrors) => {
  const errors = [];

  if (Object.keys(formErrors).length !== 0) {
    errors.push(NE_ERROR.FORM_FIELD);
  }

  if (values.experts.length === 0) {
    errors.push(NE_ERROR.MIN_EXPERT);
  }

  if (branchSelectionValues.isEnergyActive && branchSelectionValues.isGasActive) {
    const hasEnergyExpert = values.experts.some((expert) => expert.isExpertEnergyActive);
    const hasGasExpert = values.experts.some((expert) => expert.isExpertGasActive);
    if (!hasEnergyExpert || !hasGasExpert) {
      errors.push(NE_ERROR.MIN_BRANCH_EXPERT);
    }
  }

  return errors;
};
