import React from 'react';
import PropTypes from 'prop-types';
import './HomepageCardContentItem.sass';
import { getTenantId } from '../../utils/tenantUtil';

const HomepageCardContentItem = ({ name, number }) => {
  const tenantId = getTenantId();
  return (
    <div className={`homepage-card-content-item-container ${tenantId}`}>
      <i className="material-icons homepage-card-content-item-icon">chevron_right</i>
      <div>{name}</div>
      {number !== null && number >= 0 && (
        <div className="homepage-card-content-item-number">{number}</div>
      )}
    </div>
  );
};

HomepageCardContentItem.propTypes = {
  name: PropTypes.string,
  number: PropTypes.number,
};

export { HomepageCardContentItem };
