import { useMemo } from 'react';
import { getInstallerStatusSummary } from 'utils/InstallerUtils';

export const useInstallerStatusSummary = (installer) =>
  useMemo(() => {
    if (!installer) {
      return { installerSummary: undefined };
    }
    return getInstallerStatusSummary(installer);
  }, [installer]);
