import { SHARED_DOCUMENTS } from 'global/SharedDocumentFields';
import { INSTALLER_ENTRIES, TENANT_ID } from 'global/constants';

export const NE_DOCUMENTS_FIELDS = {
  [INSTALLER_ENTRIES.MAIN_ENERGY_ONLY]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[13] },
      { ...SHARED_DOCUMENTS[14] },
      { ...SHARED_DOCUMENTS[15] },
      { ...SHARED_DOCUMENTS[17] },
    ],
    [TENANT_ID.EDI]: [{ ...SHARED_DOCUMENTS[13] }, { ...SHARED_DOCUMENTS[17] }],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[13] },
      { ...SHARED_DOCUMENTS[15] },
      { ...SHARED_DOCUMENTS[17] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[13] },
      { ...SHARED_DOCUMENTS[17] },
      { ...SHARED_DOCUMENTS[18] },
      { ...SHARED_DOCUMENTS[30] },
      { ...SHARED_DOCUMENTS[32] },
    ],
  },
  [INSTALLER_ENTRIES.MAIN_GAS_ONLY]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[13] },
      { ...SHARED_DOCUMENTS[14] },
      { ...SHARED_DOCUMENTS[15] },
      { ...SHARED_DOCUMENTS[16] },
      { ...SHARED_DOCUMENTS[17] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[13] },
      { ...SHARED_DOCUMENTS[14] },
      { ...SHARED_DOCUMENTS[16] },
      { ...SHARED_DOCUMENTS[17] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[13] },
      { ...SHARED_DOCUMENTS[14] },
      { ...SHARED_DOCUMENTS[16] },
      { ...SHARED_DOCUMENTS[17] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[13] },
      { ...SHARED_DOCUMENTS[14] },
      { ...SHARED_DOCUMENTS[15] },
      { ...SHARED_DOCUMENTS[16] },
      { ...SHARED_DOCUMENTS[17] },
      { ...SHARED_DOCUMENTS[18] },
      { ...SHARED_DOCUMENTS[31] },
    ],
  },
  [INSTALLER_ENTRIES.GUEST_ONLY]: {
    [TENANT_ID.AVA]: [{ ...SHARED_DOCUMENTS[19] }],
    [TENANT_ID.EDI]: [{ ...SHARED_DOCUMENTS[19] }],
    [TENANT_ID.SHNG]: [{ ...SHARED_DOCUMENTS[19] }],
    [TENANT_ID.BAG]: [{ ...SHARED_DOCUMENTS[19] }],
  },
  [INSTALLER_ENTRIES.MAIN_BOTH]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[13] },
      { ...SHARED_DOCUMENTS[14] },
      { ...SHARED_DOCUMENTS[15] },
      { ...SHARED_DOCUMENTS[16] },
      { ...SHARED_DOCUMENTS[17] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[13] },
      { ...SHARED_DOCUMENTS[14] },
      { ...SHARED_DOCUMENTS[16] },

      { ...SHARED_DOCUMENTS[17] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[13] },
      { ...SHARED_DOCUMENTS[14] },
      { ...SHARED_DOCUMENTS[15] },
      { ...SHARED_DOCUMENTS[16] },
      { ...SHARED_DOCUMENTS[17] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[13] },
      { ...SHARED_DOCUMENTS[14] },
      { ...SHARED_DOCUMENTS[15] },
      { ...SHARED_DOCUMENTS[16] },
      { ...SHARED_DOCUMENTS[17] },
      { ...SHARED_DOCUMENTS[18] },
      { ...SHARED_DOCUMENTS[30] },
      { ...SHARED_DOCUMENTS[31] },
      { ...SHARED_DOCUMENTS[32] },
    ],
  },
  [INSTALLER_ENTRIES.MAIN_ENERGY_GUEST_GAS]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[13] },
      { ...SHARED_DOCUMENTS[14] },
      { ...SHARED_DOCUMENTS[15] },
      { ...SHARED_DOCUMENTS[17] },
      { ...SHARED_DOCUMENTS[19] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[13] },
      { ...SHARED_DOCUMENTS[17] },
      { ...SHARED_DOCUMENTS[19] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[13] },
      { ...SHARED_DOCUMENTS[15] },
      { ...SHARED_DOCUMENTS[17] },
      { ...SHARED_DOCUMENTS[19] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[13] },
      { ...SHARED_DOCUMENTS[17] },
      { ...SHARED_DOCUMENTS[18] },
      { ...SHARED_DOCUMENTS[19] },
      { ...SHARED_DOCUMENTS[30] },
      { ...SHARED_DOCUMENTS[32] },
    ],
  },
  [INSTALLER_ENTRIES.GUEST_ENERGY_MAIN_GAS]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[13] },
      { ...SHARED_DOCUMENTS[14] },
      { ...SHARED_DOCUMENTS[15] },
      { ...SHARED_DOCUMENTS[16] },
      { ...SHARED_DOCUMENTS[17] },
      { ...SHARED_DOCUMENTS[19] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[13] },
      { ...SHARED_DOCUMENTS[16] },
      { ...SHARED_DOCUMENTS[14] },
      { ...SHARED_DOCUMENTS[17] },
      { ...SHARED_DOCUMENTS[19] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[13] },
      { ...SHARED_DOCUMENTS[14] },
      { ...SHARED_DOCUMENTS[16] },
      { ...SHARED_DOCUMENTS[17] },
      { ...SHARED_DOCUMENTS[19] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[13] },
      { ...SHARED_DOCUMENTS[14] },
      { ...SHARED_DOCUMENTS[15] },
      { ...SHARED_DOCUMENTS[16] },
      { ...SHARED_DOCUMENTS[17] },
      { ...SHARED_DOCUMENTS[18] },
      { ...SHARED_DOCUMENTS[19] },
    ],
  },
};

export const NE_EXPERTS_DOCUMENTS_FIELDS = {
  [INSTALLER_ENTRIES.MAIN_ENERGY_ONLY]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[23] },
      { ...SHARED_DOCUMENTS[25] },
      { ...SHARED_DOCUMENTS[27] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[23] },
      { ...SHARED_DOCUMENTS[25] },
      { ...SHARED_DOCUMENTS[27] },
    ],
    [TENANT_ID.SHNG]: [{ ...SHARED_DOCUMENTS[25] }, { ...SHARED_DOCUMENTS[27] }],
    [TENANT_ID.BAG]: [{ ...SHARED_DOCUMENTS[25] }, { ...SHARED_DOCUMENTS[27] }],
  },
  [INSTALLER_ENTRIES.MAIN_GAS_ONLY]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[23] },
      { ...SHARED_DOCUMENTS[26] },
      { ...SHARED_DOCUMENTS[28] },
      { ...SHARED_DOCUMENTS[29] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[23] },
      { ...SHARED_DOCUMENTS[26] },
      { ...SHARED_DOCUMENTS[28] },
      { ...SHARED_DOCUMENTS[29] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[23] },
      { ...SHARED_DOCUMENTS[26] },
      { ...SHARED_DOCUMENTS[28] },
      { ...SHARED_DOCUMENTS[29] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[23] },
      { ...SHARED_DOCUMENTS[26] },
      { ...SHARED_DOCUMENTS[28] },
      { ...SHARED_DOCUMENTS[29] },
    ],
  },
  [INSTALLER_ENTRIES.GUEST_ONLY]: {
    [TENANT_ID.AVA]: [],
    [TENANT_ID.EDI]: [],
    [TENANT_ID.SHNG]: [],
    [TENANT_ID.BAG]: [],
  },
  [INSTALLER_ENTRIES.MAIN_BOTH]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[23] },
      { ...SHARED_DOCUMENTS[25] },
      { ...SHARED_DOCUMENTS[26] },
      { ...SHARED_DOCUMENTS[27] },
      { ...SHARED_DOCUMENTS[28] },
      { ...SHARED_DOCUMENTS[29] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[23] },
      { ...SHARED_DOCUMENTS[25] },
      { ...SHARED_DOCUMENTS[26] },
      { ...SHARED_DOCUMENTS[27] },
      { ...SHARED_DOCUMENTS[28] },
      { ...SHARED_DOCUMENTS[29] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[23] },
      { ...SHARED_DOCUMENTS[25] },
      { ...SHARED_DOCUMENTS[26] },
      { ...SHARED_DOCUMENTS[27] },
      { ...SHARED_DOCUMENTS[28] },
      { ...SHARED_DOCUMENTS[29] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[23] },
      { ...SHARED_DOCUMENTS[25] },
      { ...SHARED_DOCUMENTS[26] },
      { ...SHARED_DOCUMENTS[27] },
      { ...SHARED_DOCUMENTS[28] },
      { ...SHARED_DOCUMENTS[29] },
    ],
  },
  [INSTALLER_ENTRIES.MAIN_ENERGY_GUEST_GAS]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[23] },
      { ...SHARED_DOCUMENTS[25] },
      { ...SHARED_DOCUMENTS[27] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[23] },
      { ...SHARED_DOCUMENTS[25] },
      { ...SHARED_DOCUMENTS[27] },
    ],
    [TENANT_ID.SHNG]: [{ ...SHARED_DOCUMENTS[25] }, { ...SHARED_DOCUMENTS[27] }],
    [TENANT_ID.BAG]: [{ ...SHARED_DOCUMENTS[25] }, { ...SHARED_DOCUMENTS[27] }],
  },
  [INSTALLER_ENTRIES.GUEST_ENERGY_MAIN_GAS]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[23] },
      { ...SHARED_DOCUMENTS[26] },
      { ...SHARED_DOCUMENTS[28] },
      { ...SHARED_DOCUMENTS[29] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[23] },
      { ...SHARED_DOCUMENTS[26] },
      { ...SHARED_DOCUMENTS[28] },
      { ...SHARED_DOCUMENTS[29] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[23] },
      { ...SHARED_DOCUMENTS[26] },
      { ...SHARED_DOCUMENTS[28] },
      { ...SHARED_DOCUMENTS[29] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[23] },
      { ...SHARED_DOCUMENTS[26] },
      { ...SHARED_DOCUMENTS[28] },
      { ...SHARED_DOCUMENTS[29] },
    ],
  },
};
