import { Page } from '@adobe/aem-react-editable-components';
import React from 'react';
import PropTypes from 'prop-types';

class ComponentContainer extends Page {
  render() {
    return <>{this.childComponents}</>;
  }
}

ComponentContainer.propTypes = {
  tagName: PropTypes.string,
};

export default ComponentContainer;
