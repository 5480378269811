// revu-global/e-fix/components/content/teaser
import { MapTo } from '@adobe/aem-react-editable-components';
import React from 'react';
import striptags from 'striptags';
import { MessageBox } from './MessageBox/MessageBox';

const EditConfig = {
  emptyLabel: 'Title',

  isEmpty: function (props) {
    return !props || !props.text || props.text.trim().length < 1;
  },
};

function mapProps(props) {
  return {
    title: props.title,
    text: striptags(props.description),
    buttonText: props.actions[0]?.title,
    url: props.actions[0]?.url,
  };
}

const Teaser = (props) => {
  return <MessageBox data={mapProps(props)} />;
};

MapTo('e-fix/components/content/teaser')(Teaser, EditConfig);
