import { isInstallerOnlyHilfsbetrieb } from 'components/NEComponents/NESteps/NEStepForm/NEFormUtility';
import { DOCUMENT_NAME_TO_ID } from 'global/SharedDocumentFields';

export const shouldNeSecondPageUploadSectionRender = (
  sectionId,
  triggerIds,
  branchSelectionValues
) => {
  const documentsToHideWhenHilfsbetrieb = [DOCUMENT_NAME_TO_ID.craftCard];
  if (
    documentsToHideWhenHilfsbetrieb.includes(sectionId) &&
    isInstallerOnlyHilfsbetrieb(branchSelectionValues)
  ) {
    return false;
  }
  return triggerIds.includes(sectionId);
};
