/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './css/HomePageHeaderSubLinks.sass';
import qs from 'query-string';
import { getLexikonUrl, getMyDataPath } from 'utils/UrlUtil';
import { getInstallerStatusbarData } from 'utils/InstallerUtils';

const TWO_DIGIT = 100;

const HomePageHeaderSubLink = ({ link, name, closeMenu, params, installer }) => {
  const [shouldExtend, setShouldExtend] = useState(false);

  useEffect(() => {
    if (!installer || getMyDataPath() !== link) return;
    const installerDataResponse = getInstallerStatusbarData(installer);
    const shouldExtendEntry =
      installerDataResponse.gasEntry?.shouldExtend ||
      installerDataResponse.energyEntry?.shouldExtend;
    setShouldExtend(shouldExtendEntry);
  }, [installer, link]);

  const prepareLocation = () => ({
    exact: 'false',
    to: {
      pathname: link,
      search: qs.stringify(params),
      state: params,
      key: Math.floor(Math.random() * TWO_DIGIT),
    },
  });

  const location = prepareLocation();

  return (
    <button
      className={`sublink first-level`}
      onClick={() => {
        const isLexikon = link.includes('lexikon');
        if (isLexikon) {
          window.location.assign(getLexikonUrl());
        }
        if (closeMenu) {
          closeMenu();
        }
      }}>
      <Link {...location}>
        <div className="homepage-header-sublink-text-wrapper">
          <div>{name}</div>
          {shouldExtend ? <div className="sublink-red">{'(!)'}</div> : null}
        </div>
      </Link>
    </button>
  );
};

export default HomePageHeaderSubLink;
