/* eslint-disable react/prop-types */
import React from 'react';
import './DataItem.sass';

const DataItem = ({ data }) => {
  const detailData = data.data;

  switch (data.type) {
    case 'tel': {
      return (
        <div className="dataItem">
          <span className="tel">
            {data?.appendTelPrefix && <span>Tel.</span>}
            <span>
              <a href={`tel:${detailData?.value}`}>{detailData?.value}</a>
            </span>
          </span>
          {detailData?.name && <span className="name">{detailData?.name}</span>}
        </div>
      );
    }
    case 'email': {
      return (
        <div className="dataItem">
          <a href={`mailto:${detailData.value}`} className="email">
            {detailData.value}
          </a>
        </div>
      );
    }
    case 'value': {
      return <div className="dataItem value">{detailData?.value || '-'}</div>;
    }
    case 'value-thin': {
      return <div className="dataItem value-thin">{detailData?.value || '-'}</div>;
    }
    case 'value-no-margin': {
      return <div className="dataItem value-no-margin">{detailData?.value || '-'}</div>;
    }
    case 'tel-email': {
      return (
        <div className="dataItem">
          <div className="telEmail">
            <span className="tel">
              <span>Tel.</span>
              <span>
                <a href={`tel:${detailData?.firstValue}`}>{detailData?.firstValue}</a>
              </span>
            </span>
            <span className="tel-email-wrapper">
              <a href={`mailto:${detailData.secondValue}`} className="email">
                {detailData.secondValue}
              </a>
            </span>
          </div>
        </div>
      );
    }
    default:
      return null;
  }
};

export default DataItem;
