import { getTenantInfo } from './tenantUtil';
import { getDomainCIAMLoginPrefixByStage, getDomainPrefixByStage, isLocal } from './StageUtils';
import { DOMAIN_CIAM_LOGIN_PREFIX } from 'global/constants';

/**
 * Check if an url is external url or not
 * We are assuming that any url which starts with https is an external url
 * @param url - value to check
 * @return {boolean}
 */
export const isExternal = (url) => {
  return /^https?:\/\//.test(url);
};

export const getLinkToMyData = (currentUrl) => {
  let newUrl = currentUrl.split('/de')[0];
  const newPath = '/de/my-data.html';
  newUrl += newPath;

  return newUrl;
};

export const getBaseUrl = (isAEM = false) => {
  let baseUrl = '';
  const tenantSubDomain = getTenantInfo().subDomain;
  if (isLocal()) {
    baseUrl = `http://localhost:${
      isAEM ? process.env.REACT_APP_AEM_PORT : process.env.REACT_APP_PORT
    }`;
  } else {
    baseUrl = `https://${getDomainPrefixByStage()}${tenantSubDomain}.e-fix.info`;
  }
  return baseUrl;
};

export const getLogoutRedirectUrl = (isCIAM) => {
  if (isLocal()) {
    if (confirm('Cancel for EIAM, OK for CIAM')) {
      isCIAM = true;
    } else {
      isCIAM = false;
    }
  }
  return isCIAM ? getCIAMLogoutUrl() : getEIAMLogoutUrl();
};

const getCIAMLogoutUrl = () => {
  const tenantInfo = getTenantInfo();

  if (isLocal()) {
    return `https://${
      DOMAIN_CIAM_LOGIN_PREFIX.LOCAL
    }e-fix.info/secur/logout.jsp?retUrl=${encodeURIComponent(
      `/services/oauth2/authorize?client_id=3MVG9sSN_PMn8tjSodyD28jSJpyXAPKdt_YdgBgU1A7PjKW2ILbQ_wqDrjN_qXt3l39cMWsDx433YvdND71Wy&redirect_uri=http://localhost:3000/content/revu-global/e-fix/${tenantInfo.aemPath}/de.html&response_type=code&scope=openid`
    )}`;
  } else {
    return `https://${getDomainCIAMLoginPrefixByStage()}e-fix.info/secur/logout.jsp?retUrl=https://${getDomainCIAMLoginPrefixByStage()}e-fix.info/CommunitiesLandingForNetzportal?redirectUrl=${getTenantLandingPage()}`;
  }
};

const getEIAMLogoutUrl = () => {
  const tenantInfo = getTenantInfo();

  const azureTenantId = 'b914a242-e718-443b-a47c-6b4c649d8c0a';
  let postLogoutRedirectUri = '';

  if (isLocal()) {
    postLogoutRedirectUri = encodeURIComponent(
      `https://login.microsoftonline.com/b914a242-e718-443b-a47c-6b4c649d8c0a/oauth2/v2.0/authorize?client_id=54d66672-abf2-4a75-913c-0ee8d21f7e74&scope=54d66672-abf2-4a75-913c-0ee8d21f7e74%2F.default&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3001%2Fcontent%2Frevu-global%2Fe-fix%2F${tenantInfo.aemPath}%2Fde.html%3Fauth%3DEIAM`
    );
  } else {
    postLogoutRedirectUri = encodeURIComponent(getTenantLandingPage());
  }

  return `https://login.microsoftonline.com/${azureTenantId}/oauth2/v2.0/logout?post_logout_redirect_uri=${postLogoutRedirectUri}`;
};

export const getLexikonUrl = () => {
  return `${getBaseUrl(true)}${getLexikonHomePath()}`;
};

export const getHomepageUrlByTenantId = (tenantId) => {
  let baseUrl = '';
  const tenantSubDomain = getTenantInfo(tenantId).subDomain;
  if (isLocal()) {
    baseUrl = `http://localhost:${process.env.REACT_APP_PORT}`;
  } else {
    baseUrl = `https://${getDomainPrefixByStage()}${tenantSubDomain}.e-fix.info`;
  }

  return baseUrl + getPath(tenantId).HOMEPAGE;
};

export const getNewsletterCallbackUrl = () => {
  return `${getBaseUrl()}${getPath().NEWSLETTER}`;
};

export const getAuthEIAMUrl = (domainPrefix) => {
  let url = '';
  if (isLocal()) {
    url = `http://localhost:${process.env.REACT_APP_BE_PORT}/api/eiam/token`;
  } else {
    url = `https://${domainPrefix || getDomainPrefixByStage()}backend.e-fix.info/api/eiam/token`;
  }
  return url;
};

export const getAuthCIAMUrl = (domainPrefix) => {
  let url = '';
  if (isLocal()) {
    url = `http://localhost:${process.env.REACT_APP_BE_PORT}/api/ciam/token`;
  } else {
    url = `https://${domainPrefix || getDomainPrefixByStage()}backend.e-fix.info/api/ciam/token`;
  }
  return url;
};

export const getHomepagePath = () => {
  return getPath().HOMEPAGE;
};

export const getExpertTelephonePath = () => {
  return getPath().EXPERT_TELEPHONE;
};

export const getExpertTelephoneConnectionServicePath = () => {
  return getPath().EXPERT_TELEPHONE_CONNECTION_SERVICE;
};

export const getExpertTelephoneInstallerServicePath = () => {
  return getPath().EXPERT_TELEPHONE_INSTALLER_SERVICE;
};

export const getExpertTelephoneHelpInPlacePath = () => {
  return getPath().EXPERT_TELEPHONE_HELP_IN_PLACE;
};

export const getExpertTelephoneItHelpPath = () => {
  return getPath().EXPERT_TELEPHONE_IT_HELP;
};

export const getExpertTelephoneNetworkConnectionPath = () => {
  return getPath().EXPERT_TELEPHONE_NETWORK_CONNECTION;
};

export const getExpertTelephoneServiceProviderPath = () => {
  return getPath().EXPERT_TELEPHONE_SERVICE_PROVIDER;
};

export const getExpertTelephoneSupportHotlinePath = () => {
  return getPath().EXPERT_TELEPHONE_SUPPORT_HOTLINE;
};
export const getEventsOverviewPath = () => {
  return getPath().EVENTS_OVERVIEW;
};

export const getArticlesOverviewPath = () => {
  return getPath().ARTICLES_OVERVIEW;
};

export const getEventsAndArticlesOverviewPath = () => {
  return getPath().EVENTS_AND_ARTICLES_OVERVIEW;
};

export const getLexikonHomePath = () => {
  return getPath().LEXIKON_HOME;
};

export const getDisorderPath = () => {
  return getPath().DISORDER;
};

export const getMyDataPath = () => {
  return getPath().MY_DATA;
};

export const getDEBPath = () => {
  return getPath().DEB;
};

export const getPrivacyPath = () => {
  return getPath().PRIVACY;
};

const getPath = (tenantId) => {
  const tenantInfo = getTenantInfo(tenantId);
  return {
    HOMEPAGE: `/content/revu-global/e-fix/${tenantInfo.aemPath}/de.html`,
    EXPERT_TELEPHONE: `/content/revu-global/e-fix/${tenantInfo.aemPath}/de/expertentelefon.html`,
    EXPERT_TELEPHONE_CONNECTION_SERVICE: `/content/revu-global/e-fix/${tenantInfo.aemPath}/de/expertentelefon/anschlussservice.html`,
    EXPERT_TELEPHONE_INSTALLER_SERVICE: `/content/revu-global/e-fix/${tenantInfo.aemPath}/de/expertentelefon/installateur-service.html`,
    EXPERT_TELEPHONE_HELP_IN_PLACE: `/content/revu-global/e-fix/${tenantInfo.aemPath}/de/expertentelefon/hilfe-vor-ort.html`,
    EXPERT_TELEPHONE_IT_HELP: `/content/revu-global/e-fix/${tenantInfo.aemPath}/de/expertentelefon/it-hilfe.html`,
    EXPERT_TELEPHONE_NETWORK_CONNECTION: `/content/revu-global/e-fix/${tenantInfo.aemPath}/de/expertentelefon/netzanschluss.html`,
    EXPERT_TELEPHONE_SERVICE_PROVIDER: `/content/revu-global/e-fix/${tenantInfo.aemPath}/de/expertentelefon/dienstleister-zahlersetzung.html`,
    EXPERT_TELEPHONE_SUPPORT_HOTLINE: `/content/revu-global/e-fix/${tenantInfo.aemPath}/de/expertentelefon/map-support-hotline.html`,
    EVENTS_OVERVIEW: `/content/revu-global/e-fix/${tenantInfo.aemPath}/de/informationskanal/veranstaltungen.html`,
    ARTICLES_OVERVIEW: `/content/revu-global/e-fix/${tenantInfo.aemPath}/de/informationskanal/fachbeitraege.html`,
    EVENTS_AND_ARTICLES_OVERVIEW: `/content/revu-global/e-fix/${tenantInfo.aemPath}/de/informationskanal.html`,
    LEXIKON_HOME: `/content/revu-global/e-fix/${tenantInfo.aemPath}/de/lexikon/lexikon-home.html`,
    DISORDER: `/content/revu-global/e-fix/${tenantInfo.aemPath}/de/stoerungen.html`,
    MY_DATA: `/content/revu-global/e-fix/${tenantInfo.aemPath}/de/my-data.html`,
    DEB: `/content/revu-global/e-fix/${tenantInfo.aemPath}/de/my-data/datenerfassungsblatt.html`,
    PRIVACY: `/content/revu-global/e-fix/${tenantInfo.aemPath}/de/my-data/data-privacy.html`,
    NEWSLETTER: `/content/revu-global/e-fix/${tenantInfo.aemPath}/de/newsletter.html`,
  };
};

export const getTenantLandingPage = (tenantId) => {
  const tenantInfo = getTenantInfo(tenantId);
  return `https://${getDomainPrefixByStage()}e-fix.info/${tenantInfo.aemPath}/index.html`;
};
