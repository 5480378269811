import React from 'react';
import './NEStepsbar.sass';
import { useNESteps } from 'context/NEStepsContext';
import CheckIcon from '../../../assets/pics/check.png';
import { getTenantId } from 'utils/tenantUtil';
import { TENANT_ID } from 'global/constants';

const NEStepsbar = () => {
  const { activeStep } = useNESteps();
  const isBAG = getTenantId() === TENANT_ID.BAG;
  const isAVA = getTenantId() === TENANT_ID.AVA;

  return (
    <div className="ne-stepsbar">
      <div className="ne-stepsbar__background-line" />

      <div className="ne-stepsbar__steps">
        {[0, 1, 2, 3].map((step) => (
          <div key={`step_${step}`} className="ne-stepsbar__step" />
        ))}
      </div>
      <div className="ne-stepsbar__lines">
        {[1, 2, 3].map((step) => (
          <div
            key={`line_${step}`}
            className={`ne-stepsbar__line ${activeStep >= step ? 'ne-stepsbar__line--active' : ''}`}
          />
        ))}
      </div>
      <div className="ne-stepsbar__steps-active">
        {[0, 1, 2, 3].map((step) => (
          <div
            key={`active_step_${step}`}
            className={`ne-stepsbar__step-active ${
              activeStep >= step ? 'ne-stepsbar__step-active--active' : ''
            }`}>
            {activeStep > step && (
              <div className="ne-stepsbar__step-active--check-container">
                <img className="ne-stepsbar__step-active--check" src={CheckIcon} />
              </div>
            )}
            {activeStep === step && (
              <div className="ne-stepsbar__step-active--number-container">
                <div
                  className={`ne-stepsbar__step-active--number${isBAG ? ' bag' : ''}${
                    isAVA ? ' ava' : ''
                  }`}>
                  {step + 1}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export { NEStepsbar };
