export const successMessage = {
  title: 'Vielen Dank!',
  subtitle1: 'Ihre Nachricht wurde an den zuständigen Bereich ',
  subtitle2: ' übermittelt. Wir werden uns in Kürze mit Ihnen in Verbindung setzen!',
};

export const errorMessage = {
  title: 'Das hat leider nicht geklappt!',
  subtitle1:
    'Es gab ein technisches Problem beim Versenden deiner Nachricht, wir bitten dies zu entschuldigen',
};

export default { successMessage, errorMessage };
