import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import _, { uniqueId } from 'lodash';

import { ActionButton } from 'components/ActionButton/ActionButton';
import { getTenantId } from 'utils/tenantUtil';
import { ExpertPopupEnergy } from '../Popup/ExpertPopupEnergy';
import { BasicTable } from './BasicTable';
import { ExpertPopupDelete } from '../Popup/ExpertPopupDelete';
import { TableActionButtons } from './TableActionButtons';
import { BRANCH_TYPE, EIV_STATUS, EIV_STATUS_TO_STRING, TENANT_ID } from 'global/constants';

export function ExpertTableEnergy({
  data,
  oldData,
  deleteExpert,
  setSelectedId,
  selectedRow,
  formik,
  energyType,
  resetExpert,
}) {
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openPopup, setPopupOpen] = useState(false);
  const newExpertId = useRef(null);
  const edit = useRef(false);

  useEffect(() => {
    if (newExpertId.current) {
      setSelectedId(newExpertId.current);
      setPopupOpen(true);
    }
  }, [newExpertId.current]);

  const rows = useMemo(() => {
    const rowsNew = data.map((row) => {
      let oldRow = null;
      let edited = null;
      let editedEnergy = null;
      let deleted = null;
      if (oldData) {
        oldRow = oldData.find((oldRow) => oldRow.id === row.id);
        edited = !_.isEqual(row, oldRow);
        editedEnergy = oldRow && row.expertEnergyValidityDate !== oldRow.expertEnergyValidityDate;
        deleted = row.statusEnergy === EIV_STATUS.DELETED;
      }

      return (
        <TableRow
          key={row.id}
          className={deleted ? 'table-row-deleted' : edited ? 'table-row-edited' : ' '}
          sx={{
            '&:last-child td, &:last-child th': { border: 0 },
          }}>
          <TableCell scope="row" width={215}>
            {row.lastName}
          </TableCell>
          <TableCell align="right" scope="row" width={215}>
            {row.firstName}
          </TableCell>
          {getTenantId() === TENANT_ID.BAG ? (
            <TableCell align="right">{row.birthdate}</TableCell>
          ) : null}
          <TableCell align="right">
            {row.statusEnergy === EIV_STATUS.NEW && row.isMainEnergy
              ? 'Datum nach Prüfung'
              : row.expertEnergyValidityDate
              ? row.expertEnergyValidityDate
              : formik.values.installerEnergyValidityDate}
          </TableCell>
          <TableCell align="right">{EIV_STATUS_TO_STRING[row.statusEnergy]}</TableCell>
          <TableCell align="right" className="action-buttons">
            <TableActionButtons
              row={row}
              branchType={BRANCH_TYPE.ENERGY}
              entryType={energyType}
              edited={editedEnergy || deleted}
              resetExpert={resetExpert}
              deleteExpert={deleteExpert}
              setSelectedId={setSelectedId}
              setOpenDeletePopup={setOpenDeletePopup}
              setPopupOpen={setPopupOpen}
              edit={edit}
            />
          </TableCell>
        </TableRow>
      );
    });
    return rowsNew;
  }, [data, selectedRow, formik.values]);

  const headers = () => {
    return [
      <TableCell component="th" scope="row" key={'Name'}>
        Name
      </TableCell>,
      <TableCell align="right" key={'Vorname'}>
        Vorname
      </TableCell>,
      ...(getTenantId() === TENANT_ID.BAG
        ? [
            <TableCell align="right" key={'GebDatum'}>
              Geb.-Datum
            </TableCell>,
          ]
        : []),
      <TableCell align="right" key={'Ausweisgultigbis'}>
        Ausweis gültig bis
      </TableCell>,
      <TableCell align="right" key={'Status'}>
        Status
      </TableCell>,
      <TableCell align="right" key={'Button'}></TableCell>,
    ];
  };

  return (
    <div className="expert-table">
      <BasicTable headers={headers()} rows={rows}></BasicTable>
      <ActionButton
        inverted
        title={'Weitere Fachkraft hinzufügen'}
        onClick={async () => {
          const id = uniqueId();
          formik.setFieldValue('experts', [
            ...formik.values.experts,
            {
              id: id,
              birthdate: '',
              expertGasValidityDate: '',
              expertEnergyValidityDate: '',
              lastName: '',
              statusGas: EIV_STATUS.NOT_SET,
              statusEnergy: EIV_STATUS.NEW,
              isMainGas: undefined,
              isMainEnergy: energyType === 'main',
              firstName: '',
              deletionReasonEnergy: '',
              deletionReasonGas: '',
              deletionDateEnergy: '',
              deletionDateGas: '',
            },
          ]);
          edit.current = false;
          newExpertId.current = id;
        }}></ActionButton>
      <ExpertPopupEnergy
        oldData={oldData}
        formik={formik}
        selectedRow={selectedRow}
        edit={edit.current}
        popupOpen={openPopup}
        setPopupOpen={setPopupOpen}
        isGuest={energyType === 'guest'}></ExpertPopupEnergy>
      <ExpertPopupDelete
        formik={formik}
        selectedRow={selectedRow}
        popupOpen={openDeletePopup}
        setPopupOpen={setOpenDeletePopup}
        deleteExpert={deleteExpert}
        branch={BRANCH_TYPE.ENERGY}></ExpertPopupDelete>
    </div>
  );
}
ExpertTableEnergy.propTypes = {
  data: PropTypes.array,
  oldData: PropTypes.array,
  deleteExpert: PropTypes.func,
  resetExpert: PropTypes.func,
  setSelectedId: PropTypes.func,
  selectedRow: PropTypes.number,
  formik: PropTypes.object,
  gasType: PropTypes.string,
  energyType: PropTypes.string,
};
