import { EIV_ENDPOINT } from '../APIUtils';
import { getAxiosInstance } from '../InitAxios';

export const patchEinwilligungByInstallerId = async (id, data, setShowError) => {
  const axiosInstance = getAxiosInstance();
  return axiosInstance
    .patch(EIV_ENDPOINT.EINWILLIGUNG_BY_INSTALLER_ID(id), data)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      setShowError(true);
    });
};

export const createPatchEinwilligungByInstallerIdData = (dsgvoAddressPublic, dsgvoCanSendMail) => ({
  zu_adressveroeff: dsgvoAddressPublic ? 'Ja' : 'Nein',
  zu_infoversand: dsgvoCanSendMail ? 'Ja' : 'Nein',
});
