/* eslint-disable react/prop-types */
import * as React from 'react';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TextField from '@material-ui/core/TextField';
import { MobileDateRangePicker, LocalizationProvider } from '@material-ui/pickers';

import InputAdornment from '@material-ui/core/InputAdornment';
import DateRangeIcon from '@material-ui/icons/DateRange';

import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import deLocale from 'date-fns/locale/de';
import './DatePicker.scss';

const CustomInput = ({ formatedDate, handleDelete, ...rest }) => {
  return (
    <TextField
      {...rest}
      className="search-field"
      id="standard-start-adornment"
      variant="standard"
      helperText=""
      label=""
      placeholder=""
      name="datepicker-input"
      disabled={true}
      inputProps={{
        className: 'datepicker-input-field',
      }}
      InputProps={{
        value: formatedDate || '',
        name: 'datepicker-input-field',
        endAdornment: (
          <>
            {formatedDate && <HighlightOffIcon size="small" onClick={handleDelete} />}
            <InputAdornment position="end">
              <DateRangeIcon />
            </InputAdornment>
          </>
        ),
      }}
    />
  );
};

export default function DatePicker(props) {
  const { value, setValue, onSubmit } = props;

  const deleteDate = (e) => {
    e.stopPropagation();
    setValue([null, null]);
    onSubmit([null, null]);
  };

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <MobileDateRangePicker
          cancelText=""
          endText="Enddatum"
          startText="Startdatum"
          toolbarTitle="Zeitraum auswählen"
          dateAdapter={new DateFnsAdapter({ locale: deLocale })}
          calendars={1}
          mask="__.__.____"
          value={value}
          inputFormat="dd.MM.yyyy"
          onChange={(newValue) => setValue(newValue)}
          onClose={() => {
            onSubmit(value);
          }}
          renderInput={(startProps, endProps) => {
            const { value: startValue } = startProps.inputProps;
            const { value: endValue } = endProps.inputProps;

            const formatedDate =
              !startValue && !endValue
                ? null
                : endValue && startValue
                ? `${startValue} - ${endValue}`
                : endValue
                ? `bis ${endValue}`
                : `ab ${startValue}`;

            return (
              <React.Fragment>
                <CustomInput
                  formatedDate={formatedDate}
                  {...startProps.inputProps}
                  handleDelete={deleteDate}
                />
              </React.Fragment>
            );
          }}
        />
      </LocalizationProvider>
    </React.Fragment>
  );
}
