import React from 'react';
import PropTypes from 'prop-types';
import { ActionButton } from 'components/ActionButton/ActionButton';

import './Table.sass';
import { BRANCH_TYPE, EIV_STATUS } from 'global/constants';

const TableActionButtons = ({
  row,
  branchType,
  entryType,
  edited,
  resetExpert,
  deleteExpert,
  setSelectedId,
  setOpenDeletePopup,
  setPopupOpen,
  edit,
}) => {
  const typeStatus = branchType === BRANCH_TYPE.ENERGY ? row.statusEnergy : row.statusGas;
  return (
    <>
      <ActionButton
        inverted
        className="action-button-fixed-width"
        title={edited ? 'Zurücksetzen' : 'Entfernen'}
        onClick={() => {
          if (edited) {
            resetExpert(row.id, branchType);
          } else {
            if (typeStatus === EIV_STATUS.NEW) {
              deleteExpert(row.id, branchType);
            } else {
              setSelectedId(row.id);
              setOpenDeletePopup(true);
            }
          }
        }}></ActionButton>
      <ActionButton
        inverted
        className={`${
          entryType !== 'guest' || edited || typeStatus === EIV_STATUS.NEW
            ? 'action-button-hidden'
            : ''
        } action-button-fixed-width`}
        title="Verlängern"
        onClick={() => {
          edit.current = true;
          setSelectedId(row.id);
          setPopupOpen(true);
        }}></ActionButton>
    </>
  );
};

TableActionButtons.propTypes = {
  row: PropTypes.object.isRequired,
  branchType: PropTypes.string.isRequired,
  entryType: PropTypes.string.isRequired,
  edited: PropTypes.bool.isRequired,
  resetExpert: PropTypes.func.isRequired,
  deleteExpert: PropTypes.func.isRequired,
  setSelectedId: PropTypes.func.isRequired,
  setOpenDeletePopup: PropTypes.func.isRequired,
  setPopupOpen: PropTypes.func.isRequired,
  edit: PropTypes.object.isRequired,
};

export { TableActionButtons };
