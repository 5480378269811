import React from 'react';
import PropTypes from 'prop-types';
import './ErrorBanner.sass';
const ErrorBanner = ({ children }) => {
  if (children) {
    return <div className="error-banner">{children}</div>;
  } else return <></>;
};
ErrorBanner.propTypes = {
  children: PropTypes.string,
};
export { ErrorBanner };
