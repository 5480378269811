import React from 'react';
import PropTypes from 'prop-types';
import { SectionTitle } from './SectionTitle';
import { SectionSubtitle } from './SectionSubtitle';
import { SectionMidTitle } from './SectionMidTitle';
import './FormSection.sass';
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip';
export function FormSection(props) {
  return (
    <div className={'form-section' + (props.isExtension ? '__extension' : '')}>
      {props.title && (
        <div className="form-section__title-container">
          <SectionTitle>{props.title}</SectionTitle>
          {props.titleTooltip && <InfoTooltip title={props.titleTooltip}></InfoTooltip>}
        </div>
      )}
      {props.midTitle && <SectionMidTitle>{props.midTitle}</SectionMidTitle>}

      {props.subtitle && (
        <div className="form-section__section-subtitle-container">
          <SectionSubtitle flex={props.tooltip || props.navigationTooltip}>
            {props.subtitle}
          </SectionSubtitle>
          {props.tooltip && <InfoTooltip title={props.tooltip}></InfoTooltip>}
          {props.navigationTooltip && props.navigationTooltip}
        </div>
      )}
      {props.infoText ? <div className="form-section__info-text">{props.infoText}</div> : null}
      {props.children}
    </div>
  );
}

FormSection.propTypes = {
  title: PropTypes.string || PropTypes.object,
  midTitle: PropTypes.string,
  subtitle: PropTypes.string,
  infoText: PropTypes.string || PropTypes.node,
  children: PropTypes.node,
  tooltip: PropTypes.node,
  titleTooltip: PropTypes.node,
  isExtension: PropTypes.bool,
  navigationTooltip: PropTypes.node,
};
