import { useQuery } from '@apollo/client';
import React from 'react';
import { ReactComponent as SVGKalender } from 'assets/pics/kalender.svg';
import { ReactComponent as SVGKalenderAva } from 'assets/pics/kalendar_avacon.svg';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import OuterBox from 'components/OuterBox/OuterBox';
import EventListGroup from './EventListGroup';
import './EventsCardLong.sass';
import { getTenantId } from 'utils/tenantUtil';
import { FORM_CONTANTS, TENANT_ID } from 'global/constants';
import { Separator } from 'components/Separator/Separator';
import { ActionButton } from '../ActionButton/ActionButton';
import { GET_USER_EVENTS_QUERY } from 'Queries/Queries';
import { getEventsOverviewPath } from 'utils/UrlUtil';

const getEventsCardText = (tenantId) => {
  switch (tenantId) {
    case TENANT_ID.EDI: {
      return {
        title: 'Ihre Veranstaltungen',
        text: 'Alle Veranstaltungen',
      };
    }
    case TENANT_ID.SHNG: {
      return {
        title: 'Ihre Veranstaltungen',
        text: 'Alle Veranstaltungen',
      };
    }
    case TENANT_ID.AVA: {
      return {
        title: 'Veranstaltungskalender',
        text: 'Alle Veranstaltungen',
      };
    }
    case TENANT_ID.BAG: {
      return {
        title: 'Veranstaltungskalender',
        text: 'Alle Veranstaltungen',
      };
    }
  }
};

const EventsCardLong = () => {
  const tenantId = getTenantId();
  const eventsCardText = getEventsCardText(getTenantId());

  const { loading, data } = useQuery(GET_USER_EVENTS_QUERY, {
    fetchPolicy: 'network-only',
  });

  const events = data?.myRegisteredEvents;

  const renderContent = () => {
    if (events?.length) {
      return <EventListGroup events={events} />;
    } else {
      return tenantId === TENANT_ID.AVA ? (
        <div>Sie haben sich bisher für keine Veranstaltung registriert!</div>
      ) : (
        <div>Sie haben sich bisher für kein Event registriert</div>
      );
    }
  };

  const separatorColor =
    tenantId === TENANT_ID.SHNG
      ? 'homepage'
      : tenantId === TENANT_ID.AVA
      ? 'homepage'
      : 'secondary';

  return (
    <OuterBox className="events-card__outer-box outer-box--p-spacing">
      <div className="events-card__teaser-container">
        <div className={`events-card__teaser-title`}>{eventsCardText.title}</div>
        <div>
          {tenantId === TENANT_ID.AVA ? (
            <SVGKalenderAva
              alt="Calendar icon"
              className="events-card__kalendar"
              width={FORM_CONTANTS.IMAGE_SIZE}
              height={FORM_CONTANTS.IMAGE_SIZE}
            />
          ) : (
            <SVGKalender
              alt="Calendar icon"
              className="events-card__kalendar"
              width={FORM_CONTANTS.IMAGE_SIZE}
              height={FORM_CONTANTS.IMAGE_SIZE}
            />
          )}
        </div>
      </div>
      <Separator color={separatorColor} />

      {loading ? (
        <LoadingIndicator className="events-card__loading-indicator" />
      ) : (
        <div className="events-card__content-container">{renderContent()}</div>
      )}

      <ActionButton link={getEventsOverviewPath()} title={eventsCardText.text} inverted />
    </OuterBox>
  );
};

export default EventsCardLong;
