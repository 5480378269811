/* eslint-disable react/prop-types */
import React from 'react';
import './css/CardComponents.sass';
import { getTenantId } from 'utils/tenantUtil';
import { TENANT_ID } from 'global/constants';

const CardComponent = ({ cardClass, card }) => {
  const tenantId = getTenantId();
  const additionalStyle =
    tenantId === TENANT_ID.SHNG ? { fontWeight: '500' } : { fontWeight: '400' };

  const renderContent = () =>
    card.teaser.elementsList.map((element, index) =>
      element.tel ? (
        <div key={index}>
          <i className="material-icons">chevron_right</i>
          <div>
            <a href={'tel:+49' + element.tel}>{element.tel}</a>
          </div>
        </div>
      ) : (
        <div key={index}>
          <i className="material-icons">chevron_right</i>
          <div style={additionalStyle}>
            <div>{element.name}</div>
          </div>
        </div>
      )
    );

  return (
    <div className={cardClass}>
      <div className="card-content-container">
        <div
          className={`teaser-title${tenantId === TENANT_ID.AVA ? ` ${TENANT_ID.AVA}` : ''}`}
          style={additionalStyle}>
          <div>{card.title}</div>
          <div className={`card-content-icon`}>{card.icon}</div>
        </div>

        <div className={`card-teaser-list ${tenantId}`}>{renderContent()}</div>
      </div>
    </div>
  );
};

export default CardComponent;
