/* eslint-disable react/prop-types */
import React from 'react';
import { useQuery } from '@apollo/client';
import '../ExpertSearch.sass';
import { ExpertSearchResult } from './ExpertSearchResult';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { GET_CONTACTS } from 'Queries/Queries';

const ExpertSearchDropdownExpert = ({ zip, city, district, tile, onReset }) => {
  const { loading, error, data } = useQuery(GET_CONTACTS, {
    variables: {
      zip: zip,
      city: city,
      district: district,
      tile: tile,
    },
  });

  if (loading) return <LoadingIndicator />;
  if (error) return <div>{'(!) ERROR (!)'}</div>;

  return data.contacts.length > 0 ? <ExpertSearchResult data={data} onReset={onReset} /> : null;
};

export { ExpertSearchDropdownExpert };
