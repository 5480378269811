import { BRANCH_TYPE, EIV_STATUS, TENANT_ID } from 'global/constants';
import { getEntryType } from './InstallerUtils';
import { getTenantId, GRIDNET_PROVIDER, isGridnetProviderOfCurrentTenant } from './tenantUtil';
import { parseEIVDateStringToEfixDateString } from './DatesUtils';
import { canReseal, parseMeterInstallation } from './DataUtils';

export const fetchFile = async (path) =>
  new Promise((resolve, reject) => {
    fetch(path)
      .then((response) => {
        if (!response.ok) {
          reject(new Error('Network response was not ok'));
        }
        return response.blob();
      })
      .then((fileBlob) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(fileBlob);
      })
      .catch((error) => {
        reject(new Error('There has been a problem with your fetch operation:', error));
      });
  });

export const fetchFont = async (path) => {
  return new Promise((resolve, reject) => {
    fetch(path)
      .then((response) => {
        if (!response.ok) {
          reject(new Error('Network response was not ok'));
        }
        response.arrayBuffer().then((arrayBuffer) => {
          resolve(arrayBuffer);
        });
      })
      .catch((error) => {
        reject(new Error('There has been a problem with your fetch operation:', error));
      });
  });
};

export const isDocumentDownloadElegible = (documentInformation, parsedData, selectedExpert) => {
  const { type, id: documentId, guest } = documentInformation;
  const {
    isEnergyActive: isEnergyActiveInstaller,
    isGasActive: isGasActiveInstaller,
    gridProviderEnergy,
    gridProviderGas,
    pflegendeBU,
  } = parsedData;

  const {
    isEnergyActive: isEnergyActiveExpert,
    energyValidityDate,
    isGasActive: isGasActiveExpert,
    gasValidityDate,
  } = selectedExpert;

  const isActiveAndValid = (isActiveInstaller, isActiveExpert, validityDate) =>
    isActiveInstaller && isActiveExpert && validityDate;

  const isGuestTypeEligible = (
    branchType,
    entryType,
    isActiveInstaller,
    isActiveExpert,
    validityDate
  ) =>
    type === branchType &&
    getEntryType(entryType) === 'guest' &&
    guest &&
    isActiveAndValid(isActiveInstaller, isActiveExpert, validityDate);

  const isMainTypeEligible = (
    branchType,
    entryType,
    isActiveInstaller,
    isActiveExpert,
    validityDate,
    gridProvider
  ) => {
    if (
      type === branchType &&
      getEntryType(entryType) === 'main' &&
      !guest &&
      isActiveAndValid(isActiveInstaller, isActiveExpert, validityDate)
    ) {
      if ([TENANT_ID.SHNG, TENANT_ID.AVA, TENANT_ID.BAG].includes(getTenantId())) {
        if (isGridnetProviderOfCurrentTenant(gridProvider.id)) {
          return gridProvider.id === documentId;
        } else {
          const mappedGridnetProvider = GRIDNET_PROVIDER.SHNG[pflegendeBU];
          return documentId === (mappedGridnetProvider || GRIDNET_PROVIDER.SHNG.SHNG);
        }
      }
      return true;
    }
    return false;
  };

  return (
    isGuestTypeEligible(
      BRANCH_TYPE.ENERGY,
      parsedData.entryTypeEnergy,
      isEnergyActiveInstaller,
      isEnergyActiveExpert,
      energyValidityDate
    ) ||
    isGuestTypeEligible(
      BRANCH_TYPE.GAS,
      parsedData.entryTypeGas,
      isGasActiveInstaller,
      isGasActiveExpert,
      gasValidityDate
    ) ||
    isMainTypeEligible(
      BRANCH_TYPE.ENERGY,
      parsedData.entryTypeEnergy,
      isEnergyActiveInstaller,
      isEnergyActiveExpert,
      energyValidityDate,
      gridProviderEnergy
    ) ||
    isMainTypeEligible(
      BRANCH_TYPE.GAS,
      parsedData.entryTypeGas,
      isGasActiveInstaller,
      isGasActiveExpert,
      gasValidityDate,
      gridProviderGas
    )
  );
};

export const prepareDocumentDownloadData = (installer, experts, contracts) => {
  const data = {
    companyName1: installer.firmenname1,
    companyName2: installer.firmenname2 || '',
    companyName3: installer.firmenname3 || '',
    companyId: `${installer.id}`,
    companyStreet: installer.buero_strasse,
    companyHouseNumber: installer.buero_hausnummer,
    companyPostal: installer.buero_plz,
    referenceNumberEnergy: installer.referenznummer_strom || '',
    companyCity: installer.buero_ort,
    companyTypeEnergy: installer.b_betriebsart_strom?.name || '',
    companyTypeGas: installer.b_betriebsart_gas?.name || '',
    meterInstallationGas: parseMeterInstallation(installer.zaehlermontage_gas),
    meterInstallationEnergy: parseMeterInstallation(installer.zaehlermontage_strom),
    gridProviderEnergy: installer.b_netzbetreiber_strom,
    gridProviderGas: installer.b_netzbetreiber_gas,
    entryTypeEnergy: installer.eintragungsart_strom,
    entryTypeGas: installer.eintragungsart_gas,
    pflegendeBU: installer.pflegende_bu,
    limitationGas: installer.eintragungsart_erg_gas || '',
    limitationEnergy: installer.eintragungsart_erg_strom || '',
    isEnergyActive: installer.status_strom === EIV_STATUS.ACTIVE,
    isGasActive: installer.status_gas === EIV_STATUS.ACTIVE,
    canReseal: canReseal(contracts),
    installers: experts
      .filter((expert) => {
        const isValidEnergyStatus =
          expert.status_strom === EIV_STATUS.ACTIVE || expert.status_strom === EIV_STATUS.INACTIVE;
        const isValidGasStatus =
          expert.status_gas === EIV_STATUS.ACTIVE || expert.status_gas === EIV_STATUS.INACTIVE;

        return isValidEnergyStatus || isValidGasStatus;
      })
      .map((expert) => ({
        firstname: expert.vorname,
        lastname: expert.nachname,
        isEnergyActive: expert.status_strom === EIV_STATUS.ACTIVE,
        isGasActive: expert.status_gas === EIV_STATUS.ACTIVE,
        energyValidityDate:
          expert.status_strom === EIV_STATUS.ACTIVE
            ? parseEIVDateStringToEfixDateString(
                expert.gueltig_bis_strom || installer.ausw_strom_gueltig || ''
              )
            : '',
        gasValidityDate:
          expert.status_gas === EIV_STATUS.ACTIVE
            ? parseEIVDateStringToEfixDateString(
                expert.gueltig_bis_gas || installer.ausw_gas_gueltig || ''
              )
            : '',
      })),
  };

  return data;
};
