import React from 'react';
import PropTypes from 'prop-types';
import './AgreementSection.sass';
import { CheckboxRow } from 'components/CheckboxRow/CheckboxRow';
import { BRANCH_TYPE } from 'global/constants';
import { canBranchRenderByStatus } from 'utils/InstallerUtils';

export function AgreementSection({
  energyAgreementLink,
  gasAgreementLink,
  installerStatusSummary,
  formik,
}) {
  return (
    <div className="agreement-section__wrapper">
      {canBranchRenderByStatus(installerStatusSummary, BRANCH_TYPE.ENERGY) && (
        <CheckboxRow
          name="energyAgreement"
          title={'Erklärungen zur Eintragung ins Elektro-I-Verzeichnis'}
          label={'Ich verpflichte mich folgendes verbindlich anzuerkennen*:'}
          documentLink={energyAgreementLink}
          value={formik.values.energyAgreement}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.energyAgreement)}></CheckboxRow>
      )}
      {canBranchRenderByStatus(installerStatusSummary, BRANCH_TYPE.GAS) && (
        <CheckboxRow
          name="gasAgreement"
          title={'Erklärungen zur Eintragung ins Gas-I-Verzeichnis'}
          label={'Ich verpflichte mich folgendes verbindlich anzuerkennen*:'}
          documentLink={gasAgreementLink}
          value={formik.values.gasAgreement}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.gasAgreement)}></CheckboxRow>
      )}
    </div>
  );
}
AgreementSection.propTypes = {
  energyAgreementLink: PropTypes.string,
  gasAgreementLink: PropTypes.string,
  installerStatusSummary: PropTypes.object,
  formik: PropTypes.object,
};
