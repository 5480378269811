import React from 'react';
import PropTypes from 'prop-types';

import '../../global/style/BaseTenantStyle.sass';
import '../../global/multiThemingStyling/multiClientGlobalVariables.scss';
import { CustomModal } from 'components/CustomModal';
import { ActionButton } from 'components/ActionButton/ActionButton';
import './DataPolicyPopup.sass';
import { getMyDataPath } from 'utils/UrlUtil';
export const DataPolicyPopup = ({ openSubmitPopup, setOpenSubmitPopup }) => {
  return (
    <CustomModal
      isOpen={openSubmitPopup}
      title="Antrag erfolgreich gesendet!"
      content={
        <div className="data-policy-popup__content">
          Ihre Änderung wurde in unserer Datenbank vorgenommen. Vielen Dank für die Aktualisierung.
        </div>
      }
      ctaButtons={
        <ActionButton
          title={<span className="data-policy-popup__cta-button-ok">OK</span>}
          link={getMyDataPath()}
          onClick={() => {
            setOpenSubmitPopup(false);
          }}
          inverted
        />
      }
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"></CustomModal>
  );
};

DataPolicyPopup.propTypes = {
  openSubmitPopup: PropTypes.bool,
  setOpenSubmitPopup: PropTypes.func,
};
