import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import './Table.sass';

const TableHeadStyled = withStyles(() => ({
  root: {
    backgroundColor: 'var(--grey-color)',
    borderRadius: '4px',
  },
}))(TableHead);

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 400,
    whiteSpace: 'nowrap',
  },
  thead: {
    font: 'var(--header-text)',
    backgroundColor: 'var(--mercury-color)',
    '& th:first-child': {
      borderTopLeftRadius: '4px',
    },
    '& th:last-child': {
      borderTopRightRadius: '4px',
    },
  },
}));

export function BasicTable({ headers, rows }) {
  const classes = useStyles();
  return rows.length <= 0 ? (
    <div>Hierzu gibt es bisher keine Einträge.</div>
  ) : (
    <TableContainer className="deb-table">
      <Table aria-label="simple table" className={classes.table}>
        <TableHeadStyled classes={{ root: classes.thead }}>
          <TableRow>
            {headers.map((header) => {
              return header;
            })}
          </TableRow>
        </TableHeadStyled>
        <TableBody>
          {rows.map((row) => {
            return row;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
BasicTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.node),
  rows: PropTypes.arrayOf(PropTypes.node),
};
