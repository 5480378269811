import { getTenantId } from 'utils/tenantUtil';
import { NE_BS } from './BranchSelectionData';
import { NE_EI } from './EmailInput';
import { NE_FD_CFG } from './FormDataConfigs';
import { isLocal } from 'utils/StageUtils';

const TEST_SUITES = (tenantId) => ({
  GasMain: {
    step0: NE_EI,
    step1: NE_BS.GasMain,
    step2: NE_FD_CFG.GasMain(tenantId),
    step3: {},
  },
  GasGuest: {
    step0: NE_EI,
    step1: NE_BS.GasGuest,
    step2: NE_FD_CFG.GasGuest(tenantId),
    step3: {},
  },
  EnergyMain: {
    step0: NE_EI,
    step1: NE_BS.EnergyMain,
    step2: NE_FD_CFG.EnergyMain(tenantId),
    step3: {},
  },
  EnergyGuest: {
    step0: NE_EI,
    step1: NE_BS.EnergyGuest,
    step2: NE_FD_CFG.EnergyGuest(tenantId),
    step3: {},
  },
  EnergyMainGasGuest: {
    step0: NE_EI,
    step1: NE_BS.EnergyMainGasGuest,
    step2: NE_FD_CFG.EnergyMainGasGuest(tenantId),
    step3: {},
  },
  EnergyGuestGasGuest: {
    step0: NE_EI,
    step1: NE_BS.EnergyGuestGasGuest,
    step2: NE_FD_CFG.EnergyGuestGasGuest(tenantId),
    step3: {},
  },
  EnergyGuestGasMain: {
    step0: NE_EI,
    step1: NE_BS.EnergyGuestGasMain,
    step2: NE_FD_CFG.EnergyGuestGasMain(tenantId),
    step3: {},
  },
  EnergyMainHilfsbetrieb: {
    step0: NE_EI,
    step1: NE_BS.EnergyMainHilfsbetrieb,
    step2: NE_FD_CFG.EnergyMainHilfsbetrieb(tenantId),
    step3: {},
  },
  GasMainHilfsbetrieb: {
    step0: NE_EI,
    step1: NE_BS.GasMainHilfsbetrieb,
    step2: NE_FD_CFG.GasMainHilfsbetrieb(tenantId),
    step3: {},
  },
  EnergyMainHilfsbetriebGasGuestHauptbetrieb: {
    step0: NE_EI,
    step1: NE_BS.EnergyMainHilfsbetriebGasGuestHauptbetrieb,
    step2: NE_FD_CFG.EnergyMainHilfsbetriebGasGuestHauptbetrieb(tenantId),
    step3: {},
  },
  EnergyMainHauptbetriebGasGuestHilfsbetrieb: {
    step0: NE_EI,
    step1: NE_BS.EnergyMainHauptbetriebGasGuestHilfsbetrieb,
    step2: NE_FD_CFG.EnergyMainGasGuest(tenantId),
    step3: {},
  },
});

const shouldFillTestData = () => {
  return isLocal();
};

const CURRENT_TEST_SUITE = TEST_SUITES(getTenantId()).EnergyMainHilfsbetriebGasGuestHauptbetrieb;
if (shouldFillTestData()) {
  console.log('Current Testsuite:', getTenantId(), CURRENT_TEST_SUITE);
}

export { CURRENT_TEST_SUITE, shouldFillTestData };
