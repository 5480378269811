import { isLocal } from 'utils/StageUtils';
import { avaconGuestBothFormData, avaconGuestBothInstallerData } from '../Testing/Avacon/GuestBoth';
import { bagEnergyMainFormData, bagGasMainInstallerData } from '../Testing/Bag/EnergyMain';
import { edisEnergyGuestFormData, edisEnergyGuestInstallerData } from '../Testing/Edis/Edis';
import {
  shngEnergyGuestGasMainFormData,
  shngEnergyGuestGasMainInstallerData,
} from '../Testing/Shng/EnergyGuestGasMain';
import {
  energyMainHilfsbetriebGasGuestHauptbetriebFormData,
  energyMainHilfsbetriebGasGuestHauptbetriebInstallerData,
} from '../Testing/Bag/EnergyMainHilfsbetriebGasGuestHauptbetrieb';

const TEST_SUITES = {
  avaconGuestBoth: {
    formData: avaconGuestBothFormData,
    installerData: avaconGuestBothInstallerData,
  },
  bagGasMain: {
    formData: bagEnergyMainFormData,
    installerData: bagGasMainInstallerData,
  },
  bagEnergyMainHilfsbertriebGasGuestHauptbetrieb: {
    formData: energyMainHilfsbetriebGasGuestHauptbetriebFormData,
    installerData: energyMainHilfsbetriebGasGuestHauptbetriebInstallerData,
  },
  edisEnergyGuest: {
    formData: edisEnergyGuestFormData,
    installerData: edisEnergyGuestInstallerData,
  },
  shngEnergyGuestGasMain: {
    formData: shngEnergyGuestGasMainFormData,
    installerData: shngEnergyGuestGasMainInstallerData,
  },
};

const shouldFillTestData = () => {
  return isLocal();
};

// const DEB_CURRENT_TEST_SUITE = TEST_SUITES.avaconGuestBoth;
const DEB_CURRENT_TEST_SUITE = TEST_SUITES.bagGasMain;

export { DEB_CURRENT_TEST_SUITE, shouldFillTestData };
