import { clearCookies } from './CookieUtils';
import { getLogoutRedirectUrl } from './UrlUtil';

export const isTokenExpired = (token) => {
  if (!token) return true;
  const decodedJwt = parseJwt(token);
  return decodedJwt.exp * 1000 < Date.now();
};

const isBase64Url = (str) => {
  return /^[A-Za-z0-9_-]+$/.test(str);
};

const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    if (!base64Url) return null;

    let base64 = base64Url;
    if (isBase64Url(base64Url)) {
      base64 =
        base64Url.replace(/-/g, '+').replace(/_/g, '/') +
        '=='.substring(0, (3 * base64Url.length) % 4);
    }
    const decodedJwt = JSON.parse(atob(base64));

    return decodedJwt;
  } catch (error) {
    console.error('Error parsing JWT:', error);
    return null;
  }
};

export const parseAccessToken = (hash) => {
  const TokenRegex = /\b(\w+|-)\.(\w+|-).\.(\w+|-)+/;
  const accessAndIdToken = TokenRegex.exec(hash);
  if (!accessAndIdToken) {
    return '';
  } else {
    return accessAndIdToken[0];
  }
};

export const logoutWithRedirect = (isCIAM) => {
  localStorage.clear();
  clearCookies();
  window.location.href = getLogoutRedirectUrl(isCIAM);
};
