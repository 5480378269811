/* eslint-disable react/prop-types */
import { getInstallerInfo } from 'api/installerInfo/GetInstallerInfo';
import React, { useState, createContext, useEffect, useMemo, useContext } from 'react';
import { useAuth } from 'context/Auth';
import { USER_STATUS_QUERY } from 'Queries/Queries';
import { useMutation, useQuery } from '@apollo/client';
import { SET_INSTALLER_ID } from 'Queries/Mutations';
import { ErrorHandlingContext } from './ErrorHandling';

const InstallerInfo = createContext({});

export const useInstallerInfo = () => {
  return useContext(InstallerInfo);
};

export const InstallerInfoContext = ({ children }) => {
  const { setShowError } = useContext(ErrorHandlingContext);
  const { token, isAuthenticated } = useAuth();
  const [installerInfo, setInstallerInfo] = useState(null);
  const { data: userStatusData, loading } = useQuery(USER_STATUS_QUERY);
  const [setInstallerId] = useMutation(SET_INSTALLER_ID);
  const [isEIV, setIsEIV] = useState(undefined);

  useEffect(() => {
    if (!loading) {
      if (userStatusData?.userStatus?.iamType === 'CIAM') {
        setIsEIV(true);
        if (isAuthenticated && token) {
          getInstallerInfo(token, setShowError).then((response) => {
            setInstallerInfo(response);
            if (response && response.id) {
              setInstallerId({ variables: { id: response.id } });
            }
          });
        }
      } else {
        setIsEIV(false);
      }
    }
  }, [loading, userStatusData, token, isAuthenticated]);

  const contextValue = useMemo(
    () => ({
      installerInfo,
      isEIV,
    }),
    [installerInfo, userStatusData, isEIV]
  );

  return <InstallerInfo.Provider value={contextValue}>{children}</InstallerInfo.Provider>;
};
