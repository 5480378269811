import { EIV_ENDPOINT } from '../APIUtils';
import { getAxiosInstance } from '../InitAxios';

export const getInstallerById = async (id, setShowError) => {
  const axiosInstance = getAxiosInstance();
  return axiosInstance
    .get(EIV_ENDPOINT.INSTALLER_BY_ID(id))
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      setShowError(true);
    });
};
