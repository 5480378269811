import React, { useEffect } from 'react';
function usePersistedState(key, defaultValue) {
  const [state, setState] = React.useState(
    localStorage.getItem(key) === 'true'
      ? true
      : localStorage.getItem(key) === 'false'
      ? false
      : localStorage.getItem(key) || defaultValue
  );
  useEffect(() => {
    localStorage.setItem(key, state);
  }, [key, state]);
  return [state, setState];
}

export default usePersistedState;
