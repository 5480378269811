/* eslint-disable react/prop-types */
import classnames from 'classnames';
import React from 'react';
import './OuterBox.scss';

const OuterBox = ({ children, className }) => {
  const classes = classnames('outer-box', {
    [`${className}`]: !!className,
  });
  return <div className={classes}>{children}</div>;
};

export default OuterBox;
