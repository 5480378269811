import React, { useEffect } from 'react';
import { MapTo } from '@adobe/aem-react-editable-components';
import DataItem from '../../../DataItem/DataItem';
import './ServiceInstallerComponent.sass';

const DATA = {
  contactDetails: [
    {
      type: 'tel',
      data: {
        value: '+49 4102 494-2267',
        name: 'Julia Wendrich',
      },
    },
    {
      type: 'tel',
      data: {
        value: '+49 4106 629-3632',
        name: 'Petra Jarchow',
      },
    },
    {
      type: 'email',
      data: {
        value: 'Installateurverzeichnis-strom@sh-netz.com',
      },
    },
    {
      type: 'email',
      data: {
        value: 'Installateurverzeichnis@nordnetz.com',
      },
    },
    {
      type: 'email',
      data: {
        value: 'Installateurverzeichnis-gas@sh-netz.com',
      },
    },
    {
      type: 'email',
      data: {
        value: 'Installateurverzeichnis-gas@elbenergie.com',
      },
    },
    {
      type: 'email',
      data: {
        value: 'Installateurverzeichnis-gas@hansegas.com',
      },
    },
  ],
};

const ServiceInstallerComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className=" expert-page">
      <div className="expert-page-content-stage-text">
        <div className="service-installer-data-item-wrapper">
          {DATA.contactDetails.map((value) => (
            <DataItem key={value} data={value} />
          ))}
        </div>
      </div>
    </div>
  );
};

MapTo(['e-fix/components/content/shnginstallationservice'])(ServiceInstallerComponent);
