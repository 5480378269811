/* eslint-disable react/prop-types */
import React from 'react';

import './ExpertSearch.sass';
import CardComponent from '../CardComponent';
import { Link } from 'react-router-dom';
import { sortMetaNav } from '../../utils/CardDataUtils';
import { MapTo, Utils } from '@adobe/aem-react-editable-components';
import { RenderAemComponent } from 'components/RenderAemComponent/RenderAemComponent';

const CardCollection = (props) => {
  const isEditor = Utils.isInEditor();

  if (isEditor) {
    return <RenderAemComponent componentProps={props} name="CardCollection" />;
  }
  if (!props.metaNav) {
    return <div>Keine Links vorhanden.</div>;
  }

  const parsedCards = sortMetaNav(props.metaNav.links);

  return (
    <div className="expert-page-content-sites">
      <div className="mdl-grid search-pre-section-card-grid">
        {parsedCards.map((card, index) => (
          <Link key={index} to={card.linkedPage}>
            <CardComponent
              cardClass="search-pre-selection-card mdl-cell mdl-cell--4-col"
              card={card}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

MapTo('e-fix/components/content/cardcollection')(CardCollection);
