import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ExpertTableGas } from './ExpertTableGas';
import { ExpertTableEnergy } from './ExpertTableEnergy';
import { FormSection } from '../../FormHelper/FormSection';
import {
  BRANCH_TYPE,
  EIV_ENTRY_TYPE_TO_GERMAN_NAME,
  EIV_STATUS,
  EIV_STATUS_ALLOW_RENDER,
  EIV_STATUS_TO_SHOW,
} from 'global/constants';
import { getInstallerStatusSummary } from 'utils/InstallerUtils';
export function ExpertTable({ data, oldData, formik, installer }) {
  const [selectedRow, setSelectedRow] = useState(null);
  const [energyType, setEnergyType] = useState(null);
  const [gasType, setGasType] = useState(null);
  const [energyStatus, setEnergyStatus] = useState(null);
  const [gasStatus, setGasStatus] = useState(null);

  useEffect(() => {
    if (installer) {
      const installerStatusSummary = getInstallerStatusSummary(installer);
      setEnergyType(installerStatusSummary[BRANCH_TYPE.ENERGY].entryType);
      setGasType(installerStatusSummary[BRANCH_TYPE.GAS].entryType);
      setEnergyStatus(installerStatusSummary[BRANCH_TYPE.ENERGY].status);
      setGasStatus(installerStatusSummary[BRANCH_TYPE.GAS].status);
    }
  }, [installer]);

  const deleteExpert = (id, branch) => {
    const newData = [...data];
    const newExperts = [];
    newData.forEach((expert) => {
      if (expert) {
        if (id === expert.id) {
          const oldExpert = { ...oldData.find((expert) => expert.id === id) };
          if (branch === BRANCH_TYPE.GAS) {
            if (expert.statusGas !== EIV_STATUS.NEW) {
              newExperts.push({
                ...expert,
                expertGasValidityDate: oldExpert.expertGasValidityDate,
                statusGas: EIV_STATUS.DELETED,
              });
            }
          } else {
            if (expert.statusEnergy !== EIV_STATUS.NEW) {
              newExperts.push({
                ...expert,
                expertEnergyValidityDate: oldExpert.expertEnergyValidityDate,
                statusEnergy: EIV_STATUS.DELETED,
              });
            }
          }
        } else {
          newExperts.push({ ...expert });
        }
      }
    });
    setSelectedRow(null);
    formik.setFieldValue('experts', newExperts);
  };

  const resetExpert = (id, branch) => {
    const newExperts = data.map((expert) => {
      if (id === expert.id) {
        const oldExpert = { ...oldData.find((expert) => expert.id === id) };
        if (branch === BRANCH_TYPE.GAS) {
          if (expert.statusGas !== EIV_STATUS.NEW) {
            return {
              ...oldExpert,
              statusEnergy: expert.statusEnergy,
              deletionDateEnergy: expert.deletionDateEnergy,
              deletionReasonEnergy: expert.deletionReasonEnergy,
            };
          }
        } else {
          if (expert.statusEnergy !== EIV_STATUS.NEW) {
            return {
              ...oldExpert,
              statusGas: expert.statusGas,
              deletionReasonGas: expert.deletionReasonGas,
              deletionDateGas: expert.deletionDateGas,
            };
          }
        }
        return;
      } else {
        return { ...expert };
      }
    });
    formik.setFieldValue('experts', newExperts);
  };

  const setSelectedId = (id) => {
    if (data.length) {
      let newExpertIndex = null;
      data.forEach((expert, i) => {
        if (expert) {
          if (expert.id === id) {
            newExpertIndex = i;
            return;
          }
        }
      });
      if (newExpertIndex != null) setSelectedRow(newExpertIndex);
    }
  };

  const gasExpert = useMemo(
    () =>
      data.filter((expert) => {
        if (expert && EIV_STATUS_TO_SHOW.includes(expert.statusGas)) {
          return expert;
        }
      }),
    [data]
  );

  const energyExpert = useMemo(
    () =>
      data.filter((expert) => {
        if (expert && EIV_STATUS_TO_SHOW.includes(expert.statusEnergy)) {
          return expert;
        }
      }),
    [data]
  );
  const allowedTypes = ['main', 'guest'];
  return (
    <div className="expert-table__wrapper">
      {allowedTypes.includes(energyType) && EIV_STATUS_ALLOW_RENDER.includes(energyStatus) && (
        <>
          <FormSection
            title={`Fachkräfte Sparte Strom - ${EIV_ENTRY_TYPE_TO_GERMAN_NAME[energyType]}eintragung`}>
            <ExpertTableEnergy
              data={energyExpert ? energyExpert : []}
              oldData={oldData}
              formik={formik}
              selectedRow={selectedRow}
              setSelectedId={setSelectedId}
              deleteExpert={deleteExpert}
              resetExpert={resetExpert}
              gasType={gasType}
              energyType={energyType}></ExpertTableEnergy>
          </FormSection>
        </>
      )}

      {allowedTypes.includes(gasType) && EIV_STATUS_ALLOW_RENDER.includes(gasStatus) && (
        <>
          <FormSection
            title={`Fachkräfte Sparte Gas - ${EIV_ENTRY_TYPE_TO_GERMAN_NAME[gasType]}eintragung`}>
            <ExpertTableGas
              data={gasExpert ? gasExpert : []}
              oldData={oldData}
              formik={formik}
              selectedRow={selectedRow}
              setSelectedId={setSelectedId}
              deleteExpert={deleteExpert}
              resetExpert={resetExpert}
              gasType={gasType}
              energyType={energyType}></ExpertTableGas>
          </FormSection>
        </>
      )}
    </div>
  );
}
ExpertTable.propTypes = {
  data: PropTypes.array,
  oldData: PropTypes.array,
  installer: PropTypes.object,
  formik: PropTypes.object,
};
