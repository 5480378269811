import * as Yup from 'yup';
import { YDate, YPastDate } from 'utils/YupUtils';
import { EIV_CHAR_LENGTH, VALIDATION_CONSTANTS } from 'global/constants';

export const YSeals = Yup.object().shape({
  isEditingLostData: Yup.boolean(),
  lostDate: YDate.when('isEditingLostData', {
    is: (isEditingLostData) => isEditingLostData === true,
    then: YPastDate.required(VALIDATION_CONSTANTS.MANDATORY),
  }),
  lostReason: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.STANDARD_TEXT, VALIDATION_CONSTANTS.TOO_LONG),
});
