import React from 'react';

function PlusIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 4.1665V15.8332" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.16699 10H15.8337" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default PlusIcon;
