/* eslint-disable react/prop-types */
import React from 'react';
import './css/GenericInfoPage.scss';
import Headline from './Headline/Headline';
import TeaserText from './TeaserText/TeaserText';

export default function GenericInfo({ data }) {
  const emailRegex = /\S+@\S+\.\S+/;
  if (Object.keys(data).length && data.text) {
    return (
      <div className="generic-info-component">
        {data.title && <Headline variant="title" text={data.title} />}
        {data.paragraphTitleH2 && <Headline tagName="h2" text={data.paragraphTitleH2} />}
        {data.paragraphTitleH5 && <Headline tagName="h2" text={data.paragraphTitleH5} />}
        {data.text.map((paragraph, key) =>
          paragraph.match(emailRegex) ? (
            <a
              className="link generic-info-component__link"
              key={key}
              href={`mailto: ${paragraph}`}>
              {paragraph}
            </a>
          ) : (
            <TeaserText key={key} text={paragraph} />
          )
        )}
      </div>
    );
  } else {
    return null;
  }
}
