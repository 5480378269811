import { getStage, isLocal } from './StageUtils';

const setCookie = (name, value, days) => {
  let domain = '.e-fix.info';
  if (isLocal()) {
    domain = 'localhost';
  }
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  const stage = getStage();
  const stageName = stage ? `${stage}_` : '';
  document.cookie = `${stageName}${name}=${value || ''}${expires}; path=/; domain=${domain}`;
};

const getCookie = (name) => {
  const stage = getStage();
  const stageName = stage ? `${stage}_` : '';
  const nameEQ = `${stageName}${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const clearCookies = () => {
  const stage = getStage();
  const stageName = stage ? `${stage}_` : '';
  const domain = isLocal() ? 'localhost' : '.e-fix.info';
  document.cookie.split(';').forEach((cookie) => {
    const name = cookie.split('=')[0].trim();
    if (name.startsWith(stageName)) {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${domain}`;
    }
  });
};

export { setCookie, getCookie, clearCookies };
