import {
  NE_FIRST_SECTION_INPUTS,
  shouldNeFirstPageSectionFieldRender,
  shouldNeFirstPageSectionRender,
} from 'components/NEComponents/NESteps/NEStepForm/NEFormUtility';
import {
  BRANCH_TYPE,
  EIV_ENTRY_TYPE,
  EIV_ENTRY_TYPE_TO_GERMAN,
  INSTALLER_ENTRIES,
  KEYS_TO_GERMAN,
  TENANT_ID,
} from 'global/constants';
import { branchToCapital, prepValueForEmail } from 'utils/DataUtils';
import { prepDocumentForMutation } from 'utils/FileUtil';
import { getTenantId } from 'utils/tenantUtil';

export const prepNEDataForBackend = (
  firstPageValues,
  secondPageValues,
  subject,
  documents,
  branchSelectionValues
) => {
  const formDataEntries = [];
  const expertEntries = prepNEExperts(firstPageValues, branchSelectionValues);

  NE_FIRST_SECTION_INPUTS.forEach((section) => {
    if (
      section.id !== 'avaExtraInfo' &&
      shouldNeFirstPageSectionRender(section.id, branchSelectionValues, getTenantId())
    ) {
      section.fields?.forEach((field) => {
        if (
          field.name !== 'email' &&
          shouldNeFirstPageSectionFieldRender(field.name, branchSelectionValues)
        ) {
          formDataEntries.push({
            first: KEYS_TO_GERMAN[field.name],
            second: prepValueForEmail(firstPageValues[field.name]),
          });
        }
      });
    }
  });

  const documentEntries = documents.map(prepDocumentForMutation);

  const hasEnergyRow = isInstallerRegisteringForBranch(BRANCH_TYPE.ENERGY, branchSelectionValues)
    ? 'Strom'
    : '';
  const hasGasRow = isInstallerRegisteringForBranch(BRANCH_TYPE.GAS, branchSelectionValues)
    ? 'Gas'
    : '';
  const activeBranches = [hasEnergyRow, hasGasRow].filter((branch) => branch).join(' ');

  const baseDataEntries = [
    { first: 'Email', second: prepValueForEmail(firstPageValues.email) },
    { first: 'Sparte', second: prepValueForEmail(activeBranches) },
    ...(hasEnergyRow
      ? [
          {
            first: 'Strom',
            second: prepValueForEmail(
              EIV_ENTRY_TYPE_TO_GERMAN[branchSelectionValues.branchTypeEnergy]
            ),
          },
          {
            first: 'Betriebsart Strom',
            second: prepValueForEmail(branchSelectionValues.companyTypeEnergy),
          },
        ]
      : []),
    ...(hasGasRow
      ? [
          {
            first: 'Gas',
            second: prepValueForEmail(
              EIV_ENTRY_TYPE_TO_GERMAN[branchSelectionValues.branchTypeGas]
            ),
          },
          {
            first: 'Betriebsart Gas',
            second: branchSelectionValues.companyTypeGas,
          },
        ]
      : []),
    { first: 'Anmerkung des Installateurs', second: prepValueForEmail(secondPageValues.changes) },
  ];

  const agreementEntries = [
    {
      first: 'Adressveröffentlichung',
      second: prepValueForEmail(secondPageValues.isAddressPublic),
    },
    { first: 'Infoversand E-Mail', second: prepValueForEmail(secondPageValues.canSendMail) },
  ];

  if (getTenantId() === TENANT_ID.AVA) {
    baseDataEntries.push({
      first: 'PLZ Auftragsort',
      second: firstPageValues.doesNeedAvaGuestPostal
        ? firstPageValues.avaGuestPostal
        : firstPageValues.companyPostal,
    });
  }

  return {
    email: firstPageValues.email,
    subject,
    baseDataEntries,
    formDataEntries,
    expertEntries,
    documents: documentEntries,
    agreementEntries,
  };
};

export const createEmailSubjectForNE = (installerEntry, branchSelectionValues) => {
  let subject = '[e-fix] ';
  switch (installerEntry) {
    case INSTALLER_ENTRIES.MAIN_BOTH:
      subject += 'Strom Gas - Neu - Stamm';
      break;
    case INSTALLER_ENTRIES.MAIN_ENERGY_GUEST_GAS:
      subject += 'Strom Gas - Neu - Stamm Gast';
      break;
    case INSTALLER_ENTRIES.GUEST_ENERGY_MAIN_GAS:
      subject += 'Strom Gas - Neu - Gast Stamm';
      break;
    case INSTALLER_ENTRIES.MAIN_ENERGY_ONLY:
      subject += 'Strom - Neu - Stamm';
      break;
    case INSTALLER_ENTRIES.MAIN_GAS_ONLY:
      subject += 'Gas - Neu - Stamm';
      break;
    case INSTALLER_ENTRIES.GUEST_ONLY: {
      const hasEnergyRow = isInstallerRegisteringForBranch(
        BRANCH_TYPE.ENERGY,
        branchSelectionValues
      );
      const hasGasRow = isInstallerRegisteringForBranch(BRANCH_TYPE.GAS, branchSelectionValues);
      if (hasEnergyRow && hasGasRow) {
        subject += 'Strom Gas - Neu - Gast';
      } else if (hasEnergyRow) {
        subject += 'Strom - Neu - Gast';
      } else if (hasGasRow) {
        subject += 'Gas - Neu - Gast';
      }
      break;
    }
    default:
      subject = '';
      break;
  }

  return subject;
};

const prepNEExperts = (firstPageValues, branchSelectionValues) => {
  const experts = [...firstPageValues.experts];
  const expertEntries = [];
  const isEnergyGuest = isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, branchSelectionValues);
  const isGasGuest = isRegisteringBranchGuest(BRANCH_TYPE.GAS, branchSelectionValues);

  experts.forEach((expert) => {
    const hasMainBranch =
      (expert.isExpertEnergyActive && !isEnergyGuest) || (expert.isExpertGasActive && !isGasGuest);
    expertEntries.push([
      ...(expert.isExpertEnergyActive
        ? [
            {
              first: 'Sparte Strom',
              second: prepValueForEmail(expert.isExpertEnergyActive),
            },
          ]
        : []),
      ...(expert.isExpertGasActive
        ? [
            {
              first: 'Sparte Gas',
              second: prepValueForEmail(expert.isExpertGasActive),
            },
          ]
        : []),
      {
        first: 'Vorname',
        second: prepValueForEmail(expert.firstName),
      },
      {
        first: 'Nachname',
        second: prepValueForEmail(expert.lastName),
      },
      ...(expert.isExpertEnergyActive && isEnergyGuest
        ? [
            {
              first: 'Stromausweis Gültigkeitsdatum',
              second: prepValueForEmail(expert.energyValidityDate),
            },
          ]
        : []),
      ...(expert.isExpertGasActive && isGasGuest
        ? [
            {
              first: 'Gasausweis Gültigkeitsdatum',
              second: prepValueForEmail(expert.gasValidityDate),
            },
          ]
        : []),
      ...(hasMainBranch
        ? [
            {
              first: 'Inhaber',
              second: prepValueForEmail(expert.isExpertOwner),
            },
          ]
        : []),
      ...(getTenantId() === TENANT_ID.BAG
        ? [
            {
              first: 'Geburtsdatum',
              second: prepValueForEmail(expert.birthdate),
            },
          ]
        : []),
    ]);
  });
  return expertEntries;
};

export const getInstallerEntryByBranchSelection = (branchSelectionValues) => {
  if (
    isRegisteringBranchMain(BRANCH_TYPE.ENERGY, branchSelectionValues) &&
    isRegisteringBranchMain(BRANCH_TYPE.GAS, branchSelectionValues)
  ) {
    return INSTALLER_ENTRIES.MAIN_BOTH;
  } else if (
    isRegisteringBranchMain(BRANCH_TYPE.ENERGY, branchSelectionValues) &&
    isRegisteringBranchGuest(BRANCH_TYPE.GAS, branchSelectionValues)
  ) {
    return INSTALLER_ENTRIES.MAIN_ENERGY_GUEST_GAS;
  } else if (
    isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, branchSelectionValues) &&
    isRegisteringBranchMain(BRANCH_TYPE.GAS, branchSelectionValues)
  ) {
    return INSTALLER_ENTRIES.GUEST_ENERGY_MAIN_GAS;
  } else if (
    isRegisteringBranchMain(BRANCH_TYPE.ENERGY, branchSelectionValues) &&
    !isInstallerRegisteringForBranch(BRANCH_TYPE.GAS, branchSelectionValues)
  ) {
    return INSTALLER_ENTRIES.MAIN_ENERGY_ONLY;
  } else if (
    !isInstallerRegisteringForBranch(BRANCH_TYPE.ENERGY, branchSelectionValues) &&
    isRegisteringBranchMain(BRANCH_TYPE.GAS, branchSelectionValues)
  ) {
    return INSTALLER_ENTRIES.MAIN_GAS_ONLY;
  } else if (
    isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, branchSelectionValues) ||
    isRegisteringBranchGuest(BRANCH_TYPE.GAS, branchSelectionValues)
  ) {
    return INSTALLER_ENTRIES.GUEST_ONLY;
  } else {
    return '';
  }
};

export const getInstallerEntryByExpert = (expert, branchSelectionValues) => {
  if (expert.isExpertEnergyActive && expert.isExpertGasActive) {
    if (
      isRegisteringBranchMain(BRANCH_TYPE.ENERGY, branchSelectionValues) &&
      isRegisteringBranchMain(BRANCH_TYPE.GAS, branchSelectionValues)
    ) {
      return INSTALLER_ENTRIES.MAIN_BOTH;
    } else if (
      isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, branchSelectionValues) &&
      isRegisteringBranchMain(BRANCH_TYPE.GAS, branchSelectionValues)
    ) {
      return INSTALLER_ENTRIES.GUEST_ENERGY_MAIN_GAS;
    } else if (
      isRegisteringBranchMain(BRANCH_TYPE.ENERGY, branchSelectionValues) &&
      isRegisteringBranchGuest(BRANCH_TYPE.GAS, branchSelectionValues)
    ) {
      return INSTALLER_ENTRIES.MAIN_ENERGY_GUEST_GAS;
    } else if (
      isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, branchSelectionValues) &&
      isRegisteringBranchGuest(BRANCH_TYPE.GAS, branchSelectionValues)
    ) {
      return INSTALLER_ENTRIES.GUEST_ONLY;
    }
    return '';
  } else if (expert.isExpertEnergyActive) {
    if (isRegisteringBranchMain(BRANCH_TYPE.ENERGY, branchSelectionValues)) {
      return INSTALLER_ENTRIES.MAIN_ENERGY_ONLY;
    } else {
      return INSTALLER_ENTRIES.GUEST_ONLY;
    }
  } else if (expert.isExpertGasActive) {
    if (isRegisteringBranchMain(BRANCH_TYPE.GAS, branchSelectionValues)) {
      return INSTALLER_ENTRIES.MAIN_GAS_ONLY;
    } else {
      return INSTALLER_ENTRIES.GUEST_ONLY;
    }
  } else {
    return '';
  }
};

export const isInstallerRegisteringForBranch = (branch, branchSelectionValues) => {
  return branchSelectionValues[`is${branchToCapital(branch)}Active`];
};

export const isRegisteringBranchGuest = (branch, branchSelectionValues) => {
  return branchSelectionValues[`branchType${branchToCapital(branch)}`] === EIV_ENTRY_TYPE.GUEST;
};

export const isRegisteringBranchMain = (branch, branchSelectionValues) => {
  return branchSelectionValues[`branchType${branchToCapital(branch)}`] === EIV_ENTRY_TYPE.MAIN;
};

export const isGuestOnly = (branchSelectionValues) => {
  return (
    (isInstallerRegisteringForBranch(BRANCH_TYPE.ENERGY, branchSelectionValues)
      ? isRegisteringBranchGuest(BRANCH_TYPE.ENERGY, branchSelectionValues)
      : true) &&
    (isInstallerRegisteringForBranch(BRANCH_TYPE.GAS, branchSelectionValues)
      ? isRegisteringBranchGuest(BRANCH_TYPE.GAS, branchSelectionValues)
      : true)
  );
};

export const isBranchCompanyTypeHilfsbetrieb = (branch, branchSelectionValues) => {
  return branchSelectionValues[`companyType${branchToCapital(branch)}`] === 'Hilfsbetrieb';
};

export const isInstallerRegisteringOnlyForBranch = (branch, branchSelectionValues) => {
  const otherBranch = branch === BRANCH_TYPE.ENERGY ? BRANCH_TYPE.GAS : BRANCH_TYPE.ENERGY;
  return (
    isInstallerRegisteringForBranch(branch, branchSelectionValues) &&
    !isInstallerRegisteringForBranch(otherBranch, branchSelectionValues)
  );
};
