import React from 'react';
import PropTypes from 'prop-types';
import './SectionSubtitle.sass';

export function SectionSubtitle({ children, flex }) {
  return (
    <div className={`section-subtitle  ${flex ? 'section-subtitle__flex' : ''}`}>{children}</div>
  );
}

SectionSubtitle.propTypes = {
  children: PropTypes.node,
  flex: PropTypes.bool,
};
