import React from 'react';

import './ContactDetails.sass';
import { MapTo } from '@adobe/aem-react-editable-components';

export const ContactDetails = () => {
  return <div>ContactDetails</div>;
};

ContactDetails.displayName = 'ContactDetails';

ContactDetails.propTypes = {};

ContactDetails.defaultProps = {};

MapTo(['e-fix/components/content/contactdetails'])(ContactDetails);
