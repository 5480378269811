import React, { useMemo } from 'react';
import './LabelCheckbox.sass';
import PropTypes from 'prop-types';
import { CustomCheckbox } from 'components/CustomCheckbox/CustomCheckbox';
import { InfoTooltip } from 'components/InfoTooltip/InfoTooltip';

const LabelCheckbox = ({
  label,
  id,
  value,
  onChange,
  disabled,
  icon,
  isEdited,
  name,
  tooltipText,
  error,
}) => {
  const getClassnameForError = useMemo(() => {
    if (error) return 'error';
    return '';
  }, [error]);

  return (
    <div className="label-checkbox">
      <CustomCheckbox
        id={id}
        value={value}
        onChange={onChange}
        disabled={disabled}
        name={name}
        isEdited={isEdited}
        tooltipText={tooltipText}
        error={error}
      />
      {icon && <img src={icon} alt="label-checkbox-icon" />}
      {label && (
        <div id={id} className={getClassnameForError}>
          {label}
        </div>
      )}
      {tooltipText && <InfoTooltip title={tooltipText}></InfoTooltip>}
    </div>
  );
};

LabelCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  isEdited: PropTypes.bool,
  name: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  error: PropTypes.bool,
};

export { LabelCheckbox };
