import { AssetDownloadItem } from 'components/AssetDownloadItem/AssetDownloadItem';
import React from 'react';
import { getTenantId } from 'utils/tenantUtil';
import PropTypes from 'prop-types';

import { FormSection } from 'components/FormHelper/FormSection';
import { CheckboxRow } from 'components/CheckboxRow/CheckboxRow';
import { TENANT_ID } from 'global/constants';

const CONTENT = {
  [TENANT_ID.EDI]: {
    INTRO_TEXT: 'Ich bin / wir sind damit einverstanden, dass die E.DIS Netz GmbH',
    APPROVAL_TEXTS: [
      'über gesetzliche, technische oder organisatorische Neuerungen sowie fachbezogene Veranstaltungen und das Installateurportal informiert [Info-Versand per E-Mail]',
      'meine / unsere im Installateurverzeichnis gespeicherten Kontaktdaten (Name, Adresse, Telefonnummer, E-Mail-Adresse) in der Installateur-Suche für Kunden auf der E.DIS Netz GmbH Homepage und im Netzanschlussportal veröffentlicht',
    ],
  },
  [TENANT_ID.SHNG]: {
    INTRO_TEXT: 'Ich bin / wir sind damit einverstanden, dass die Schleswig-Holstein Netz GmbH',

    APPROVAL_TEXTS: [
      'über gesetzliche, technische oder organisatorische Neuerungen sowie fachbezogene Veranstaltungen und das Installateurportal informiert [Info-Versand per E-Mail]',
      'meine / unsere im Installateurverzeichnis gespeicherten Kontaktdaten (Name, Adresse, Telefonnummer, E-Mail-Adresse) in der Installateur-Suche für Kunden auf der Schleswig-Holstein Netz GmbH Homepage und im Netzanschlussportal veröffentlicht',
    ],
  },
  [TENANT_ID.AVA]: {
    INTRO_TEXT: 'Ich bin / wir sind damit einverstanden, dass die Avacon Netz GmbH',

    APPROVAL_TEXTS: [
      'über gesetzliche, technische oder organisatorische Neuerungen sowie fachbezogene Veranstaltungen und das Installateurportal informiert [Info-Versand per E-Mail]',
      'meine / unsere im Installateurverzeichnis gespeicherten Kontaktdaten (Name, Adresse, Telefonnummer, E-Mail-Adresse) in der Installateur-Suche für Kunden auf der Avacon Netz GmbH Homepage und im Netzanschlussportal veröffentlicht',
    ],
  },
  [TENANT_ID.BAG]: {
    INTRO_TEXT: 'Ich bin / wir sind damit einverstanden, dass die Bayernwerk Netz GmbH',

    APPROVAL_TEXTS: [
      'über gesetzliche, technische oder organisatorische Neuerungen sowie fachbezogene Veranstaltungen und das Installateurportal informiert [Info-Versand per E-Mail]',
      'meine / unsere im Installateurverzeichnis gespeicherten Kontaktdaten (Name, Adresse, Telefonnummer, E-Mail-Adresse) in der Installateur-Suche für Kunden auf der Bayernwerk Netz GmbH Homepage und im Netzanschlussportal veröffentlicht',
    ],
  },
};

const DataPolicyContent = ({
  className,
  dsgvoAddressPublic,
  dsgvoCanSendMail,
  onChangeDsvgoAddressPublic,
  onChangeDsvgoCanSendMail,
  dsgvoAddressPublicName,
  dsgvoCanSendMailName,
  documents,
}) => {
  const CURRENT_CONTENT = CONTENT[getTenantId()];
  const filteredDocuments = documents.filter(
    ({ link, label }) => link && link !== '' && label && label !== ''
  );

  return (
    <div className={className}>
      {filteredDocuments.length > 0 && (
        <FormSection subtitle="Informationen zum Datenschutz">
          <div className="data-policy__down-section-wrapper ">
            {filteredDocuments.map(({ link, label }, index) => (
              <AssetDownloadItem key={`${label}_${index}`} asset={link} name={label} />
            ))}
          </div>
        </FormSection>
      )}
      <FormSection subtitle="Zustimmungen zum Datenschutz">
        <div>
          Mit Absenden dieser Anfrage bestätige ich / bestätigen wir, dass ich / wir die
          „Datenschutz“-Zustimmung und die hier aktuell hinterlegte „Informationen zum Datenschutz“
          zur Kenntnis genommen habe / haben und mit Absenden dieser Anfrage bestätige ich /
          bestätigen wir, dass die oben unter „Inhaber / Gesellschafter / Teilhaber“ und unter
          „verantwortliche Fachkräfte“ genannten Personen die „Informationen zum Datenschutz“
          gelesen haben und mit den in der „Datenschutz“-Zustimmung genannten Punkten einverstanden
          sind.
        </div>
        <div className="data-policy__switch-section-intro-text">{CURRENT_CONTENT.INTRO_TEXT}</div>
        <div className="data-policy__switch-section-wrapper">
          <CheckboxRow
            name={dsgvoCanSendMailName}
            label={CURRENT_CONTENT.APPROVAL_TEXTS[0]}
            value={dsgvoCanSendMail}
            onChange={onChangeDsvgoCanSendMail}
          />
          <CheckboxRow
            name={dsgvoAddressPublicName}
            label={CURRENT_CONTENT.APPROVAL_TEXTS[1]}
            value={dsgvoAddressPublic}
            onChange={onChangeDsvgoAddressPublic}
          />
        </div>
      </FormSection>
    </div>
  );
};

DataPolicyContent.propTypes = {
  className: PropTypes.string,
  dsgvoAddressPublic: PropTypes.bool.isRequired,
  dsgvoAddressPublicName: PropTypes.string.isRequired,
  dsgvoCanSendMail: PropTypes.bool.isRequired,
  dsgvoCanSendMailName: PropTypes.string.isRequired,
  onChangeDsvgoAddressPublic: PropTypes.func.isRequired,
  onChangeDsvgoCanSendMail: PropTypes.func.isRequired,
  documents: PropTypes.array.isRequired,
};

export { DataPolicyContent };
