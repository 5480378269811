import * as Yup from 'yup';
import { YParticipant } from './YParticipant';

import { EIV_CHAR_LENGTH, VALIDATION_CONSTANTS } from 'global/constants';

export const YEventRegistration = Yup.object().shape({
  firmenname1: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.FIRMENNAME1, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  firmenname2: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.FIRMENNAME2, VALIDATION_CONSTANTS.TOO_LONG),
  firmenname3: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.FIRMENNAME3, VALIDATION_CONSTANTS.TOO_LONG),
  userId: Yup.string(),
  participants: Yup.array(YParticipant),
});
