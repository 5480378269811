import React from 'react';

import './PublicFooter.sass';
import PropTypes from 'prop-types';
import { getMetaNavLinks } from 'utils/AEMPropUtils';

const PublicFooter = (props) => {
  const metaNavLinks = getMetaNavLinks(props.cqItems.root[':items'].footer);

  return (
    <div className="public-footer">
      <div className="public-footer__border" />
      <div className="public-footer__container">
        {metaNavLinks.map((value, index) => (
          <a key={index} href={value.url} className="public-footer__link">
            {value.linkTitle}
          </a>
        ))}
      </div>
    </div>
  );
};

PublicFooter.displayName = 'PublicFooter';

PublicFooter.propTypes = {
  cqItems: PropTypes.object,
};

PublicFooter.defaultProps = {};

export { PublicFooter };
