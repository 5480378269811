import React from 'react';
import PropTypes from 'prop-types';
import './SectionMidTitle.sass';

export function SectionMidTitle({ children }) {
  return <div className="section-mid-title">{children}</div>;
}

SectionMidTitle.propTypes = {
  children: PropTypes.node,
};
