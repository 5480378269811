import React from 'react';
import PropTypes from 'prop-types';
import './PageTitleSection.sass';
import { PageTitle } from '../PageTitle/PageTitle';
import ClassNames from 'classnames';
import { MapTo } from '@adobe/aem-react-editable-components';

export const PageTitleSection = (props) => {
  const { title, subText, className, notFramed } = props;

  const classes = ClassNames('page-title-section', {
    [className]: !!className,
    ['page-title-section__framed']: !notFramed,
  });

  return (
    <div className={classes}>
      <PageTitle text={title} className="page-title-section-title" />
      {subText && <div className="page-title-section-subtext-wrapper">{subText}</div>}
    </div>
  );
};

PageTitleSection.propTypes = {
  title: PropTypes.string.isRequired,
  subText: PropTypes.string,
  className: PropTypes.string,
  notFramed: PropTypes.bool,
};

PageTitleSection.defaultProps = {
  notFramed: false,
  title: 'Title',
};

MapTo(['e-fix/components/content/pagetitlesection'])(PageTitleSection);
