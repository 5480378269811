/* eslint-disable react/prop-types */
import { MapTo } from '@adobe/aem-react-editable-components';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSvgFetch } from '../hooks/useSvgFetch';
import { logoutWithRedirect } from '../utils/AuthUtils';
import './css/HomePageHeader.sass';
import { getFileExtension } from '../utils/getFileExtension';
import DividingLine from './DividingLine/DividingLine';
import HamburgerMenuComponent from './HamburgerMenuComponent';
import HomePageHeaderReportDisorderButton from './HomePageHeaderReportDisorderButton';
import HomePageHeaderSubLink from './HomePageHeaderSubLink';
import { TenantSwitchButton } from './TenantSwitch/TenantSwitchButton/TenantSwitchButton';
import { TenantSwitchModal } from './TenantSwitch/TenantSwitchModal/TenantSwitchModal';
import { useCurrentInstaller } from 'hooks/UseCurrentInstaller';
import { ErrorPopup } from './ErrorPopup/ErrorPopup';
import { useAuth } from 'context/Auth';

const HeaderEditConfig = {
  emptyLabel: 'Header',
  isEmpty: function (props) {
    return Boolean(!props.navigation?.items?.length);
  },
};

// fallback navroot for the case that navigation is not configured yet in AEM i.e. navigation.items=[]
const emptyNavRoot = {
  url: '#',
  title: 'Startseite',
  children: [],
};

const HomePageHeader = (props) => {
  const navRoot = props.navigation?.items?.length ? props.navigation.items[0] : emptyNavRoot;
  const navLinks = navRoot.children;
  const switchLinks = props?.metaNav?.links;

  const { disorderReportLabel } = props;

  const [isTenantSwitchOpen, setIsTenantSwitchOpen] = useState(false);

  const { installer } = useCurrentInstaller();
  const { isCIAM } = useAuth();

  const logo = {
    image: useSvgFetch(props.logo) || props.logo,
    isSvg: getFileExtension(props.logo) === 'svg',
    mobileLogo: useSvgFetch(props.mobileLogo) || props.mobileLogo,
  };

  const stoerungenNavLinkData = navLinks.find((navLink) => navLink.url.includes('stoerungen'));

  const renderHeaderMobile = () => {
    return (
      <div className="header phone">
        <div className="home-page-header-logout-button-mobile" onClick={logoutWithRedirect}>
          <Link to={navRoot.url}>
            <img alt="logout-icon" src={require('../assets/pics/icon-close.svg')} />
            Ausloggen
          </Link>
        </div>
        <span className="home-page-header-top-section-mobile">
          <Link to={navRoot.url}>
            <div className={`home-page-header-logo-mobile-wrapper`}>
              <img
                alt="Efix_Logo"
                className="home-page-header-logo-mobile"
                src={props.mobileLogo || props.logo}
              />
            </div>
          </Link>
          <TenantSwitchButton
            className="home-page-header-tenant-switch-button"
            onClick={() => {
              setIsTenantSwitchOpen(true);
            }}
          />
        </span>
        <DividingLine />
        <div className="header-mobile-links">
          <HamburgerMenuComponent navRoot={navRoot} navLinks={navLinks} installer={installer} />
          <HomePageHeaderReportDisorderButton
            disorderReportLabel={disorderReportLabel}
            navLinkData={stoerungenNavLinkData}
          />
        </div>
      </div>
    );
  };

  const renderHeaderDesktop = () => {
    return (
      <>
        <div className="header desktop">
          <Link to={navRoot.url}>
            {logo.isSvg ? (
              <>
                <span className={`header-logo`} dangerouslySetInnerHTML={{ __html: logo.image }} />
              </>
            ) : (
              <>
                <img alt="Efix_Logo" className={`header-logo`} src={logo.image} />
              </>
            )}
          </Link>
          <div className="home-page-header-logout-button">
            <TenantSwitchButton
              className="home-page-header-tenant-switch-button"
              onClick={() => {
                setIsTenantSwitchOpen(true);
              }}
            />
            <div
              onClick={() => {
                logoutWithRedirect(isCIAM);
              }}>
              <Link to={navRoot.url}>
                <img alt="logout-icon" src={require('../assets/pics/icon-close.svg')} /> Ausloggen
              </Link>
            </div>
          </div>
        </div>
        <DividingLine />
        <div className="home-page-header-links">
          <HomePageHeaderSubLink name={navRoot.title} link={navRoot.url} key={navRoot.title} />
          {navLinks.map((link) => {
            return (
              <HomePageHeaderSubLink
                name={link.title}
                link={link.url}
                key={link.title}
                installer={installer}
              />
            );
          })}
          <HomePageHeaderReportDisorderButton
            disorderReportLabel={disorderReportLabel}
            navLinkData={stoerungenNavLinkData}
          />
        </div>
      </>
    );
  };

  return (
    <>
      <header className="home-page-header">
        {renderHeaderMobile()}
        {renderHeaderDesktop()}
      </header>
      <TenantSwitchModal
        isOpen={isTenantSwitchOpen}
        onClose={() => setIsTenantSwitchOpen(false)}
        links={switchLinks}
      />
      <ErrorPopup></ErrorPopup>
    </>
  );
};

export default MapTo('e-fix/components/structure/header')(HomePageHeader, HeaderEditConfig);
