import { isArray } from 'lodash';
import { format } from 'date-fns';
import { DOCUMENT_NAME_TO_ID, SHARED_DOCUMENTS } from 'global/SharedDocumentFields';
import { STATIC_FIELDS } from 'global/constants';

export const getFilesKeys = (values) => {
  return Object.keys(values).filter((key) => {
    return !STATIC_FIELDS.includes(key);
  });
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const prepFilesForUpload = async (files) => {
  return Promise.all(
    Array.from(files).map(async (file) => ({
      name: file.name,
      contentType: file.type ? file.type : null,
      base64: await toBase64(file),
      size: file.size,
    }))
  );
};

export const prepDocumentForMutation = (document) => ({
  contentType: document.contentType,
  base64: document.base64.split(',')[1],
  fileName: document.name,
});

export const downloadPdfFromBase64 = (base64String, fileName) => {
  // Convert base64 string to a Blob
  const binaryString = window.atob(base64String);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  const blob = new Blob([bytes], { type: 'application/pdf' });

  // Create an object URL from the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element and start the download
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  link.click();

  // Release the object URL
  window.URL.revokeObjectURL(url);
};

export const calculateTotalFilesSize = (values) => {
  const filesFields = getFilesKeys(values);
  let totalSize = 0;
  filesFields.forEach((key) => {
    if (isArray(values[key])) {
      values[key].forEach((file) => {
        totalSize += file.size;
      });
    } else {
      Object.keys(values[key]).forEach((internalKey) => {
        values[key][internalKey].forEach((file) => {
          totalSize += file.size;
        });
      });
    }
  });
  return totalSize;
};

export const createDocumentName = (installer, documentBaseName, expert) => {
  return documentBaseName
    .split('_')
    .map((nameSection) => {
      switch (nameSection) {
        case 'Date':
          return format(new Date(), 'yyyyMMdd');
        case 'installateurnummer':
          return installer.id;
        case 'firstname':
          return expert.firstName;
        case 'lastname':
          return expert.lastName;
        default:
          return nameSection;
      }
    })
    .join('_');
};

export const getFileWithNames = (values, valuesFirstPage, installer) => {
  const filesKey = getFilesKeys(values);
  const fileWithNames = filesKey
    .map((key) => {
      if (isArray(values[key])) {
        return values[key].map((file, i) => {
          const baseUploadName = SHARED_DOCUMENTS[DOCUMENT_NAME_TO_ID[key]].uploadName;
          let uploadName = createDocumentName(installer, baseUploadName);
          if (values[key].length > 1) {
            uploadName +=
              '_' +
              (i + 1).toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false,
              });
          }
          return { ...file, name: uploadName };
        });
      } else {
        return Object.keys(values[key])
          .map((documentKey) => {
            return values[key][documentKey].map((file, i) => {
              const expert = valuesFirstPage.experts.find((expert) => {
                return expert.id == key;
              });
              const baseUploadName = SHARED_DOCUMENTS[DOCUMENT_NAME_TO_ID[documentKey]].uploadName;
              let uploadName = createDocumentName(installer, baseUploadName, expert);
              if (values[key][documentKey].length > 1) {
                uploadName +=
                  '_' +
                  (i + 1).toLocaleString('en-US', {
                    minimumIntegerDigits: 2,
                    useGrouping: false,
                  });
              }
              return { ...file, name: uploadName };
            });
          })
          .flat(1);
      }
    })
    .flat(1);
  return fileWithNames;
};
