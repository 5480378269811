import * as Yup from 'yup';
import { getTenantId } from 'utils/tenantUtil';
import { YDate, YFutureDate, YPastDate } from 'utils/YupUtils';
import {
  EIV_STATUS,
  EXPERT_EDITING_STATUS,
  TENANT_ID,
  EIV_CHAR_LENGTH,
  VALIDATION_CONSTANTS,
} from 'global/constants';

export const YExpert = Yup.object().shape({
  id: Yup.number().required(),
  lastName: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.EXPERT_NAME, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  birthdate: Yup.string().when(['statusGas', 'statusEnergy'], {
    is: (statusGas, statusEnergy) =>
      statusGas === EIV_STATUS.NEW || statusEnergy === EIV_STATUS.NEW,
    then:
      getTenantId() === TENANT_ID.BAG
        ? YPastDate.required(VALIDATION_CONSTANTS.MANDATORY)
        : Yup.string(),
  }),
  firstName: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.EXPERT_NAME, VALIDATION_CONSTANTS.TOO_LONG)
    .required(VALIDATION_CONSTANTS.MANDATORY),
  isMainEnergy: Yup.boolean(),
  isMainGas: Yup.boolean(),
  expertGasValidityDate: Yup.string().when(['isMainGas', 'editingStatusGas'], {
    is: (isMainGas, editingStatusGas) => {
      return isMainGas === false && Object.values(EXPERT_EDITING_STATUS).includes(editingStatusGas);
    },
    then: YFutureDate.required(VALIDATION_CONSTANTS.MANDATORY),
  }),
  expertEnergyValidityDate: Yup.string().when(['isMainEnergy', 'editingStatusEnergy'], {
    is: (isMainEnergy, editingStatusEnergy) => {
      return (
        isMainEnergy === false && Object.values(EXPERT_EDITING_STATUS).includes(editingStatusEnergy)
      );
    },
    then: YFutureDate.required(VALIDATION_CONSTANTS.MANDATORY),
  }),
  deletionDateEnergy: YDate,
  deletionDateGas: YDate,
  deletionReasonGas: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.STANDARD_TEXT, VALIDATION_CONSTANTS.TOO_LONG),
  deletionReasonEnergy: Yup.string()
    .min(2, VALIDATION_CONSTANTS.TOO_SHORT)
    .max(EIV_CHAR_LENGTH.STANDARD_TEXT, VALIDATION_CONSTANTS.TOO_LONG),
});
