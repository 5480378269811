/* eslint-disable react/prop-types */
import React from 'react';
import qs from 'query-string';
import { Link } from 'react-router-dom';

const HomePageHeaderReportDisorderButton = ({ disorderReportLabel, navLinkData }) => {
  const prepareLocation = () => {
    const locationObj = {
      exact: 'false',
      to: {
        pathname: navLinkData.url,
        search: qs.stringify({ toReport: true }),
        key: Math.floor(Math.random() * 100),
      },
    };

    return locationObj;
  };

  const location = prepareLocation();

  return (
    <Link {...location}>
      <button className="sublink home-page-header-report-disorder">
        <div> {disorderReportLabel} </div>
      </button>
    </Link>
  );
};
export default HomePageHeaderReportDisorderButton;
