import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import './ActionButton.sass';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';

const ActionButton = ({
  link,
  title,
  inverted,
  onClick,
  disabled,
  className,
  type,
  icon,
  loading,
}) => {
  const classes = classNames('action-button', {
    inverted,
    [className]: !!className,
  });

  const button = (
    <button
      disabled={disabled || loading}
      className={classes}
      onClick={onClick}
      type={type ? type : link ? 'button' : 'submit'}>
      {icon || loading ? (
        <div className="button-content">
          {/* icon should be an SVG component, it can be created with https://react-svgr.com/playground/ 
                it can actually be any component so use with care 
            */}
          {icon && !loading ? <div className="icon-space">{icon}</div> : null}
          {loading ? (
            <CircularProgress className="action-button__loading-spinner" size={20} />
          ) : null}
          <div className="button-title">{loading ? 'Lädt...' : title}</div>
        </div>
      ) : loading ? (
        'Lädt...'
      ) : (
        title
      )}
    </button>
  );
  return link && !disabled ? <Link to={link}>{button}</Link> : button;
};

ActionButton.propTypes = {
  link: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  inverted: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.object,
};

export { ActionButton };
