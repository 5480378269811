import classnames from 'classnames';
import { oneOf, string } from 'prop-types';
import React from 'react';

import './TeaserText.scss';

const TeaserText = ({ text, className, variant }) => {
  const classes = classnames('teaser', {
    [`${className}`]: !!className,
    [`teaser--${variant}`]: !!variant,
  });

  return <div className={classes}>{text}</div>;
};

TeaserText.propTypes = {
  text: string.isRequired,
  className: string,
  variant: oneOf(['normal', 'no-margin', 'bold']),
};

export default TeaserText;
