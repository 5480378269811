import React from 'react';
import ReactDOM from 'react-dom';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import { ActionButton } from './ActionButton/ActionButton';
import { useHistory } from 'react-router-dom';
import '../components/css/MessageComponent.sass';
import { SubTitle } from './SubTitle/SubTitle';
import PropTypes from 'prop-types';
import { getExpertTelephonePath } from 'utils/UrlUtil';
import { getTenantId } from 'utils/tenantUtil';

const DialogTitle = (props) => {
  const { children, confirmClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className="title-wrapper" {...other}>
      <SubTitle text={children} className={`title ${!confirmClose && 'no-icon'}`} notFramed />
      {confirmClose ? (
        <IconButton aria-label="close" onClick={confirmClose}>
          <CancelIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

DialogTitle.propTypes = {
  children: PropTypes.any,
  confirmClose: PropTypes.func,
};

const DialogSubtitle = (props) => {
  const { children, ...other } = props;
  return (
    <MuiDialogContent className="subtitle" {...other}>
      <div className="subtitleText">{children}</div>
    </MuiDialogContent>
  );
};

DialogSubtitle.propTypes = {
  children: PropTypes.any,
};

const MessageComponent = ({ data, openConfirm, closeConfirm, email }) => {
  let history = useHistory();

  function handleRedirect() {
    history.push(getExpertTelephonePath());
  }

  return openConfirm
    ? ReactDOM.createPortal(
        <>
          <Dialog
            fullWidth={true}
            className={getTenantId()}
            max-height="461px"
            open={openConfirm}
            confirmClose={closeConfirm}
            aria-labelledby="form-dialog-title">
            {data.title && (
              <DialogTitle id="customized-dialog-title" confirmClose={closeConfirm}>
                {data.title}
              </DialogTitle>
            )}
            {data.subtitle1 && (
              <DialogSubtitle>
                {data.subtitle1}
                {email}
                {data.subtitle2}
              </DialogSubtitle>
            )}
            <hr className="horizontalLine" />
            <div className="base-tenant-style message-component-button-wrapper">
              <ActionButton title="Weitere Expertenberatung" onClick={handleRedirect} inverted />
            </div>
          </Dialog>
        </>,
        document.body
      )
    : null;
};

export default MessageComponent;
