import React from 'react';
import PropTypes from 'prop-types';
import './PageTitle.sass';
import ClassNames from 'classnames';

export const PageTitle = (props) => {
  const { text, className } = props;
  const classes = ClassNames('page-title', { [className]: !!className });

  return <div className={classes}>{text}</div>;
};

PageTitle.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};
