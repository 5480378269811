import { RESEAL_ALLOWED_CONTRACTS_IDS, STAGE } from 'global/constants';
import { getStage } from 'utils/StageUtils';

export const EIV_ENDPOINT = {
  INSTALLER_INFO: () => `/api/external/installateur-info`,
  INSTALLER_BY_ID: (id) => `/api/external/installateure/${id}`,
  INSTALLER_CONTRACTS_BY_ID: (id) => `/api/external/installateure/${id}/vertraege`,
  INSTALLER_SEALS_BY_ID: (id) => `/api/external/installateure/${id}/plombierungen`,
  EXPERTS_BY_INSTALLER_ID: (id) => `/api/external/installateure/${id}/fachkraefte`,
  EINWILLIGUNG_BY_INSTALLER_ID: (id) => `/api/external/installateure/${id}/einwilligung`,
  CIAM_STATUS_BY_EMAIL: (email) => `/api/external/installateure/ciam-info-by-email/${email}`,
};

const EIV_BASE_URL = {
  DEVELOPMENT: 'https://eiv-api-dev.eon.com/eon-installer-directory-srv',
  STAGING: 'https://eiv-api-qa.eon.com/eon-installer-directory-srv',
  UAT: 'https://eiv-api-uat.eon.com/eon-installer-directory-srv',
  PRODUCTION: 'https://eiv-api.eon.com/eon-installer-directory-srv',
};

// Reason for this choice is the CIAM mapping
// CIAM DEV -> e-fix DEV -> EIV QA (& DEV)
// CIAM QA -> e-fix QA -> EIV UAT
// CIAM PROD -> e-fix PROD-> EIV PROD
export const getEIVApiBaseUrl = () => {
  let baseUrl = '';
  switch (getStage()) {
    case STAGE.LOCAL:
      baseUrl = EIV_BASE_URL.STAGING;
      break;
    case STAGE.DEVELOPMENT:
      baseUrl = EIV_BASE_URL.STAGING;
      break;
    case STAGE.STAGING:
      baseUrl = EIV_BASE_URL.UAT;
      break;
    case STAGE.PRODUCTION:
      baseUrl = EIV_BASE_URL.PRODUCTION;
      break;
  }
  return baseUrl;
};

export const getResealAllowedIds = () => {
  let ids = [];
  switch (getStage()) {
    case STAGE.LOCAL:
      ids = RESEAL_ALLOWED_CONTRACTS_IDS.STAGING;
      break;
    case STAGE.DEVELOPMENT:
      ids = RESEAL_ALLOWED_CONTRACTS_IDS.STAGING;
      break;
    case STAGE.STAGING:
      ids = RESEAL_ALLOWED_CONTRACTS_IDS.UAT;
      break;
    case STAGE.PRODUCTION:
      ids = RESEAL_ALLOWED_CONTRACTS_IDS.PRODUCTION;
      break;
  }
  return ids;
};
