import React, { useEffect } from 'react';
import { MapTo } from '@adobe/aem-react-editable-components';
import NetworkConnectionSubtitle from '../../components/NetworkConnectionSubtitle/NetworkConnectionSubtitle';
import { PageTitleSection } from '../../../PageTitleSection/PageTitleSection';

const CONTENT = {
  title: 'Installateurbetreuung',
  firstDescription:
    'Bei Fragen zu Ihrem Eintrag in unserem Installateur-Verzeichnis, Ausweisverlängerungen, dem Vorgehen bei Änderung des Firmensitzes, Umfirmierung oder Änderung von Fachkräften wenden Sie sich an das gemeinsame Postfach:',
  firstDescriptionEmail: 'installateure@bayernwerk.de',
};

const InstallerSupportComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { title, firstDescription, firstDescriptionEmail } = CONTENT;

  return (
    <div className=" expert-page">
      <div className="expert-page-content-stage-text">
        <PageTitleSection title={title} notFramed />
        <NetworkConnectionSubtitle
          description={firstDescription}
          descriptionEmail={firstDescriptionEmail}
        />
      </div>
    </div>
  );
};

MapTo(['e-fix/components/content/baginstallersupport'])(InstallerSupportComponent);
