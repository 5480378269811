import React from 'react';
import { ActionButton } from '../ActionButton/ActionButton';
import OuterBox from '../OuterBox/OuterBox';
import { getTenantId } from 'utils/tenantUtil';
import { SubTitle } from 'components/SubTitle/SubTitle';
import { Separator } from 'components/Separator/Separator';
import './MessageBox.scss';
import PropTypes from 'prop-types';
import TeaserText from 'components/TeaserText/TeaserText';
import { getArticlesOverviewPath, getEventsOverviewPath } from 'utils/UrlUtil';
import { TENANT_ID } from 'global/constants';

const MessageBox = ({ data }) => {
  /**
   * TODO: routes should come from AEM. Probably we should fetch them on App initialisation and put them
   * into context so we can grab them here out of the context-provider
   */

  function getLink() {
    return data.buttonText === 'Zu den Fachbeiträgen'
      ? getArticlesOverviewPath()
      : getEventsOverviewPath();
  }

  const url = data.url;

  return (
    <div className="message-box">
      <OuterBox className="message-box__outer-box">
        <SubTitle text={data.title} notFramed />
        <Separator color={getTenantId() === TENANT_ID.AVA ? 'homepage' : 'secondary'} />
        <TeaserText className="message-box__teaser" text={data.text} />
        <ActionButton
          title={data.buttonText}
          link={url ? undefined : getLink()}
          onClick={url ? () => (window.location.href = url) : undefined}
          inverted
        />
      </OuterBox>
    </div>
  );
};

MessageBox.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    url: PropTypes.string,
    buttonText: PropTypes.string,
  }),
};

export { MessageBox };
