/* eslint-disable react/prop-types */
import React from 'react';
import './ContactPersonItem.scss';

const ContactPersonItemComponent = ({
  title = '',
  name,
  phoneNumber,
  phoneNumberDisplay,
  email = '',
  additionalContainerStyle = {},
}) => (
  <div className="contactPersonItem" style={additionalContainerStyle}>
    <div className="contactPersonItemTitle">{title || <span>&nbsp;</span>}</div>
    <div className="contactPersonItemName">{name}</div>
    <div className="contactPersonItemPhone">
      <a href={`tel:${phoneNumber}`}>
        <span style={{ marginRight: 2 }}>T</span>
        {phoneNumberDisplay}
      </a>
    </div>
    {email && (
      <div className="contactPersonItemEmail">
        <a href={`mailto:${email}`} className="email">
          {email}
        </a>
      </div>
    )}
  </div>
);

export default ContactPersonItemComponent;
