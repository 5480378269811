import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { getFileExtension } from '../utils/getFileExtension';

export const useSvgFetch = (url) => {
  const cache = useRef({});
  const [data, setData] = useState(null);

  useEffect(() => {
    // prettier-ignore
    if (!url || !url.length || getFileExtension(url).toLowerCase() !== "svg") return;

    const fetchData = async () => {
      if (cache.current[url]) return setData(cache.current[url]);
      const result = await axios(url);
      const responseData = result.data;
      cache.current[url] = responseData;
      setData(responseData);
    };

    fetchData();
  }, [url]);

  return data;
};
