/* eslint-disable react/prop-types */
import React, { createContext } from 'react';
import usePersistedState from '../hooks/PersistedState';

export const ParticipantsListContext = createContext({});

const ParticipantsListContextProvider = ({ children }) => {
  const [list, setList] = usePersistedState('showList', false);

  return (
    <ParticipantsListContext.Provider value={{ list, setList }}>
      {children}
    </ParticipantsListContext.Provider>
  );
};

export default ParticipantsListContextProvider;
