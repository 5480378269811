/* eslint-disable react/prop-types */
import { MapTo } from '@adobe/aem-react-editable-components';
import React from 'react';
import './css/Title.scss';
import { SubTitle } from './SubTitle/SubTitle';
import { Separator } from './Separator/Separator';
import { getTenantId } from 'utils/tenantUtil';
import { TENANT_ID } from 'global/constants';

const EditConfig = {
  emptyLabel: 'Title',

  isEmpty: function (props) {
    return !props || !props.text || props.text.trim().length < 1;
  },
};

const Title = ({ text }) => {
  return (
    <div>
      <SubTitle text={text} notFramed />
      <Separator color={getTenantId() === TENANT_ID.AVA ? 'homepage' : 'secondary'} />
    </div>
  );
};

MapTo('e-fix/components/content/title')(Title, EditConfig);
