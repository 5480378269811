import React, { useContext } from 'react';
import '../../global/style/BaseTenantStyle.sass';
import '../../global/multiThemingStyling/multiClientGlobalVariables.scss';
import { CustomModal } from 'components/CustomModal';
import { ActionButton } from 'components/ActionButton/ActionButton';
import './ErrorPopup.sass';
import { getHomepagePath } from 'utils/UrlUtil';
import { ErrorHandlingContext } from 'context/ErrorHandling';
export const ErrorPopup = () => {
  const { showError, setShowError } = useContext(ErrorHandlingContext);

  return (
    <CustomModal
      isOpen={showError}
      title="Fehler!"
      content={
        <div className="form-popup__content">
          Problem bei der Anfrage. Bitte versuchen Sie es später erneut.
        </div>
      }
      ctaButtons={
        <ActionButton
          title={<span className="form-popup__cta-button-ok">OK</span>}
          link={getHomepagePath()}
          inverted
          onClick={() => {
            setShowError(false);
          }}
        />
      }
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"></CustomModal>
  );
};
