import React from 'react';
import PropTypes from 'prop-types';
import './SectionTitle.sass';

export function SectionTitle({ children }) {
  return <div className="section-title">{children}</div>;
}

SectionTitle.propTypes = {
  children: PropTypes.node,
};
