/* eslint-disable react/prop-types */
import ExpandLessTwoToneIcon from '@material-ui/icons/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import React, { useState } from 'react';
import Truncate from 'react-truncate';
import ClockIcon from '../../../assets/pics/clock.svg';
import LocationIcon from '../../../assets/pics/pin-3.svg';
import ParticipantsICon from '../../../assets/pics/shape.svg';
import TickIcon from '../../../assets/pics/tick.svg';
import { formatDate } from 'utils/DatesUtils';
import Tags from '../../Tags/Tags';
import './EventMetaData.scss';

const EventMetaData = ({ event }) => {
  const {
    creationDate,
    title,
    maxParticipants,
    registration,
    description,
    location,
    eventDays,
    tags,
    attachmentUrl,
    registrationDeadline,
    meetingLink,
  } = event;

  const { eventParticipantsNumber, participants } = registration;

  const [truncated, setTruncated] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleTruncate = (truncatedNow) => {
    if (truncated !== truncatedNow) {
      setTruncated(truncatedNow);
    }
  };

  function getSanitzedAttachmentUrl(attachmentUrl) {
    const NEEDLE = 'http://localhost:4502';
    const NODE_ENV_DEV = 'development';

    if (
      !attachmentUrl ||
      process.env.NODE_ENV === NODE_ENV_DEV ||
      !process.env.REACT_APP_GRAPHQL_ENDPOINT ||
      !process.env.REACT_APP_AEM_ENDPOINT.length ||
      attachmentUrl.indexOf(NEEDLE) === -1
    ) {
      return attachmentUrl;
    }

    return attachmentUrl.replace(NEEDLE, process.env.REACT_APP_AEM_ENDPOINT);
  }

  const toggleLines = (event) => {
    event.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <div className="event-meta-data">
      <div className="event-meta-data__header">
        <div className="event-meta-data__header-date">
          {creationDate && `Veröffentlicht am ${formatDate(creationDate)}`}
        </div>
        <Tags tags={tags} />
      </div>
      <div className="event-meta-data__title">{title}</div>
      <div className="event-meta-data__text">
        <Truncate
          lines={!expanded && 3}
          ellipsis={
            <div>
              {' '}
              <ExpandMoreTwoToneIcon fontSize="large" onClick={toggleLines} />{' '}
              <u>
                <a href="#" onClick={toggleLines}>
                  Mehr anzeigen
                </a>
              </u>
            </div>
          }
          onTruncate={handleTruncate}>
          {description}
        </Truncate>
        {!truncated && expanded && (
          <div>
            {' '}
            <ExpandLessTwoToneIcon className="less" fontSize="large" onClick={toggleLines} />{' '}
            <u>
              <a href="#" onClick={toggleLines}>
                Weniger anzeigen
              </a>
            </u>
          </div>
        )}
      </div>

      <div className="event-meta-data__info">
        <div className="event-meta-data__info-item">
          <div className="event-meta-data__info-item-section">
            <div className="event-meta-data__info-icon-container">
              <img alt="Location icon" src={LocationIcon} className="event-meta-data__info-icon" />
            </div>

            <div className="event-meta-data__info-text-container">
              {meetingLink ? (
                <>
                  <div className="event-meta-data__info-item-text event-meta-data__info-item-text--space-left">
                    Online-Veranstaltung
                  </div>
                  <a
                    href={participants.length ? meetingLink : undefined}
                    target="_blank"
                    rel="noreferrer"
                    className="event-meta-data__info-item-link">
                    {participants.length ? 'Jetzt teilnehmen' : 'Link nach Anmeldung'}
                  </a>
                </>
              ) : (
                <>
                  <div className="event-meta-data__info-item-text event-meta-data__info-item-text--space-left">
                    {location.name}
                  </div>
                  <div className="event-meta-data__info-item-text event-meta-data__info-item-text--space-left">
                    {location.description}
                  </div>
                  <div className="event-meta-data__info-item-text event-meta-data__info-item-text--space-left">
                    {location.street}, {location.streetNumber}
                  </div>
                  <div className="event-meta-data__info-item-text event-meta-data__info-item-text--space-left">
                    {location.zip}, {location.city}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="event-meta-data__info-item">
          <div className="event-meta-data__info-item-section">
            <div className="event-meta-data__info-icon-container">
              <img alt="Clock icon" className="event-meta-data__info-icon" src={ClockIcon} />
            </div>
            <div className="event-meta-data__info-text-container">
              {eventDays.map((item, index) => (
                <div key={`${item.startDate}.${index}`}>
                  <div className="event-meta-data__info-item-text event-meta-data__info-item-text--space-left event-meta-data__info-item-text--space-top">
                    Veranstaltungstag {formatDate(item.date)}
                  </div>
                  <div className="event-meta-data__info-item-text event-meta-data__info-item-text--space-left">
                    {`Von ${item.startTime} bis ${item.endTime}`}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="event-meta-data__info">
        <div className="event-meta-data__info-item">
          {attachmentUrl && (
            <div className="event-meta-data__info-item-section event-meta-data__info-item-section--vcenter">
              <div className="event-meta-data__info-icon-container">
                <GetAppOutlinedIcon className="event-meta-data__info-icon" />
              </div>
              <div className="event-meta-data__info-item-text event-meta-data__info-item-text--space-left">
                <u>
                  <a href={getSanitzedAttachmentUrl(attachmentUrl)} download>
                    Anhang
                  </a>
                </u>
              </div>
            </div>
          )}
        </div>
        <div className="event-meta-data__info-item">
          <div className="event-meta-data__info-item-section event-meta-data__info-item-section--vcenter">
            <div className="event-meta-data__info-icon-container">
              <img alt="Participants icon" src={ParticipantsICon} />
            </div>
            <div className="event-meta-data__info-text-container">
              <div className="event-meta-data__info-item-text  event-meta-data__info-item-text--space-top event-meta-data__info-item-text--space-left">
                {maxParticipants > eventParticipantsNumber ? (
                  <div>{`${eventParticipantsNumber} von ${maxParticipants} möglichen Teilnehmern bisher`}</div>
                ) : (
                  <>Die Veranstaltung ist leider ausgebucht</>
                )}
              </div>
            </div>
          </div>
          {participants.length > 0 && (
            <div className="event-meta-data__info-item event-meta-data__info-item-text--space-top">
              <div className="event-meta-data__info-item-section event-meta-data__info-item-section--vcenter">
                <div className="event-meta-data__info-icon-container">
                  <img alt="Tick icon" src={TickIcon} />
                </div>
                <div className="event-meta-data__info-text-container">
                  <div className="event-meta-data__info-item-text event-meta-data__info-item-text--space-top event-meta-data__info-item-text--space-left">
                    <div>{`Teilnahme mit ${participants.length} ${
                      participants.length > 1 ? 'Personen' : 'Person'
                    }`}</div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="event-meta-data__info-item-text event-meta-data__info-item-text--space-top">
            {registrationDeadline ? `Anmeldeschluss: ${formatDate(registrationDeadline)}` : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventMetaData;
