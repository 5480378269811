/* eslint-disable react/prop-types */
import { CURRENT_TEST_SUITE, shouldFillTestData } from 'components/NEForm/NETestdata/NETestSetup';
import React, { useState, createContext, useMemo, useContext, useEffect } from 'react';

const NESteps = createContext({});

export const useNESteps = () => {
  return useContext(NESteps);
};

export const NEStepsContext = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);

  const [submittedValues, setSubmittedValues] = useState(
    shouldFillTestData()
      ? { ...CURRENT_TEST_SUITE }
      : {
          step0: {},
          step1: {},
          step2: {},
          step3: {},
        }
  );

  const setSubmittedValuesWithStep = (step, values) => {
    setSubmittedValues((oldValues) => {
      return { ...oldValues, [`step${step}`]: values };
    });
  };

  const clearSubmittedValuesWithStep = (step) => {
    setSubmittedValues((oldValues) => {
      return { ...oldValues, [`step${step}`]: {} };
    });
  };

  const getSubmittedValuesWithStep = (step) => {
    return submittedValues[`step${step}`];
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const contextValue = useMemo(
    () => ({
      activeStep,
      setActiveStep,
      getSubmittedValuesWithStep,
      setSubmittedValuesWithStep,
      clearSubmittedValuesWithStep,
    }),
    [
      activeStep,
      setActiveStep,
      getSubmittedValuesWithStep,
      setSubmittedValuesWithStep,
      clearSubmittedValuesWithStep,
    ]
  );

  return <NESteps.Provider value={contextValue}>{children}</NESteps.Provider>;
};
