import React, { useEffect } from 'react';
import { MapTo } from '@adobe/aem-react-editable-components';
import NetworkConnectionSubtitle from '../../components/NetworkConnectionSubtitle/NetworkConnectionSubtitle';
import NetworkConnectionSubText from '../../components/NetworkConnectionSubText/NetworkConnectionSubText';
import ContactPersonItem from '../../components/ContactPersonItem/ContactPersonItem';
import { flatCards } from '../../components/card-utils';
import './InstallerSupport.scss';
import { PageTitleSection } from '../../../PageTitleSection/PageTitleSection';

const CONTENT = {
  localAssistanceInstallerContact: {
    title: 'Ansprechpartner Installateurbetreuung',
    firstDescription:
      'Bei Fragen zu Ihrem Eintrag in unserem Installateurverzeichnis, Ausweisverlängerungen, dem Vorgehen bei Änderung des Firmensitzes, Umfirmierung oder Änderung von Fachkräften wenden Sie sich an das gemeinsame Postfach',
    firstDescriptionEmail: 'netzanschluss@avacon.de',
    secondDescription: 'Bitte geben Sie die PLZ Ihres Auftragsortes im Betreff an.',
    cards: [
      {
        title: 'Burgwedel, Nienburg, Syke',
        data: [
          {
            name: 'Silvio Geister',
            phoneNumber: '+49502198930044',
            phoneNumberDisplay: '05021 989-30044',
          },
        ],
      },
      {
        title: 'Burgwedel, Nienburg, Syke',
        data: [
          {
            name: 'Marvin Precht',
            phoneNumber: '+49513980232159',
            phoneNumberDisplay: '05139 802-32159',
          },
        ],
      },
      {
        title: 'Gardelegen, Lüneburg, Salzwedel',
        data: [
          {
            name: 'Carsten Mohs',
            phoneNumber: '+49413170438860',
            phoneNumberDisplay: '04131 704-38860',
          },
        ],
      },
      {
        title: 'Gardelegen, Lüneburg, Salzwedel',
        data: [
          {
            name: 'Vertretung: Niclas Rudolph',
            phoneNumber: '+49413170430226',
            phoneNumberDisplay: '04131 704-30226',
          },
        ],
      },
      {
        title: 'Gehrden, Sarstedt',
        data: [
          {
            name: 'Sammelrufnummer',
            phoneNumber: '+4950668333333',
            phoneNumberDisplay: '05066 83-33333',
          },
        ],
      },
      {
        title: 'Genthin, Schöningen, Oschersleben',
        data: [
          {
            name: 'Johannes Meineke',
            phoneNumber: '+493933822130057',
            phoneNumberDisplay: '03933 8221-30057',
          },
        ],
      },
      {
        title: 'WEVG / Salzgitter',
        data: [
          {
            name: 'Michael Werneburg',
            phoneNumber: '+49534122131369',
            phoneNumberDisplay: '05341 221-31369',
          },
        ],
      },
    ],
  },
  firstSubText:
    'Sie sind sich nicht sicher, welchem unserer Betriebsstandorte Ihr Bauvorhaben zugeordnet ist?',
  secondSubText: 'Dann nutzen Sie gerne unsere ',
  secondSubTextActionText: 'Standortsuche',
  secondSubTextAfterAction: '.',
  secondSubTextAction:
    'https://avacon-standortsuche.regiocom.net/lokaextern/App/Suche/Standortsuche.aspx',
};

const InstallerSupportComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    localAssistanceInstallerContact: { cards, ...localAssistanceInstallerContactSection },
    ...data
  } = CONTENT;

  const localAssistanceInstallerContactCards = flatCards(cards);

  return (
    <div className=" expert-page installerSupportComponent">
      <div className="expert-page-content-stage-text">
        <PageTitleSection title={localAssistanceInstallerContactSection.title} notFramed />
        <NetworkConnectionSubtitle
          description={localAssistanceInstallerContactSection.firstDescription}
          descriptionEmail={localAssistanceInstallerContactSection.firstDescriptionEmail}
        />
        <div className="installer-support-subtext-wrapper">
          <NetworkConnectionSubText
            text={localAssistanceInstallerContactSection.secondDescription}
            textEmail={localAssistanceInstallerContactSection.secondDescriptionEmail}
          />
        </div>

        <div className="installerSupportSectionContactPersonContainer">
          {localAssistanceInstallerContactCards.map((details, index) => (
            <div
              key={index}
              className={`installerSupportSectionContactPersonItem${
                !details?.title ? ' borderNone' : ''
              }`}>
              <ContactPersonItem
                title={details?.title}
                name={details.name}
                phoneNumber={details.phoneNumber}
                phoneNumberDisplay={details.phoneNumberDisplay}
              />
            </div>
          ))}
        </div>

        <div className="bottom-text-wrapper">
          <div>{data.firstSubText}</div>
          <NetworkConnectionSubText
            text={data.secondSubText}
            textAction={data.secondSubTextActionText}
            textAfterEmail={data.secondSubTextAfterAction}
            textActionLink={data.secondSubTextAction}
          />
        </div>
      </div>
    </div>
  );
};

MapTo(['e-fix/components/content/avaconinstallersupport'])(InstallerSupportComponent);
