export const avaconGuestBothFormData = {
  companyName1: 'Avacon Test (QA EIV) / DEV Efix',
  companyName2: 'Avacon Manuel Gumz',
  companyName3: 'Line 3 Testing',
  companyOwner: '',
  companyId: '100000302511',
  companyStreet: 'Hopfenstraße',
  companyHouseNumber: '123',
  companyPostal: '38226',
  companyCity: 'Salzgitter',
  companyRegion: '',
  gridProviderEnergy: 'Stadtwerke Überlingen GmbH',
  gridProviderGas: 'Avacon Netz GmbH',
  foreignCompanyIdEnergy: '',
  foreignCompanyIdGas: '',
  email: 'manuel.elgomez@gmail.com',
  phone: '+49 1325 45642 4',
  mobile: '',
  isAddressPublic: false,
  canSendMail: false,
  limitationGas: '',
  limitationEnergy: '',
  installerGasValidityDate: '31.12.2026',
  installerEnergyValidityDate: '31.12.2027',
  energyAgreement: false,
  gasAgreement: false,
  energyExtension: false,
  gasExtension: false,
  craftsChamberArea: '',
  craftsChamberNumber: '',
  craftsChamberDate: '',
  businessRegistrationLocation: '',
  businessRegistrationDate: '',
  commercialRegisterNumber: '',
  commercialRegisterDate: '',
  experts: [
    {
      firstName: 'Combi',
      lastName: 'Installer',
      expertGasValidityDate: '31.12.2026',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '01.01.1975',
      statusGas: 4,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290564,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Wasser',
      lastName: 'AQUA',
      expertGasValidityDate: '31.12.2026',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: null,
      statusEnergy: null,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290574,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Combi',
      lastName: 'befristetdoppelt',
      expertGasValidityDate: '28.12.2024',
      expertEnergyValidityDate: '27.12.2024',
      birthdate: '',
      statusGas: 4,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290575,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Combi',
      lastName: 'Strom-befristet',
      expertGasValidityDate: '31.12.2026',
      expertEnergyValidityDate: '29.12.2024',
      birthdate: '15.05.1950',
      statusGas: 4,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290576,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Combi',
      lastName: 'Gas-befristet',
      expertGasValidityDate: '30.12.2024',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: 4,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290577,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'gas-gelöscht',
      lastName: 'Strom-aktiv',
      expertGasValidityDate: '31.12.2026',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '01.01.2000',
      statusGas: 1,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290578,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'strom-befristet',
      lastName: 'gas-gelöscht',
      expertGasValidityDate: '31.12.2026',
      expertEnergyValidityDate: '30.12.2024',
      birthdate: '01.01.2002',
      statusGas: 1,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290579,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Schorsch',
      lastName: 'Stromer',
      expertGasValidityDate: '31.12.2026',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: null,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290580,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Stromer',
      lastName: 'befristet',
      expertGasValidityDate: '31.12.2026',
      expertEnergyValidityDate: '15.05.2024',
      birthdate: '30.04.2002',
      statusGas: null,
      statusEnergy: 2,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290581,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Strom',
      lastName: 'im Bearbeitung',
      expertGasValidityDate: '31.12.2026',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: null,
      statusEnergy: 3,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290582,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Strom-gelöscht',
      lastName: 'Gas-aktiv',
      expertGasValidityDate: '31.12.2026',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '01.01.2001',
      statusGas: 4,
      statusEnergy: 1,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290583,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'gas-befristet',
      lastName: 'strom-gelöscht',
      expertGasValidityDate: '29.12.2024',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: 4,
      statusEnergy: 1,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290584,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Gaser',
      lastName: 'befristet',
      expertGasValidityDate: '31.12.2024',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: 4,
      statusEnergy: null,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290587,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Gerry',
      lastName: 'Gaser',
      expertGasValidityDate: '31.12.2026',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '15.05.2000',
      statusGas: 4,
      statusEnergy: null,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290588,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Gas',
      lastName: 'in Bearbeitung',
      expertGasValidityDate: '31.12.2026',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: 3,
      statusEnergy: null,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290589,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Gas',
      lastName: 'Wasser',
      expertGasValidityDate: '31.12.2026',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: 4,
      statusEnergy: null,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290590,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Gas-aktiv',
      lastName: 'Wasser-inaktiv',
      expertGasValidityDate: '31.12.2026',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: 4,
      statusEnergy: null,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290591,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'gas-befristet',
      lastName: 'strom-gelöscht',
      expertGasValidityDate: '29.12.2024',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: 4,
      statusEnergy: 1,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 290592,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Herbert',
      lastName: 'Zusel',
      expertGasValidityDate: '31.12.2026',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: null,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 302515,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'inaktiv',
      lastName: 'inaktiv',
      expertGasValidityDate: '31.12.2026',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: 2,
      statusEnergy: 2,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 302516,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'strom aktiv',
      lastName: 'Gas inaktiv',
      expertGasValidityDate: '31.12.2026',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: 2,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 302517,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Strom befristet',
      lastName: 'Gas gelöscht',
      expertGasValidityDate: '31.12.2026',
      expertEnergyValidityDate: '25.10.2026',
      birthdate: '',
      statusGas: 1,
      statusEnergy: 4,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 302518,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
    {
      firstName: 'Gas-aktiv-zwei',
      lastName: 'Wasser-inaktiv-zwei',
      expertGasValidityDate: '31.12.2026',
      expertEnergyValidityDate: '31.12.2027',
      birthdate: '',
      statusGas: 4,
      statusEnergy: null,
      deletionReasonEnergy: '',
      deletionReasonGas: '',
      deletionDateEnergy: '',
      deletionDateGas: '',
      id: 302519,
      isMainGas: false,
      isMainEnergy: false,
      editingStatusGas: '',
      editingStatusEnergy: '',
    },
  ],
  doesEnergyBranchExist: true,
  doesGasBranchExist: true,
  doesNeedAvaGuestPostal: true,
  avaGuestPostal: '',
  contracts: [
    {
      id: 5487,
      contractStatus: 'Aktiv',
      contractType: {
        name: 'EBY - Feldversuch IAW bis 12/2006',
      },
      validFrom: '25.02.2024',
      cancelledOn: '',
    },
    {
      id: 5507,
      contractStatus: 'Aktiv',
      contractType: {
        name: 'Zählersetzen Strom',
      },
      validFrom: '01.07.2024',
      cancelledOn: '',
    },
    {
      id: 5508,
      contractStatus: 'Aktiv',
      contractType: {
        name: 'Zählersetzen Strom',
      },
      validFrom: '28.04.2024',
      cancelledOn: '',
    },
    {
      id: 5509,
      contractStatus: 'Aktiv',
      contractType: {
        name: 'Zählersetzen Gas',
      },
      validFrom: '25.04.2024',
      cancelledOn: '',
    },
  ],
  seals: [],
};

export const avaconGuestBothInstallerData = {
  anredename: null,
  ausw_gas_datum: null,
  ausw_gas_gueltig: '2026-12-31',
  ausw_probe_gas: 0,
  ausw_probe_strom: 0,
  ausw_probe_wasser: 0,
  ausw_strom_datum: '2023-06-26',
  ausw_strom_gueltig: '2027-12-31',
  ausw_strom_matrizennr: null,
  ausw_wasser_datum: null,
  ausw_wasser_gueltig: null,
  ausweisnr_fremd_gas: null,
  ausweisnr_fremd_strom: null,
  ausweisnr_fremd_wasser: null,
  b_anrede: null,
  b_betriebsart_gas: {
    id: 1,
    name: 'Hauptbetrieb',
    geloescht: 0,
    pflegende_bu: '0',
  },
  b_betriebsart_strom: {
    id: 1,
    name: 'Hauptbetrieb',
    geloescht: 0,
    pflegende_bu: '0',
  },
  b_betriebsart_wasser: null,
  b_briefanrede: {
    id: 28,
    name: 'Guten Tag',
    geloescht: 0,
  },
  b_dezentrale_einheit_gas: null,
  b_dezentrale_einheit_strom: null,
  b_dezentrale_einheit_wasser: null,
  b_eintragung_als: {
    id: 1,
    name: 'Installateur',
    geloescht: 0,
  },
  b_eintragung_gas_als: {
    id: 1,
    name: 'Installateur',
    geloescht: 0,
  },
  b_eintragung_wasser_als: null,
  b_hwkbereich: null,
  b_netzbetreiber_gas: {
    id: 32,
    name: 'Avacon Netz GmbH',
    geloescht: 0,
    ort: 'Helmstedt',
    plz: '38350',
    strasse: 'Schillerstraße 3',
    netzbetreibernummer: 7137,
    eintragungsart: 1,
  },
  b_netzbetreiber_strom: {
    id: 936,
    name: 'Stadtwerke Überlingen GmbH',
    geloescht: 0,
    ort: 'Überlingen',
    plz: '88662',
    strasse: 'Kurt-Wilde-Str. 10',
    netzbetreibernummer: 643,
    eintragungsart: 2,
  },
  b_netzbetreiber_wasser: null,
  b_standort: null,
  bemerkung: null,
  besichtigung_ausschuss: null,
  besichtigung_datum: null,
  besichtigung_eon: null,
  betriebshaftpflicht: 0,
  buero_hausnummer: '123',
  buero_land: null,
  buero_ort: 'Salzgitter',
  buero_ortsteil: null,
  buero_plz: '38226',
  buero_strasse: 'Hopfenstraße',
  communication_email: null,
  eintragungsart_erg_gas: null,
  eintragungsart_erg_strom: null,
  eintragungsart_erg_wasser: null,
  eintragungsart_gas: 2,
  eintragungsart_strom: 2,
  eintragungsart_wasser: null,
  email: 'manuel.elgomez@gmail.com',
  ersteintragung: '2023-06-26',
  ersteintragung_gas: '2023-12-01',
  ersteintragung_wasser: null,
  fax: null,
  fax_werkstatt: null,
  firmeninhaber: null,
  firmenname1: 'Avacon Test (QA EIV) / DEV Efix',
  firmenname2: 'Avacon Manuel Gumz',
  firmenname3: 'Line 3 Testing',
  geloescht_am: null,
  geloescht_gas_am: null,
  geloescht_wasser_am: null,
  gewerbeanmeldung_datum: null,
  gewerbeanmeldung_ort: null,
  hrabnummer: null,
  hwkdatum: null,
  hwknummer: null,
  id: 100000302511,
  importiert: null,
  in_verlaengerung: false,
  instv_gas_am: null,
  instv_wasser_am: null,
  lfdnr: null,
  musteranlage_gas: null,
  netzportal: 1,
  pflegende_bu: '2235',
  referenznummer_gas: null,
  referenznummer_strom: null,
  referenznummer_wasser: null,
  register_letzte_aenderung: null,
  registergericht: null,
  richtlinien: null,
  richtlinien_version: null,
  status_gas: 4,
  status_gas_automatic: 1,
  status_strom: 4,
  status_strom_automatic: 1,
  status_wasser: null,
  status_wasser_automatic: 0,
  telefon_geschaeft: '+49 1325 45642 4',
  telefon_mobil: null,
  telefon_werkstatt: null,
  werkstatt_hausnummer: null,
  werkstatt_land: null,
  werkstatt_ort: null,
  werkstatt_ortsteil: null,
  werkstatt_plz: null,
  werkstatt_strasse: null,
  zaehlermontage_gas: '00',
  zaehlermontage_strom: '01',
  zu_adressveroeff: 'Nein',
  zu_gasinfo: null,
  zu_infoversand: 'Nein',
  zu_kommunikation_mail: null,
  zu_netzanschlussportal: null,
  zu_technikinfo: null,
};
