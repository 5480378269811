/* eslint-disable react/prop-types */
import React from 'react';
import './BusinessUnitSelection.scss';

const BusinessUnitSelection = ({ options, onOptionSelected }) => {
  return (
    <div className="businessUnitSelection">
      {(options || []).map(
        (option) =>
          option.imageName && (
            <div
              className="imageItem"
              key={option.key}
              onClick={() => onOptionSelected(option.key)}>
              <img
                alt={option?.alt || ''}
                src={require(`../../../../../assets/pics/${option?.imageName}`)}
              />
            </div>
          )
      )}
    </div>
  );
};

export default BusinessUnitSelection;
