import { TableCell } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { booleanToYesOrHyphen } from 'utils/DataUtils';

const CustomTableCell = ({ children, ...props }) => {
  return (
    <TableCell {...props}>
      {children && children !== ''
        ? typeof children == 'boolean'
          ? booleanToYesOrHyphen(children)
          : children
        : '-'}
    </TableCell>
  );
};
CustomTableCell.propTypes = {
  children: PropTypes.string | PropTypes.object | PropTypes.bool,
};
export { CustomTableCell };
