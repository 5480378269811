/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { ActionButton } from '../ActionButton/ActionButton';
import ParticipantsICon from '../../assets/pics/shape.svg';
import './EventListGroup.scss';
import { CustomModal } from '../../components/CustomModal';
import { ParticipantsListContext } from '../../context/ParticipantsList';
import LocationIcon from '../../assets/pics/pin-3.svg';
import { UNREGISTER_FROM_EVENT_MUTATION } from '../../Queries/Mutations';
import { EventIdContext } from 'context/EventIdContext';
import { format } from 'date-fns';

const EventListGroupItem = ({ event }) => {
  const { id, path, startDate, endDate, title, registration, deleted, meetingLink } = event;

  const [disabled, setDisabled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { setList } = useContext(ParticipantsListContext);
  const { setId } = useContext(EventIdContext);
  const { participants } = registration;

  const [unregisterFromEvent, { data: dataResponseFromUnregisterEvent }] = useMutation(
    UNREGISTER_FROM_EVENT_MUTATION
  );
  const [modalContent, setModalContent] = useState({
    title: 'Wirklich absagen?',
    content: 'Willst du den Termin wirklich für dich und alle deine Teilnehmer absagen?',
  });
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!dataResponseFromUnregisterEvent) return;
    const newContent = Object.assign({}, modalContent, {
      title: 'Teilnahme abgesagt',
      content: 'Wir haben deine Absage erhalten...',
    });
    setModalContent(newContent);
    setSuccess(true);
    setDisabled(true);
  }, [dataResponseFromUnregisterEvent]);

  const eventListGroupClasses = classnames('event-list-group-item__wrapper', {
    'event-list-group-item__wrapper--disabled': deleted || disabled,
  });

  const eventListBtn = classnames('event-list-group-item__cta-btn', {
    'event-list-group-item__cta-btn-red': deleted || disabled,
  });

  const handleCancelEventClick = async () => {
    const variables = { eventId: id, unregister: true, participants: [] };
    await unregisterFromEvent({ variables });
  };

  const onChangeEventClicked = () => {
    setId(event.id);
  };

  const checkPath = (path) => {
    setList(false);

    if (path.startsWith('/')) {
      return path;
    }
    return `/${path}`;
  };

  const getModalCtaButtons = () => {
    if (success) {
      return <ActionButton onClick={() => setIsOpen(false)} title="Ok, verstanden" inverted />;
    }

    return (
      <>
        <ActionButton onClick={handleCancelEventClick} title="Absagen" inverted />
        <ActionButton onClick={() => setIsOpen(false)} title="Abbrechen" inverted />
      </>
    );
  };

  return (
    <>
      <div className="event-list-group-item">
        <CustomModal
          title={modalContent.title}
          content={
            <div className="event-list-group-item__modal-content-wrapper">
              {modalContent.content}
            </div>
          }
          ctaButtons={getModalCtaButtons()}
          isOpen={isOpen}
        />
        <div className="event-list-group-item__outer-wrapper">
          <div className={`${eventListGroupClasses} event-list-group-item__flex-4`}>
            <div>
              <div className="event-list-group-item__info">
                <div className="event-list-group-item__date">
                  {startDate && endDate
                    ? format(new Date(startDate), 'dd.MM.yyyy HH:mm') +
                      format(new Date(endDate), ' - HH:mm')
                    : ''}
                </div>
                <div className="event-list-group-item__participants">
                  <img
                    alt=""
                    className="event-list-group-item__participants-icon"
                    src={ParticipantsICon}
                  />
                  <span className="event-list-group-item__participants-number">
                    {participants.length} Teilnehmer angemeldet
                  </span>
                </div>
              </div>
              <div className="event-list-group-item__description">{title}</div>
            </div>
          </div>

          <div className={`${eventListGroupClasses} event-list-group-item__flex-4`}>
            {meetingLink && (
              <div className="event-list-group-item__online-event-outer-wrapper">
                <img
                  alt="Location icon"
                  src={LocationIcon}
                  className="event-list-group-item__location-icon"
                />
                <div className="event-list-group-item__online-event-wrapper">
                  <span className="">Online-Veranstaltung</span>
                  <a
                    href={meetingLink}
                    target="_blank"
                    rel="noreferrer"
                    className="event-list-group-item__take-part-link">
                    Jetzt teilnehmen
                  </a>
                </div>
              </div>
            )}
            {!disabled && !deleted ? (
              <div className="event-list-group-item__wrapper-buttons">
                <button className="event-list-group-item__cta-btn" onClick={() => setIsOpen(true)}>
                  Teilnahme absagen
                </button>

                <Link to={checkPath(path)}>
                  <button
                    className="event-list-group-item__cta-btn"
                    onClick={() => onChangeEventClicked()}>
                    Teilnehmer ändern
                  </button>
                </Link>
              </div>
            ) : (
              <div className={eventListBtn}>Veranstaltung Abgesagt</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
const EventListGroup = ({ events }) => {
  return (
    <div className="event-list-group">
      {events.map((event) => {
        return <EventListGroupItem key={event.id} event={event} />;
      })}
    </div>
  );
};

export default EventListGroup;
