/*~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 ~ Copyright 2018 Adobe Systems Incorporated
 ~
 ~ Licensed under the Apache License, Version 2.0 (the "License");
 ~ you may not use this file except in compliance with the License.
 ~ You may obtain a copy of the License at
 ~
 ~     http://www.apache.org/licenses/LICENSE-2.0
 ~
 ~ Unless required by applicable law or agreed to in writing, software
 ~ distributed under the License is distributed on an "AS IS" BASIS,
 ~ WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 ~ See the License for the specific language governing permissions and
 ~ limitations under the License.
 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~*/
import {
  Page,
  MapTo,
  withComponentMappingContext,
  Utils,
} from '@adobe/aem-react-editable-components';
import _ from 'lodash';
import React from 'react';
import LikeCard from '../../../components/Article/LikeCard/LikeCard';
import OuterBox from '../../../components/OuterBox/OuterBox';
import { withRoute } from '../../../utils/RouteHelper';

import ComponentContainer from '../../_shared/ComponentContainer';
import TwoColumnsLayout from '../TwoColumnLayout';

class TwoColumnLayoutContainer extends Page {
  constructor(props) {
    super(props);

    this.regions = ['breadcrumb', 'content', 'sidebar'];
    this.isEditor = Utils.isInEditor();
  }

  get regionsData() {
    const regionsData = {};
    this.regions.forEach((region) => {
      regionsData[region] = this.parseRegionsData(region);
    });

    return regionsData;
  }

  parseRegionsData(region) {
    const newProps = _.cloneDeep(this.props);
    for (const [key] of Object.entries(newProps.cqItems.root[':items'])) {
      if (key !== region) delete newProps.cqItems.root[':items'][key];
    }
    newProps.cqItems.root[':itemsOrder'] = [region];

    if (!newProps.cqItems.root[':items'][region]) return null;

    return newProps;
  }

  getLeftColumnContent = (content) => {
    return this.props?.templateName === 'article' ? (
      <OuterBox className="layout-two-column__outer-box outer-box--mt-spacing">
        <ComponentContainer {...content} />
        <LikeCard {...content} />
      </OuterBox>
    ) : (
      <div className="layout-two-column__outer-box outer-box--mt-spacing">
        <ComponentContainer {...content} />
        {/* <LikeCard /> */}
      </div>
    );
  };

  render() {
    const { breadcrumb, content, sidebar } = this.regionsData;

    return (
      <>
        <TwoColumnsLayout
          outerTopContent={<ComponentContainer className="bread-crumb-container" {...breadcrumb} />}
          leftColumn={this.getLeftColumnContent(content)}
          rightColumn={<ComponentContainer {...sidebar} />}
        />
      </>
    );
  }
}

MapTo([
  'e-fix/components/page/twocolumnpage',
  'e-fix/components/page/article',
  'e-fix/components/page/event',
])(withComponentMappingContext(withRoute(TwoColumnLayoutContainer)));
