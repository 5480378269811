import { MapTo } from '@adobe/aem-react-editable-components';
import React, { useEffect } from 'react';
import { agb } from '../../global/data/footerPagesContent';
import GenericInfoPage from '../../components/GenericInfoPage';
import './AGBPage.scss';

const AGBPage = () => {
  useEffect(() => {
    // window.componentHandler.upgradeDom();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="layout-outer-container agb-page">
      <div className="agb-page__container">
        {agb.map((paragraph, key) => (
          <GenericInfoPage key={key} data={paragraph} />
        ))}
      </div>
    </div>
  );
};

MapTo('e-fix/components/content/terms')(AGBPage);
