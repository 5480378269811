import { oneOf, string } from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import './Headline.scss';

const Headline = ({ text, tagName, className, variant }) => {
  const TagName = tagName;
  const classes = classnames('headline', {
    [`${className}`]: !!className,
    [`headline--${variant}`]: !!variant,
  });

  return <TagName className={classes}>{text}</TagName>;
};

Headline.defaultProps = {
  tagName: 'h1',
  variant: 'small',
};

Headline.propTypes = {
  text: string.isRequired,
  className: string,
  variant: oneOf(['title', 'large', 'small']),
  tagName: oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};

export default Headline;
