/* eslint-disable react/prop-types */
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';
import React from 'react';
import './LoadingIndicator.scss';

const LoadingIndicator = ({ className }) => {
  const containerClasses = classnames('efix-loading-indicator', {
    [`${className}`]: !!className,
  });
  const svgWrapperClasses = classnames('efix-loading-indicator__svg-wrapper', {
    [`${className}__svg-wrapper`]: !!className,
  });

  return (
    <div className={containerClasses}>
      <CircularProgress className={svgWrapperClasses} />
    </div>
  );
};

export default LoadingIndicator;
