import React from 'react';
import PropTypes from 'prop-types';
import './ThemedSwitch.sass';

const ThemedSwitch = ({ checked, onChange }) => {
  return (
    <label className="themed-switch__switch">
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className="themed-switch__switch-slider"></span>
    </label>
  );
};

ThemedSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export { ThemedSwitch };
