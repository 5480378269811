import { MapTo, Utils } from '@adobe/aem-react-editable-components';
import React, { useEffect, useRef } from 'react';
import './EdisIncidents.scss';
import { renderIncidents, renderIncidentsMobile } from './IncidentUtils';
import { useQuery } from '@apollo/client';
import { ReportIncidentCard } from '../ReportIncidentCard/ReportIncidentCard';
import { GET_INCIDENTS } from 'Queries/Queries';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { PageTitleSection } from '../PageTitleSection/PageTitleSection';
import { useFormik } from 'formik';
import { ThemedTextField } from 'components/ThemedTextField/ThemedTextField';
import { YDisorderPage } from './YDisorderPage';
import { getTenantId } from 'utils/tenantUtil';
import { RenderAemComponent } from 'components/RenderAemComponent/RenderAemComponent';
import { EdisIncidentFormField } from './EdisIncidentFormField';
import { TENANT_ID } from 'global/constants';

const EdisIncidents = () => {
  const { search, key } = useLocation();
  const reportFaultSection = useRef(null);

  const { loading, error, data } = useQuery(GET_INCIDENTS, { pollInterval: 60000 });

  useEffect(() => {
    const parsedQS = qs.parse(search);
    if (!loading && parsedQS.toReport) {
      if (reportFaultSection) {
        reportFaultSection.current.scrollIntoView();
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [key, loading]);

  const formik = useFormik({
    initialValues: { zip: '' },
    validationSchema: YDisorderPage,
  });
  const isEditor = Utils.isInEditor();

  if (isEditor) {
    return <RenderAemComponent componentProps={{}} name="EdisIncident" />;
  }
  if (loading) return <div>Loading...</div>;
  if (error) return <div>{'(!) ERROR (!)'}</div>;

  const { values, handleChange, handleBlur, touched, errors } = formik;

  return (
    <div className="disorder-page-content">
      <div className="">
        {loading ? (
          <PageTitleSection title="Lädt..." notFramed />
        ) : data && data.incidents && data.incidents.length === 0 ? (
          <PageTitleSection title="Momentan sind keine Störungen bekannt" notFramed />
        ) : (
          <div className="disorder-page-content-stage">
            <div className="">
              <PageTitleSection
                title={getTenantId() === TENANT_ID.AVA ? 'Störungen' : 'Aktuelle Störungen'}
                subText=""
                notFramed
              />
            </div>
            <div className="disorder-panel">
              <EdisIncidentFormField>
                <ThemedTextField
                  fullWidth
                  id="zip"
                  name="zip"
                  label="Suche nach Postleitzahl"
                  variant="standard"
                  value={values.zip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.zip && Boolean(errors.zip)}
                  helperText={touched.zip && errors.zip}
                />
              </EdisIncidentFormField>
              <div className=" hide-tablet hide-phone">
                {renderIncidents(data.incidents, values.zip)}
              </div>
              <div className=" hide-desktop">
                {renderIncidentsMobile(data.incidents, values.zip)}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="disorder-page-report-incident-card-wrapper" ref={reportFaultSection}>
        <ReportIncidentCard />
      </div>
    </div>
  );
};

MapTo('e-fix/components/content/edisincidents')(EdisIncidents);
