import React, { useState } from 'react';
import { CustomTooltip } from 'components/CustomTooltip/CustomTooltip';
import { InfoIcon } from 'components/Icons/InfoIcon';
import PropTypes from 'prop-types';
import { TooltipIconButton } from 'components/TooltipIconButton/TooltipIconButton';
import { useMediaQuery } from 'usehooks-ts';

export const InfoTooltip = ({ title }) => {
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const isTabletOrPhone = useMediaQuery('(max-width:768px)');

  return (
    <>
      <CustomTooltip
        title={title}
        placement="top"
        open={isTooltipOpen}
        onClose={() => setTooltipOpen(false)}
        onOpen={() => {
          if (title) setTooltipOpen(true);
        }}>
        <TooltipIconButton onClick={() => isTabletOrPhone && setTooltipOpen(!isTooltipOpen)}>
          <InfoIcon />
        </TooltipIconButton>
      </CustomTooltip>
    </>
  );
};
InfoTooltip.propTypes = {
  title: PropTypes.string,
};
