/* eslint-disable react/prop-types */
import { ActionButton } from 'components/ActionButton/ActionButton';
import EmailIcon from 'components/Icons/EmailIcon';
import { GenericModal } from 'components/GenericModal';
import { SubTitle } from 'components/SubTitle/SubTitle';
import { TENANT_ID } from 'global/constants';
import React, { useRef, useEffect, useState } from 'react';

import { useToggle } from 'react-use';
import { getTenantId } from 'utils/tenantUtil';

const ExpertSearchResult = ({ data, onReset }) => {
  const [openModal, toggleModal] = useToggle(false);
  const [indexOfContact, setIndexOfContact] = useState(0);

  let modal = {
    title: 'Ihre Nachricht',
    subtitle: 'Meine Installateurnummer',
    email: data.contacts[indexOfContact].email,
  };

  function resetSearch() {
    onReset();
  }

  const scrollTo = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const useMountEffect = (callback) => {
    useEffect(callback, []);
  };

  const myRef = useRef(null);
  useMountEffect(() => scrollTo(myRef));

  const typeMapping = (type) => {
    const data = [
      {
        type: 'STROM',
        name: 'Strom',
      },
      {
        type: 'GAS',
        name: 'Gas',
      },
      {
        type: 'EINSPEISER',
        name: 'Einspeiser',
      },
      {
        type: 'STROMHELP',
        name: 'Strom',
      },
      {
        type: 'GASHELP',
        name: 'Gas',
      },
      {
        type: 'PPJ',
        name: 'Installateur-Betreuung',
      },
      {
        type: 'EDIS',
        name: 'E.DIS Service',
      },
      {
        type: 'TAB',
        name: 'TAB-Ansprechpartner',
      },
      {
        type: 'CONTACT',
        name: 'Netzanschluss / Einspeiser',
      },
    ];

    const name = data
      .map(function (t) {
        if (t.type === type) {
          return t.name;
        }
      })
      .find((elem) => {
        return elem !== undefined;
      });
    if (name !== undefined) {
      return name;
    } else {
      return type;
    }
  };

  const phoneIcon = require('../../../assets/pics/combined-shape.png');

  return (
    <>
      <SubTitle ref={myRef} text="Ihr Expertenkontakt" notFramed />
      <div className="expert-search-results">
        {data.contacts.map((result, index) => (
          <div key={index} className="mdl-grid mdl-cell--12-col result-subcontainer">
            <div className="mdl-cell--6-col mdl-cell--12-col-phone result-headline">
              <SubTitle text={typeMapping(result.type)} />
              {result.marker && (
                <div className="expert-search-results__marker-text">
                  *Der Ort wird von zwei Kundencentren betreut, ggf. werden Sie weitergeleitet
                </div>
              )}
            </div>
            <div className="mdl-cell--6-col mdl-cell--12-col-phone result-number">
              {getTenantId() === TENANT_ID.BAG && (
                <div className="expert-search-results__sales-office-text">
                  {result.location.salesOffice}
                </div>
              )}
              <h6>
                <img alt="" src={phoneIcon} />
                <a href={'tel:' + result.phoneNumberCall}>{result.phoneNumberDisplay}</a>
              </h6>
              <span className="notice">Während der üblichen Geschäftszeiten</span>
              <div
                className="email-modal-open"
                onClick={() => {
                  setIndexOfContact(index);
                  toggleModal();
                }}>
                <EmailIcon />
                <div className="icon-label">Nachricht senden</div>
              </div>
            </div>
          </div>
        ))}
        <ActionButton onClick={resetSearch} title="Neue Suche" inverted />
        <GenericModal data={modal} openModal={openModal} closeModal={toggleModal} />
      </div>
    </>
  );
};

export { ExpertSearchResult };
