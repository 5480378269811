import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';

const TooltipIconButton = (props) => {
  const { children } = props;
  return (
    <IconButton
      style={{ padding: 0, alignItems: 'center', alignContent: 'center' }}
      //className doesn't work
      {...props}>
      {children}
    </IconButton>
  );
};

TooltipIconButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export { TooltipIconButton };
