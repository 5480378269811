import React from 'react';
import { Route } from 'react-router-dom';
import { useAuth } from '../../context/Auth';
import { logoutWithRedirect } from 'utils/AuthUtils';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';

function PrivateRoute(props) {
  const { isAuthenticated, isLoading, isCIAM } = useAuth();

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (!isAuthenticated) {
    logoutWithRedirect(isCIAM);
  }

  return <Route {...props} />;
}

export default PrivateRoute;
