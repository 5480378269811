import { SHARED_DOCUMENTS } from 'global/SharedDocumentFields';
import { INSTALLER_ENTRIES, TENANT_ID } from 'global/constants';

export const DEB_DOCUMENTS_FIELDS = {
  [INSTALLER_ENTRIES.MAIN_ENERGY_ONLY]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 3] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[22], triggeredByGroupIds: [11] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3, 9] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3, 6] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3, 5] },
      { ...SHARED_DOCUMENTS[18], triggeredByGroupIds: [7] },
    ],
  },
  [INSTALLER_ENTRIES.MAIN_GAS_ONLY]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[21], triggeredByGroupIds: [1] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 3] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[16], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[20], triggeredByGroupIds: [3] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3, 6] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[15], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[16], triggeredByGroupIds: [1, 2] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3, 5] },
      { ...SHARED_DOCUMENTS[18], triggeredByGroupIds: [1, 2, 7] },
    ],
  },
  [INSTALLER_ENTRIES.GUEST_ONLY]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 9, 10, 11, 12] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 9, 10, 11, 12] },
    ],

    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 9, 10, 11, 12] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 9, 10, 11, 12] },
    ],
  },
  [INSTALLER_ENTRIES.MAIN_BOTH]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[21], triggeredByGroupIds: [1] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[22], triggeredByGroupIds: [11] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[16], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3, 9] },
      { ...SHARED_DOCUMENTS[20], triggeredByGroupIds: [3] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3, 6] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[15], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[16], triggeredByGroupIds: [1, 2] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3, 5] },
      { ...SHARED_DOCUMENTS[18], triggeredByGroupIds: [1, 2, 7] },
    ],
  },
  [INSTALLER_ENTRIES.MAIN_ENERGY_GUEST_GAS]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 10, 12] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 10, 12] },
      { ...SHARED_DOCUMENTS[22], triggeredByGroupIds: [11] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3, 9] },
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 10, 12] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3, 6] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3, 5] },
      { ...SHARED_DOCUMENTS[18], triggeredByGroupIds: [7] },
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 10, 12] },
    ],
  },
  [INSTALLER_ENTRIES.GUEST_ENERGY_MAIN_GAS]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 9, 11] },
      { ...SHARED_DOCUMENTS[21], triggeredByGroupIds: [1] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 9, 11] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[16], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 3] },
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 9, 11] },
      { ...SHARED_DOCUMENTS[20], triggeredByGroupIds: [3] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[13], triggeredByGroupIds: [1, 2, 3, 6] },
      { ...SHARED_DOCUMENTS[14], triggeredByGroupIds: [1] },
      { ...SHARED_DOCUMENTS[15], triggeredByGroupIds: [1, 2, 3] },
      { ...SHARED_DOCUMENTS[16], triggeredByGroupIds: [1, 2] },
      { ...SHARED_DOCUMENTS[17], triggeredByGroupIds: [1, 2, 3, 5] },
      { ...SHARED_DOCUMENTS[18], triggeredByGroupIds: [1, 2, 7] },
      { ...SHARED_DOCUMENTS[19], triggeredByGroupIds: [1, 2, 3, 8, 9, 11] },
    ],
  },
};

export const DEB_EXPERTS_DOCUMENTS_FIELDS = {
  [INSTALLER_ENTRIES.MAIN_ENERGY_ONLY]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [9] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [9] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [9] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [9] },
    ],
  },
  [INSTALLER_ENTRIES.MAIN_GAS_ONLY]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[24], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [10] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[24], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [10] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[24], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [10] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 2, 3, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [10] },
    ],
  },
  [INSTALLER_ENTRIES.GUEST_ONLY]: {
    [TENANT_ID.AVA]: [],
    [TENANT_ID.EDI]: [],
    [TENANT_ID.SHNG]: [],
    [TENANT_ID.BAG]: [],
  },
  [INSTALLER_ENTRIES.MAIN_BOTH]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [9, 10] },
      { ...SHARED_DOCUMENTS[24], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [10] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [9, 10] },
      { ...SHARED_DOCUMENTS[24], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [10] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[24], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [10] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 2, 3, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [10] },
    ],
  },
  [INSTALLER_ENTRIES.MAIN_ENERGY_GUEST_GAS]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [9] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [9] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [9] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[25], triggeredByGroupIds: [9] },
      { ...SHARED_DOCUMENTS[27], triggeredByGroupIds: [9] },
    ],
  },
  [INSTALLER_ENTRIES.GUEST_ENERGY_MAIN_GAS]: {
    [TENANT_ID.AVA]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[24], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [10] },
    ],
    [TENANT_ID.EDI]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[24], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [10] },
    ],
    [TENANT_ID.SHNG]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[24], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [10] },
    ],
    [TENANT_ID.BAG]: [
      { ...SHARED_DOCUMENTS[23], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[26], triggeredByGroupIds: [10] },
      { ...SHARED_DOCUMENTS[28], triggeredByGroupIds: [1, 2, 3, 10, 12] },
      { ...SHARED_DOCUMENTS[29], triggeredByGroupIds: [10] },
    ],
  },
};
