import React from 'react';
import './ReportIncidentCard.sass';
// import PropTypes from "prop-types";
import { ReactComponent as SVGKontakte } from '../../assets/pics/edis-kontakte.svg';
import { SubTitle } from '../SubTitle/SubTitle';

import { Separator } from '../Separator/Separator';

const ReportIncidentCard = () => {
  return (
    <div className={`mdl-card report-incident-card-container`}>
      <div className={`report-incident-card-title-container`}>
        <div className="report-incident-card-icon-container">
          <div>
            <SVGKontakte />
          </div>
        </div>
        <SubTitle
          text="Störungen melden"
          className="report-incident-card-container-title"
          notFramed
        />
      </div>
      <Separator color={'white'} />
      <div className={`report-incident-card-content-container`}>
        <div className={`report-incident-card-content-item-container`}>
          <div>Für Strom:</div>
          <div>
            <a href={'tel:+4933617332333'}>T 0 33 61-7 33 23 33</a>
          </div>
        </div>
        <div className={`report-incident-card-content-item-container`}>
          <div>Für Gas:</div>
          <div>
            <a href={'tel:+4901804551111'}>T 0180-4 55 11 11*</a>
          </div>
        </div>
        <div className="report-incident-card-content-fine-print">
          *0.20 €/Verbindung aus dem Festnetz. Mobilfunk max. 0.42 €/Min.
        </div>
      </div>
    </div>
  );
};

ReportIncidentCard.propTypes = {};

ReportIncidentCard.defaultProps = {};

export { ReportIncidentCard };
