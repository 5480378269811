import React from 'react';
import DividingLine from '../DividingLine/DividingLine';

const pad = (number) => {
  return number < 10 ? '0' + number : number;
};

const getDate = (timestamp) => {
  const dateRegex = /^(\d|-)+[^T]/;
  // const timeRegex = /(\d){2}:(\d){2}:(\d){2}/ - for seconds
  const timeRegex = /(\d){2}:(\d){2}/; // without seconds

  const date = dateRegex.exec(timestamp);
  const dateToBeFormatted = new Date(date[0]);
  const time = timeRegex.exec(timestamp);

  const dateDisplayed =
    pad(dateToBeFormatted.getDate()) +
    '.' +
    pad(dateToBeFormatted.getMonth() + 1) +
    '.' +
    dateToBeFormatted.getFullYear() +
    ' ' +
    time[0];
  return dateDisplayed;
};

const renderNoIncidents = () => {
  return (
    <div className="incident-utils-no-incident-wrapper">
      <div>Ungültige PLZ oder keine Störungen vorhanden</div>
    </div>
  );
};

export function renderIncidents(incidents, zip) {
  const filteredIncidents = incidents
    .slice()
    .sort((incidentA, incidentB) => {
      if (new Date(incidentA.dateCreated) > new Date(incidentB.dateCreated)) {
        return -1;
      }
      return 1;
    })
    .filter((incident) => {
      if (!zip || incident.zip === zip) {
        return true;
      } else {
        return false;
      }
    });

  if (filteredIncidents.length === 0) {
    return renderNoIncidents();
  } else {
    return (
      <table>
        <thead>
          <tr>
            <td>
              <div id="zip" className="disorder-tabel-col-subject">
                PLZ
              </div>
            </td>
            <td>
              <div id="city" className="disorder-tabel-col-subject">
                Ort
              </div>
            </td>
            <td>
              <div id="district" className="disorder-tabel-col-subject">
                Ortsteil
              </div>
            </td>
            <td>
              <div id="street" className="disorder-tabel-col-subject">
                Strasse
              </div>
            </td>
            <td>
              <div id="dateCreated" className="disorder-tabel-col-subject">
                Stand
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          {filteredIncidents.map((result, index) => (
            <tr key={index}>
              <td>{result.zip}</td>
              <td>{result.city}</td>
              <td>{result.district}</td>
              <td>{result.street}</td>
              <td>{getDate(result.dateCreated)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export function renderIncidentsMobile(incidents, zip) {
  const filteredIncidents = incidents.filter((incident) => {
    if (!zip || incident.zip === zip) {
      return true;
    } else {
      return false;
    }
  });
  if (filteredIncidents.length === 0) {
    return renderNoIncidents();
  } else {
    return (
      <div className="disorder-list-phone-tablet">
        {filteredIncidents.map((result, index, array) => (
          <div key={index}>
            <div className="disorder-list-phone-tablet-row">
              <div className="disorder-list-phone-tablet-tr">PLZ</div>
              <div className="disorder-list-phone-tablet-td">{result.zip}</div>
            </div>
            <div className="disorder-list-phone-tablet-row">
              <div className="disorder-list-phone-tablet-tr">Ort</div>
              <div className="disorder-list-phone-tablet-td">{result.city}</div>
            </div>
            <div className="disorder-list-phone-tablet-row">
              <div className="disorder-list-phone-tablet-tr">Ortsteil</div>
              <div className="disorder-list-phone-tablet-td">{result.district}</div>
            </div>
            <div className="disorder-list-phone-tablet-row">
              <div className="disorder-list-phone-tablet-tr">Strasse</div>
              <div className="disorder-list-phone-tablet-td">{result.street}</div>
            </div>
            <div className="disorder-list-phone-tablet-row">
              <div className="disorder-list-phone-tablet-tr">Stand</div>
              <div className="disorder-list-phone-tablet-td">{getDate(result.dateCreated)}</div>
            </div>
            {index < array.length - 1 && <DividingLine />}
          </div>
        ))}
      </div>
    );
  }
}
