/* eslint-disable react/prop-types */
import { MapTo } from '@adobe/aem-react-editable-components';
import React from 'react';
import { useSvgFetch } from '../hooks/useSvgFetch';
import { getFileExtension } from '../utils/getFileExtension';
import DividingLine from './DividingLine/DividingLine';
import './css/HomePageFooter.sass';
import { useHistory } from 'react-router-dom';
import Subnav from './Subnav';
import { getLexikonUrl } from 'utils/UrlUtil';
import { getFooterLinks, getMetaNavLinks } from 'utils/AEMPropUtils';

const HomePageFooter = (props) => {
  const history = useHistory();
  const footerLogo = {
    image: useSvgFetch(props.logo) || props.logo,
    isSvg: getFileExtension(props.logo) === 'svg',
  };
  const footerNavLinks = getFooterLinks(props);

  return (
    <footer>
      <DividingLine />
      <div className="footer__container">
        <div className="footer__list">
          {footerNavLinks.map(({ title, url }, index) => {
            const isLexikon = url.includes('lexikon');
            return (
              <button
                key={index}
                onClick={() => {
                  if (isLexikon) {
                    window.location.assign(getLexikonUrl());
                  } else {
                    history.push(url);
                  }
                }}>
                {title}
              </button>
            );
          })}
        </div>
        <div className="footer__address">
          <div className="footer__address-inner-wrapper">
            {footerLogo.isSvg ? (
              <div
                className="footer__logo"
                dangerouslySetInnerHTML={{ __html: footerLogo.image }}
              />
            ) : (
              <div className="footer__logo-container">
                <img className="footer__logo" alt="logo" src={footerLogo.image} />
              </div>
            )}
            <div dangerouslySetInnerHTML={{ __html: props.address }} />
          </div>
        </div>
      </div>
      <Subnav navItems={getMetaNavLinks(props)} />
    </footer>
  );
};

MapTo(['e-fix/components/structure/footer'])(HomePageFooter);
